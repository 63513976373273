// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file proto/atlas.proto (package augmedi.atlas, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum augmedi.atlas.Visibility
 */
export enum Visibility {
  /**
   * @generated from enum value: VISIBILITY_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: VISIBILITY_PUBLIC = 1;
   */
  PUBLIC = 1,

  /**
   * @generated from enum value: VISIBILITY_AUGMEDI_INTERNAL = 2;
   */
  AUGMEDI_INTERNAL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Visibility)
proto3.util.setEnumType(Visibility, "augmedi.atlas.Visibility", [
  { no: 0, name: "VISIBILITY_UNKNOWN" },
  { no: 1, name: "VISIBILITY_PUBLIC" },
  { no: 2, name: "VISIBILITY_AUGMEDI_INTERNAL" },
]);

/**
 * @generated from enum augmedi.atlas.IdSpace
 */
export enum IdSpace {
  /**
   * @generated from enum value: ID_SPACE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * contains no structures
   *
   * @generated from enum value: ID_SPACE_NONE = 1;
   */
  NONE = 1,

  /**
   * @generated from enum value: ID_SPACE_A = 2;
   */
  A = 2,

  /**
   * HACK temporary, make A work instead
   *
   * @generated from enum value: ID_SPACE_TEMP_DO_NOT_USE_WITHOUT_ASKING_PHILIPPE = 3;
   */
  TEMP_DO_NOT_USE_WITHOUT_ASKING_PHILIPPE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(IdSpace)
proto3.util.setEnumType(IdSpace, "augmedi.atlas.IdSpace", [
  { no: 0, name: "ID_SPACE_UNKNOWN" },
  { no: 1, name: "ID_SPACE_NONE" },
  { no: 2, name: "ID_SPACE_A" },
  { no: 3, name: "ID_SPACE_TEMP_DO_NOT_USE_WITHOUT_ASKING_PHILIPPE" },
]);

/**
 * @generated from enum augmedi.atlas.RelatedStructureScope
 */
export enum RelatedStructureScope {
  /**
   * @generated from enum value: RELATED_STRUCTURE_SCOPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: RELATED_STRUCTURE_SCOPE_PARTS = 1;
   */
  PARTS = 1,

  /**
   * @generated from enum value: RELATED_STRUCTURE_SCOPE_PARTS_AND_RELATED = 2;
   */
  PARTS_AND_RELATED = 2,

  /**
   * for nerves, veins, arteries
   *
   * @generated from enum value: RELATED_STRUCTURE_SCOPE_DIRECT_DOWNSTREAM_NVA = 3;
   */
  DIRECT_DOWNSTREAM_NVA = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(RelatedStructureScope)
proto3.util.setEnumType(RelatedStructureScope, "augmedi.atlas.RelatedStructureScope", [
  { no: 0, name: "RELATED_STRUCTURE_SCOPE_UNKNOWN" },
  { no: 1, name: "RELATED_STRUCTURE_SCOPE_PARTS" },
  { no: 2, name: "RELATED_STRUCTURE_SCOPE_PARTS_AND_RELATED" },
  { no: 3, name: "RELATED_STRUCTURE_SCOPE_DIRECT_DOWNSTREAM_NVA" },
]);

/**
 * @generated from message augmedi.atlas.StructureName
 */
export class StructureName extends Message<StructureName> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * ISO 639, like "en"
   *
   * @generated from field: string language = 2;
   */
  language = "";

  constructor(data?: PartialMessage<StructureName>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.StructureName";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StructureName {
    return new StructureName().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StructureName {
    return new StructureName().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StructureName {
    return new StructureName().fromJsonString(jsonString, options);
  }

  static equals(a: StructureName | PlainMessage<StructureName> | undefined, b: StructureName | PlainMessage<StructureName> | undefined): boolean {
    return proto3.util.equals(StructureName, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.EqualIdSet
 */
export class EqualIdSet extends Message<EqualIdSet> {
  /**
   * @generated from field: string structure_id = 1;
   */
  structureId = "";

  /**
   * @generated from field: string fma_id = 2;
   */
  fmaId = "";

  constructor(data?: PartialMessage<EqualIdSet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.EqualIdSet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fma_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EqualIdSet {
    return new EqualIdSet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EqualIdSet {
    return new EqualIdSet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EqualIdSet {
    return new EqualIdSet().fromJsonString(jsonString, options);
  }

  static equals(a: EqualIdSet | PlainMessage<EqualIdSet> | undefined, b: EqualIdSet | PlainMessage<EqualIdSet> | undefined): boolean {
    return proto3.util.equals(EqualIdSet, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.IdMask
 */
export class IdMask extends Message<IdMask> {
  /**
   * @generated from field: augmedi.atlas.IdSpace id_space = 1;
   */
  idSpace = IdSpace.UNKNOWN;

  /**
   * @generated from field: bytes data = 2;
   */
  data = new Uint8Array(0);

  /**
   * @generated from field: repeated string extra_ids = 3;
   */
  extraIds: string[] = [];

  constructor(data?: PartialMessage<IdMask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.IdMask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id_space", kind: "enum", T: proto3.getEnumType(IdSpace) },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "extra_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IdMask {
    return new IdMask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IdMask {
    return new IdMask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IdMask {
    return new IdMask().fromJsonString(jsonString, options);
  }

  static equals(a: IdMask | PlainMessage<IdMask> | undefined, b: IdMask | PlainMessage<IdMask> | undefined): boolean {
    return proto3.util.equals(IdMask, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.LanguagePreference
 */
export class LanguagePreference extends Message<LanguagePreference> {
  /**
   * ISO 639, default "en"
   *
   * @generated from field: string general_language = 1;
   */
  generalLanguage = "";

  /**
   * ISO 639, default ["en"]
   *
   * @generated from field: repeated string term_languages = 2;
   */
  termLanguages: string[] = [];

  constructor(data?: PartialMessage<LanguagePreference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.LanguagePreference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "general_language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "term_languages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LanguagePreference {
    return new LanguagePreference().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LanguagePreference {
    return new LanguagePreference().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LanguagePreference {
    return new LanguagePreference().fromJsonString(jsonString, options);
  }

  static equals(a: LanguagePreference | PlainMessage<LanguagePreference> | undefined, b: LanguagePreference | PlainMessage<LanguagePreference> | undefined): boolean {
    return proto3.util.equals(LanguagePreference, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.NameSearchRequest
 */
export class NameSearchRequest extends Message<NameSearchRequest> {
  /**
   * @generated from field: string query = 1;
   */
  query = "";

  /**
   * default VISIBILITY_PUBLIC
   *
   * @generated from field: augmedi.atlas.Visibility visibility = 2;
   */
  visibility = Visibility.UNKNOWN;

  constructor(data?: PartialMessage<NameSearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.NameSearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "visibility", kind: "enum", T: proto3.getEnumType(Visibility) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NameSearchRequest {
    return new NameSearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NameSearchRequest {
    return new NameSearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NameSearchRequest {
    return new NameSearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NameSearchRequest | PlainMessage<NameSearchRequest> | undefined, b: NameSearchRequest | PlainMessage<NameSearchRequest> | undefined): boolean {
    return proto3.util.equals(NameSearchRequest, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.NameSearchResponse
 */
export class NameSearchResponse extends Message<NameSearchResponse> {
  /**
   * @generated from field: repeated augmedi.atlas.NameSearchResponse.Entry entries = 1;
   */
  entries: NameSearchResponse_Entry[] = [];

  constructor(data?: PartialMessage<NameSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.NameSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: NameSearchResponse_Entry, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NameSearchResponse {
    return new NameSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NameSearchResponse {
    return new NameSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NameSearchResponse {
    return new NameSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NameSearchResponse | PlainMessage<NameSearchResponse> | undefined, b: NameSearchResponse | PlainMessage<NameSearchResponse> | undefined): boolean {
    return proto3.util.equals(NameSearchResponse, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.NameSearchResponse.Entry
 */
export class NameSearchResponse_Entry extends Message<NameSearchResponse_Entry> {
  /**
   * @generated from field: string structure_id = 1;
   */
  structureId = "";

  /**
   * Name that makes sense to show as the main text of the search result (probably, but not necessarily, the primary name of the structure)
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * The name that most directly matched the query (often not the primary name of the structure)
   *
   * @generated from field: string matching_name = 3;
   */
  matchingName = "";

  constructor(data?: PartialMessage<NameSearchResponse_Entry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.NameSearchResponse.Entry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "matching_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NameSearchResponse_Entry {
    return new NameSearchResponse_Entry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NameSearchResponse_Entry {
    return new NameSearchResponse_Entry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NameSearchResponse_Entry {
    return new NameSearchResponse_Entry().fromJsonString(jsonString, options);
  }

  static equals(a: NameSearchResponse_Entry | PlainMessage<NameSearchResponse_Entry> | undefined, b: NameSearchResponse_Entry | PlainMessage<NameSearchResponse_Entry> | undefined): boolean {
    return proto3.util.equals(NameSearchResponse_Entry, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.BatchExactNameSearchRequest
 */
export class BatchExactNameSearchRequest extends Message<BatchExactNameSearchRequest> {
  /**
   * @generated from field: repeated string queries = 1;
   */
  queries: string[] = [];

  /**
   * currently only VISIBILITY_AUGMEDI_INTERNAL is supported
   *
   * @generated from field: augmedi.atlas.Visibility visibility = 2;
   */
  visibility = Visibility.UNKNOWN;

  constructor(data?: PartialMessage<BatchExactNameSearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.BatchExactNameSearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "queries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "visibility", kind: "enum", T: proto3.getEnumType(Visibility) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchExactNameSearchRequest {
    return new BatchExactNameSearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchExactNameSearchRequest {
    return new BatchExactNameSearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchExactNameSearchRequest {
    return new BatchExactNameSearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchExactNameSearchRequest | PlainMessage<BatchExactNameSearchRequest> | undefined, b: BatchExactNameSearchRequest | PlainMessage<BatchExactNameSearchRequest> | undefined): boolean {
    return proto3.util.equals(BatchExactNameSearchRequest, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.BatchExactNameSearchResponse
 */
export class BatchExactNameSearchResponse extends Message<BatchExactNameSearchResponse> {
  /**
   * @generated from field: repeated augmedi.atlas.BatchExactNameSearchResponse.Entry entries = 1;
   */
  entries: BatchExactNameSearchResponse_Entry[] = [];

  constructor(data?: PartialMessage<BatchExactNameSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.BatchExactNameSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: BatchExactNameSearchResponse_Entry, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchExactNameSearchResponse {
    return new BatchExactNameSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchExactNameSearchResponse {
    return new BatchExactNameSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchExactNameSearchResponse {
    return new BatchExactNameSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchExactNameSearchResponse | PlainMessage<BatchExactNameSearchResponse> | undefined, b: BatchExactNameSearchResponse | PlainMessage<BatchExactNameSearchResponse> | undefined): boolean {
    return proto3.util.equals(BatchExactNameSearchResponse, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.BatchExactNameSearchResponse.Entry
 */
export class BatchExactNameSearchResponse_Entry extends Message<BatchExactNameSearchResponse_Entry> {
  /**
   * @generated from field: string query = 1;
   */
  query = "";

  /**
   * only set if there is exactly one match
   *
   * @generated from field: augmedi.atlas.EqualIdSet match = 2;
   */
  match?: EqualIdSet;

  constructor(data?: PartialMessage<BatchExactNameSearchResponse_Entry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.BatchExactNameSearchResponse.Entry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "match", kind: "message", T: EqualIdSet },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchExactNameSearchResponse_Entry {
    return new BatchExactNameSearchResponse_Entry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchExactNameSearchResponse_Entry {
    return new BatchExactNameSearchResponse_Entry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchExactNameSearchResponse_Entry {
    return new BatchExactNameSearchResponse_Entry().fromJsonString(jsonString, options);
  }

  static equals(a: BatchExactNameSearchResponse_Entry | PlainMessage<BatchExactNameSearchResponse_Entry> | undefined, b: BatchExactNameSearchResponse_Entry | PlainMessage<BatchExactNameSearchResponse_Entry> | undefined): boolean {
    return proto3.util.equals(BatchExactNameSearchResponse_Entry, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.DisplayTreeNode
 */
export class DisplayTreeNode extends Message<DisplayTreeNode> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * 0-n items, since different anatomy ontologies don't map 1:1; the first one is the "best" match
   *
   * @generated from field: repeated string structure_ids = 3;
   */
  structureIds: string[] = [];

  /**
   * empty for top level nodes, which there may be multiple of, so technically this is not a single tree
   *
   * @generated from field: string parent_id = 4;
   */
  parentId = "";

  /**
   * children of the same parent should be sorted by this field (ascending)
   *
   * @generated from field: int32 order = 5;
   */
  order = 0;

  constructor(data?: PartialMessage<DisplayTreeNode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.DisplayTreeNode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "structure_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "parent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DisplayTreeNode {
    return new DisplayTreeNode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DisplayTreeNode {
    return new DisplayTreeNode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DisplayTreeNode {
    return new DisplayTreeNode().fromJsonString(jsonString, options);
  }

  static equals(a: DisplayTreeNode | PlainMessage<DisplayTreeNode> | undefined, b: DisplayTreeNode | PlainMessage<DisplayTreeNode> | undefined): boolean {
    return proto3.util.equals(DisplayTreeNode, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.GetDisplayTreeRequest
 */
export class GetDisplayTreeRequest extends Message<GetDisplayTreeRequest> {
  /**
   * default VISIBILITY_PUBLIC
   *
   * @generated from field: augmedi.atlas.Visibility visibility = 2;
   */
  visibility = Visibility.UNKNOWN;

  constructor(data?: PartialMessage<GetDisplayTreeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.GetDisplayTreeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "visibility", kind: "enum", T: proto3.getEnumType(Visibility) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDisplayTreeRequest {
    return new GetDisplayTreeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDisplayTreeRequest {
    return new GetDisplayTreeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDisplayTreeRequest {
    return new GetDisplayTreeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDisplayTreeRequest | PlainMessage<GetDisplayTreeRequest> | undefined, b: GetDisplayTreeRequest | PlainMessage<GetDisplayTreeRequest> | undefined): boolean {
    return proto3.util.equals(GetDisplayTreeRequest, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.GetDisplayTreeResponse
 */
export class GetDisplayTreeResponse extends Message<GetDisplayTreeResponse> {
  /**
   * sorted by DisplayTreeNode.order
   *
   * @generated from field: repeated augmedi.atlas.DisplayTreeNode nodes = 1;
   */
  nodes: DisplayTreeNode[] = [];

  constructor(data?: PartialMessage<GetDisplayTreeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.GetDisplayTreeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nodes", kind: "message", T: DisplayTreeNode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDisplayTreeResponse {
    return new GetDisplayTreeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDisplayTreeResponse {
    return new GetDisplayTreeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDisplayTreeResponse {
    return new GetDisplayTreeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDisplayTreeResponse | PlainMessage<GetDisplayTreeResponse> | undefined, b: GetDisplayTreeResponse | PlainMessage<GetDisplayTreeResponse> | undefined): boolean {
    return proto3.util.equals(GetDisplayTreeResponse, a, b);
  }
}

/**
 * May be exposed outside of our system if the structure is visible to the requesting user.
 *
 * @generated from message augmedi.atlas.StructureMetadataBase
 */
export class StructureMetadataBase extends Message<StructureMetadataBase> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated augmedi.atlas.StructureName alternative_names = 3;
   */
  alternativeNames: StructureName[] = [];

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  constructor(data?: PartialMessage<StructureMetadataBase>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.StructureMetadataBase";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "alternative_names", kind: "message", T: StructureName, repeated: true },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StructureMetadataBase {
    return new StructureMetadataBase().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StructureMetadataBase {
    return new StructureMetadataBase().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StructureMetadataBase {
    return new StructureMetadataBase().fromJsonString(jsonString, options);
  }

  static equals(a: StructureMetadataBase | PlainMessage<StructureMetadataBase> | undefined, b: StructureMetadataBase | PlainMessage<StructureMetadataBase> | undefined): boolean {
    return proto3.util.equals(StructureMetadataBase, a, b);
  }
}

/**
 * Do not expose outside of our system, even if the structure is visible to the requesting user.
 *
 * @generated from message augmedi.atlas.StructureMetadataExtended
 */
export class StructureMetadataExtended extends Message<StructureMetadataExtended> {
  /**
   * @generated from field: augmedi.atlas.EqualIdSet equal_id_set = 1;
   */
  equalIdSet?: EqualIdSet;

  /**
   * ID of the equivalent structure with left/right removed. Empty if this structure is already unsided.
   *
   * @generated from field: string unsided_structure_id = 2;
   */
  unsidedStructureId = "";

  constructor(data?: PartialMessage<StructureMetadataExtended>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.StructureMetadataExtended";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "equal_id_set", kind: "message", T: EqualIdSet },
    { no: 2, name: "unsided_structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StructureMetadataExtended {
    return new StructureMetadataExtended().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StructureMetadataExtended {
    return new StructureMetadataExtended().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StructureMetadataExtended {
    return new StructureMetadataExtended().fromJsonString(jsonString, options);
  }

  static equals(a: StructureMetadataExtended | PlainMessage<StructureMetadataExtended> | undefined, b: StructureMetadataExtended | PlainMessage<StructureMetadataExtended> | undefined): boolean {
    return proto3.util.equals(StructureMetadataExtended, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.GetStructureMetadataRequest
 */
export class GetStructureMetadataRequest extends Message<GetStructureMetadataRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * default VISIBILITY_PUBLIC
   *
   * @generated from field: augmedi.atlas.Visibility visibility = 2;
   */
  visibility = Visibility.UNKNOWN;

  /**
   * @generated from field: augmedi.atlas.LanguagePreference language_preference = 3;
   */
  languagePreference?: LanguagePreference;

  constructor(data?: PartialMessage<GetStructureMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.GetStructureMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "visibility", kind: "enum", T: proto3.getEnumType(Visibility) },
    { no: 3, name: "language_preference", kind: "message", T: LanguagePreference },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStructureMetadataRequest {
    return new GetStructureMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStructureMetadataRequest {
    return new GetStructureMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStructureMetadataRequest {
    return new GetStructureMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStructureMetadataRequest | PlainMessage<GetStructureMetadataRequest> | undefined, b: GetStructureMetadataRequest | PlainMessage<GetStructureMetadataRequest> | undefined): boolean {
    return proto3.util.equals(GetStructureMetadataRequest, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.GetStructureMetadataResponse
 */
export class GetStructureMetadataResponse extends Message<GetStructureMetadataResponse> {
  /**
   * @generated from field: augmedi.atlas.StructureMetadataBase base = 1;
   */
  base?: StructureMetadataBase;

  /**
   * @generated from field: augmedi.atlas.StructureMetadataExtended extended = 2;
   */
  extended?: StructureMetadataExtended;

  constructor(data?: PartialMessage<GetStructureMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.GetStructureMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base", kind: "message", T: StructureMetadataBase },
    { no: 2, name: "extended", kind: "message", T: StructureMetadataExtended },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStructureMetadataResponse {
    return new GetStructureMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStructureMetadataResponse {
    return new GetStructureMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStructureMetadataResponse {
    return new GetStructureMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetStructureMetadataResponse | PlainMessage<GetStructureMetadataResponse> | undefined, b: GetStructureMetadataResponse | PlainMessage<GetStructureMetadataResponse> | undefined): boolean {
    return proto3.util.equals(GetStructureMetadataResponse, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.ListStructuresRequest
 */
export class ListStructuresRequest extends Message<ListStructuresRequest> {
  constructor(data?: PartialMessage<ListStructuresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.ListStructuresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStructuresRequest {
    return new ListStructuresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStructuresRequest {
    return new ListStructuresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStructuresRequest {
    return new ListStructuresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListStructuresRequest | PlainMessage<ListStructuresRequest> | undefined, b: ListStructuresRequest | PlainMessage<ListStructuresRequest> | undefined): boolean {
    return proto3.util.equals(ListStructuresRequest, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.ListStructuresResponse
 */
export class ListStructuresResponse extends Message<ListStructuresResponse> {
  /**
   * @generated from field: repeated augmedi.atlas.ListStructuresResponse.Entry entries = 1;
   */
  entries: ListStructuresResponse_Entry[] = [];

  constructor(data?: PartialMessage<ListStructuresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.ListStructuresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: ListStructuresResponse_Entry, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStructuresResponse {
    return new ListStructuresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStructuresResponse {
    return new ListStructuresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStructuresResponse {
    return new ListStructuresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListStructuresResponse | PlainMessage<ListStructuresResponse> | undefined, b: ListStructuresResponse | PlainMessage<ListStructuresResponse> | undefined): boolean {
    return proto3.util.equals(ListStructuresResponse, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.ListStructuresResponse.Entry
 */
export class ListStructuresResponse_Entry extends Message<ListStructuresResponse_Entry> {
  /**
   * @generated from field: string structure_id = 1;
   */
  structureId = "";

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<ListStructuresResponse_Entry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.ListStructuresResponse.Entry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStructuresResponse_Entry {
    return new ListStructuresResponse_Entry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStructuresResponse_Entry {
    return new ListStructuresResponse_Entry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStructuresResponse_Entry {
    return new ListStructuresResponse_Entry().fromJsonString(jsonString, options);
  }

  static equals(a: ListStructuresResponse_Entry | PlainMessage<ListStructuresResponse_Entry> | undefined, b: ListStructuresResponse_Entry | PlainMessage<ListStructuresResponse_Entry> | undefined): boolean {
    return proto3.util.equals(ListStructuresResponse_Entry, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.ResolveStructureIdRequest
 */
export class ResolveStructureIdRequest extends Message<ResolveStructureIdRequest> {
  /**
   * At least one field of EqualIdSet must be set. Conflicting IDs or IDs that
   * don't correspond to any structure cause errors.
   *
   * @generated from field: augmedi.atlas.EqualIdSet partial_ids = 1;
   */
  partialIds?: EqualIdSet;

  constructor(data?: PartialMessage<ResolveStructureIdRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.ResolveStructureIdRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "partial_ids", kind: "message", T: EqualIdSet },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveStructureIdRequest {
    return new ResolveStructureIdRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveStructureIdRequest {
    return new ResolveStructureIdRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveStructureIdRequest {
    return new ResolveStructureIdRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveStructureIdRequest | PlainMessage<ResolveStructureIdRequest> | undefined, b: ResolveStructureIdRequest | PlainMessage<ResolveStructureIdRequest> | undefined): boolean {
    return proto3.util.equals(ResolveStructureIdRequest, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.ResolveStructureIdResponse
 */
export class ResolveStructureIdResponse extends Message<ResolveStructureIdResponse> {
  /**
   * All available IDs will be set.
   *
   * @generated from field: augmedi.atlas.EqualIdSet resolved_ids = 1;
   */
  resolvedIds?: EqualIdSet;

  constructor(data?: PartialMessage<ResolveStructureIdResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.ResolveStructureIdResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resolved_ids", kind: "message", T: EqualIdSet },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveStructureIdResponse {
    return new ResolveStructureIdResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveStructureIdResponse {
    return new ResolveStructureIdResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveStructureIdResponse {
    return new ResolveStructureIdResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveStructureIdResponse | PlainMessage<ResolveStructureIdResponse> | undefined, b: ResolveStructureIdResponse | PlainMessage<ResolveStructureIdResponse> | undefined): boolean {
    return proto3.util.equals(ResolveStructureIdResponse, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.GetRelatedStructuresRequest
 */
export class GetRelatedStructuresRequest extends Message<GetRelatedStructuresRequest> {
  /**
   * If multiple IDs are set, the union of related structures over all supplied IDs is returned.
   *
   * @generated from field: augmedi.atlas.IdMask query_structures = 1;
   */
  queryStructures?: IdMask;

  /**
   * @generated from field: augmedi.atlas.RelatedStructureScope scope = 2;
   */
  scope = RelatedStructureScope.UNKNOWN;

  /**
   * @generated from field: augmedi.atlas.IdSpace response_id_space = 3;
   */
  responseIdSpace = IdSpace.UNKNOWN;

  constructor(data?: PartialMessage<GetRelatedStructuresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.GetRelatedStructuresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query_structures", kind: "message", T: IdMask },
    { no: 2, name: "scope", kind: "enum", T: proto3.getEnumType(RelatedStructureScope) },
    { no: 3, name: "response_id_space", kind: "enum", T: proto3.getEnumType(IdSpace) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRelatedStructuresRequest {
    return new GetRelatedStructuresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRelatedStructuresRequest {
    return new GetRelatedStructuresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRelatedStructuresRequest {
    return new GetRelatedStructuresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRelatedStructuresRequest | PlainMessage<GetRelatedStructuresRequest> | undefined, b: GetRelatedStructuresRequest | PlainMessage<GetRelatedStructuresRequest> | undefined): boolean {
    return proto3.util.equals(GetRelatedStructuresRequest, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.GetRelatedStructuresResponse
 */
export class GetRelatedStructuresResponse extends Message<GetRelatedStructuresResponse> {
  /**
   * @generated from field: augmedi.atlas.IdMask related_structures = 1;
   */
  relatedStructures?: IdMask;

  constructor(data?: PartialMessage<GetRelatedStructuresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.GetRelatedStructuresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "related_structures", kind: "message", T: IdMask },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRelatedStructuresResponse {
    return new GetRelatedStructuresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRelatedStructuresResponse {
    return new GetRelatedStructuresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRelatedStructuresResponse {
    return new GetRelatedStructuresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRelatedStructuresResponse | PlainMessage<GetRelatedStructuresResponse> | undefined, b: GetRelatedStructuresResponse | PlainMessage<GetRelatedStructuresResponse> | undefined): boolean {
    return proto3.util.equals(GetRelatedStructuresResponse, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.EnumerateIdSpaceRequest
 */
export class EnumerateIdSpaceRequest extends Message<EnumerateIdSpaceRequest> {
  /**
   * @generated from field: augmedi.atlas.IdSpace id_space = 1;
   */
  idSpace = IdSpace.UNKNOWN;

  constructor(data?: PartialMessage<EnumerateIdSpaceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.EnumerateIdSpaceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id_space", kind: "enum", T: proto3.getEnumType(IdSpace) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnumerateIdSpaceRequest {
    return new EnumerateIdSpaceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnumerateIdSpaceRequest {
    return new EnumerateIdSpaceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnumerateIdSpaceRequest {
    return new EnumerateIdSpaceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EnumerateIdSpaceRequest | PlainMessage<EnumerateIdSpaceRequest> | undefined, b: EnumerateIdSpaceRequest | PlainMessage<EnumerateIdSpaceRequest> | undefined): boolean {
    return proto3.util.equals(EnumerateIdSpaceRequest, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.EnumerateIdSpaceResponse
 */
export class EnumerateIdSpaceResponse extends Message<EnumerateIdSpaceResponse> {
  /**
   * The index of the Entry in entries is the index of the structure in the ID space.
   *
   * @generated from field: repeated augmedi.atlas.EnumerateIdSpaceResponse.Entry entries = 1;
   */
  entries: EnumerateIdSpaceResponse_Entry[] = [];

  constructor(data?: PartialMessage<EnumerateIdSpaceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.EnumerateIdSpaceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: EnumerateIdSpaceResponse_Entry, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnumerateIdSpaceResponse {
    return new EnumerateIdSpaceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnumerateIdSpaceResponse {
    return new EnumerateIdSpaceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnumerateIdSpaceResponse {
    return new EnumerateIdSpaceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EnumerateIdSpaceResponse | PlainMessage<EnumerateIdSpaceResponse> | undefined, b: EnumerateIdSpaceResponse | PlainMessage<EnumerateIdSpaceResponse> | undefined): boolean {
    return proto3.util.equals(EnumerateIdSpaceResponse, a, b);
  }
}

/**
 * @generated from message augmedi.atlas.EnumerateIdSpaceResponse.Entry
 */
export class EnumerateIdSpaceResponse_Entry extends Message<EnumerateIdSpaceResponse_Entry> {
  /**
   * null if this index is not mapped to a structure
   *
   * @generated from field: augmedi.atlas.EqualIdSet equal_id_set = 1;
   */
  equalIdSet?: EqualIdSet;

  constructor(data?: PartialMessage<EnumerateIdSpaceResponse_Entry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.atlas.EnumerateIdSpaceResponse.Entry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "equal_id_set", kind: "message", T: EqualIdSet },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnumerateIdSpaceResponse_Entry {
    return new EnumerateIdSpaceResponse_Entry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnumerateIdSpaceResponse_Entry {
    return new EnumerateIdSpaceResponse_Entry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnumerateIdSpaceResponse_Entry {
    return new EnumerateIdSpaceResponse_Entry().fromJsonString(jsonString, options);
  }

  static equals(a: EnumerateIdSpaceResponse_Entry | PlainMessage<EnumerateIdSpaceResponse_Entry> | undefined, b: EnumerateIdSpaceResponse_Entry | PlainMessage<EnumerateIdSpaceResponse_Entry> | undefined): boolean {
    return proto3.util.equals(EnumerateIdSpaceResponse_Entry, a, b);
  }
}

