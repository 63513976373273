/* eslint react/display-name: off, react/prop-types: off, react/no-unknown-property: off */
import { CameraControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import CameraControlsImpl from "camera-controls";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import * as THREE from "three";
import { defaultCamera } from "../logic/camera";

export interface Props {
  backgroundColor?: THREE.ColorRepresentation;
  disableCameraControls?: boolean;
  initialCameraPosition?: number[];
}

export interface SharedModelPreviewStuffRef {
  resetCamera: () => void;
  pointCameraToMesh: (meshes: string[]) => void;
  playAnswerVisualFeedback: (targetColor: THREE.Color) => Promise<void>;
}

interface PrivateCameraControls {
  _cameraUp0: THREE.Vector3;
  _position0: THREE.Vector3;
  _zoom0: number;
}

export const SharedModelPreviewStuff = forwardRef<
  SharedModelPreviewStuffRef,
  Props
>(({ backgroundColor, disableCameraControls, initialCameraPosition }, ref) => {
  const controlsRef = useRef<CameraControls>(null);
  const boundingBoxRef = useRef<THREE.Box3 | null>(null);
  const { scene } = useThree();

  useFrame(({ scene }) => {
    const meshes = scene.children.filter(
      (child) => child.type === "Mesh" && !child.userData?.ignoreForFit,
    );

    if (!meshes.length) {
      return { meshes: [], boundingBox: null, boundingBoxCenter: null };
    }

    const boundingBox = new THREE.Box3().setFromObject(meshes[0]);
    for (const mesh of meshes.slice(1)) {
      boundingBox.union(new THREE.Box3().setFromObject(mesh));
    }
    if (boundingBoxRef.current?.equals(boundingBox)) {
      return;
    }
    boundingBoxRef.current = boundingBox;
    resetCamera();
  });

  const resetCamera = useCallback(() => {
    const controls = controlsRef.current;
    if (!boundingBoxRef.current || !controls) {
      return;
    }

    // HACK The View component from @react-three/drei does not reset some camera
    // parameters to their defaults when rendering different views. This leads
    // to a bug where if you open a page with an OwnCanvas (internally View),
    // leave the page (SPA navigation), resize the window, then open the page
    // again, the camera will reset to a weird angle. This is because
    // CameraControls was created when entering the page for the second time. At
    // that time the camera already had non-default parameters, but
    // CameraControls assumed that these were the defaults. This code sets the
    // true defaults in CameraControls private state, so that CameraControls can
    // reset to them.
    const privateControls = controls as unknown as PrivateCameraControls;
    privateControls._cameraUp0.set(...defaultCamera.up);
    privateControls._position0.set(...defaultCamera.position);
    privateControls._zoom0 = defaultCamera.zoom;

    controls.reset(true);
    const boundingBoxCenter = boundingBoxRef.current.getCenter(
      new THREE.Vector3(),
    );
    controlsRef.current?.setOrbitPoint(
      boundingBoxCenter.x,
      boundingBoxCenter.y,
      boundingBoxCenter.z,
    );

    if (initialCameraPosition) {
      controlsRef.current?.setLookAt(
        initialCameraPosition[0],
        initialCameraPosition[1],
        initialCameraPosition[2],
        boundingBoxCenter.x,
        boundingBoxCenter.y,
        boundingBoxCenter.z,
        true,
      );
    }
    // Create bounding sphere using Three.Sphere
    const boundingSphere = new THREE.Sphere();
    boundingBoxRef.current.getBoundingSphere(boundingSphere);
    // Fit camera to sphere
    controlsRef.current?.fitToSphere(boundingSphere, true);
  }, []);

  useEffect(() => {
    resetCamera();
  }, [controlsRef.current]);

  const pointCameraToMesh = useCallback((incomingMeshes: string[]) => {
    // filter scene meshes for the highlighted meshes
    const targetMeshes = scene.children.filter(
      (child) => child.type === "Mesh" && incomingMeshes.includes(child.name),
    );
    if (!targetMeshes.length) {
      return;
    }
    // calculate the bounding box of the target meshes
    const boundingBox = new THREE.Box3().setFromObject(targetMeshes[0]);
    for (const mesh of targetMeshes.slice(1)) {
      boundingBox.union(new THREE.Box3().setFromObject(mesh));
    }
    // do not update the view if the bounding box is the same
    if (boundingBoxRef.current?.equals(boundingBox)) {
      return;
    }
    boundingBoxRef.current = boundingBox;
    controlsRef.current?.fitToBox(boundingBoxRef.current, true, {
      paddingTop: 0.1,
      paddingLeft: 0.1,
      paddingBottom: 0.1,
      paddingRight: 0.1,
    });
    return;
  }, []);

  const playAnswerVisualFeedback = useCallback(
    async (targetColor: THREE.Color) => {
      if (!(scene.background instanceof THREE.Color)) {
        console.error(
          "[SharedmODELpreviewStuff]-playAnswerVisualFeedback: Scene background should be a THREE.Color.",
        );
        return;
      }

      const currColor = scene.background;
      const whiteColor = new THREE.Color("white");
      // Lerp from current color to target color
      let alpha = 0;
      while (alpha <= 1) {
        await new Promise(requestAnimationFrame);
        scene.background = currColor.lerp(targetColor, alpha);
        alpha += 0.05;
      }
      // Lerp from target color to white - HACK using just 0.5 since since it's visually better
      alpha = 0;
      while (alpha <= 0.5) {
        await new Promise(requestAnimationFrame);
        scene.background = targetColor.lerp(whiteColor, alpha);
        alpha += 0.02;
      }
      scene.background = whiteColor;
    },
    [],
  );

  useImperativeHandle(
    ref,
    (): SharedModelPreviewStuffRef => ({
      resetCamera,
      pointCameraToMesh,
      playAnswerVisualFeedback,
    }),
    [resetCamera, pointCameraToMesh, playAnswerVisualFeedback],
  );

  return (
    <>
      {backgroundColor && (
        <color attach="background" args={[backgroundColor]} />
      )}
      <CameraControls
        enabled={!disableCameraControls}
        ref={controlsRef}
        makeDefault
        mouseButtons={{
          right: CameraControlsImpl.ACTION.OFFSET,
          left: CameraControlsImpl.ACTION.ROTATE,
          wheel: CameraControlsImpl.ACTION.DOLLY,
          middle: CameraControlsImpl.ACTION.DOLLY,
        }}
        touches={{
          one: CameraControlsImpl.ACTION.TOUCH_ROTATE,
          two: CameraControlsImpl.ACTION.TOUCH_DOLLY_OFFSET,
          three: CameraControlsImpl.ACTION.TOUCH_DOLLY_OFFSET,
        }}
      />

      {/* Scene Light Sources */}
      <ambientLight intensity={0.6} />
      <pointLight position={[1, -1, 1]} intensity={10} />
      <pointLight position={[-1, 1, -1]} intensity={7} />
    </>
  );
});
