import { type RenderProps as CanvasProps } from "@react-three/fiber";

export const defaultCamera = {
  fov: 45,
  near: 0.01,
  far: 20,
  up: [0, 1, 0],
  position: [0, 0, 0.5],
  zoom: 1,
} satisfies CanvasProps<HTMLCanvasElement>["camera"];
