import { type NotificationData, notifications } from "@mantine/notifications";

export function showErrorNotification(args: NotificationData) {
  notifications.show({
    color: "red",
    title: "Error",
    withCloseButton: true,
    autoClose: 2500,
    ...args,
    style: {
      position: "fixed",
      top: "5rem",
      left: "1rem",
      ...args.style,
    },
  });
}
