import { atlasPb, quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { type PlainMessage } from "@bufbuild/protobuf";
import { useMutation, useQuery } from "@connectrpc/connect-query";
import {
  Box,
  Button,
  Center,
  Group,
  Image,
  Modal,
  Overlay,
  ScrollArea,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import * as Sentry from "@sentry/react";
import { IconPhoto } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { Suspense, useMemo, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { annotatorLeftModalProps } from "../logic/annotator-left-modal.js";
import { AppLayout, useAppLayout } from "../logic/app-layout.js";
import { useNavigationLockAndBeforeUnload } from "../logic/navigation-lock.js";
import { showErrorNotification } from "../logic/notification.js";
import { createAnyArgumentsQueryKey } from "../logic/query-key.js";
import { AnnotatorInferChildrenModalContent } from "./AnnotatorInferChildrenModalContent.js";
import { ChildLabelList } from "./ChildLabelList.js";
import { CreateNewDirectedEdgeModal } from "./CreateNewDirectedEdgeModal.js";
import { FallbackWithReset } from "./FallbackWithReset.js";
import { LargeLoader } from "./LargeLoader.js";
import { PageMenu } from "./PageMenu.js";
import { StructureSelect } from "./StructureSelect.js";

export const AnnotatorPage = () => {
  const queryClient = useQueryClient();

  const [mediaItemId, setMediaItemId] = useState("");
  const imageQuery = useQuery(quizConnectQuery.getMediaItemAsset, {
    id: mediaItemId,
  });

  const [
    isCreateNewDirectedEdgeModalOpen,
    {
      toggle: toggleCreateNewDirectedEdgeModal,
      close: closeCreateNewDirectedEdgeModal,
    },
  ] = useDisclosure(false);

  const [selectedParentLabel, setSelectedParentLabel] =
    useState<PlainMessage<quizPb.ModelLabel>>();
  const [selectedChildLabel, setSelectedChildLabel] =
    useState<PlainMessage<quizPb.ModelLabel>>();

  const downstreamLabelsQuery = useQuery(
    quizConnectQuery.listDownstreamStructures,
    {
      upstreamStructureId: selectedParentLabel?.structureId ?? "",
    },
  );

  const childLabelList = useMemo(
    () =>
      (downstreamLabelsQuery.data?.downstreamStructures ?? []).map(
        (structure) =>
          new quizPb.ModelLabel({
            id: structure.downstreamStructureId,
            name: structure.notes,
          }),
      ),
    [downstreamLabelsQuery.data],
  );

  const [structureIdToInfer, setStructureIdToInfer] = useState<string>();
  const [labelToDelete, setLabelToDelete] = useState<{
    id: string;
    name: string | undefined;
  }>();

  const createEdgeMutation = useMutation(quizConnectQuery.addEdge, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: createAnyArgumentsQueryKey(
          quizConnectQuery.listDownstreamStructures,
        ),
      });
    },
    onError: () =>
      showErrorNotification({ message: "Failed to create new edge." }),
  });
  const deleteEdgeMutation = useMutation(quizConnectQuery.deleteEdge, {
    onSuccess: async () => {
      if (!selectedParentLabel) {
        throw new Error(
          "Failed to set selected label. A parent label should be select even after child label deletion",
        );
      }
      await queryClient.invalidateQueries({
        queryKey: createAnyArgumentsQueryKey(
          quizConnectQuery.listDownstreamStructures,
        ),
      });
      setSelectedChildLabel(undefined);
      setLabelToDelete(undefined);
    },
  });

  const anythingSaving =
    createEdgeMutation.isPending || deleteEdgeMutation.isPending;
  useNavigationLockAndBeforeUnload(anythingSaving);

  const userVisibleSaveState = anythingSaving ? "saving" : "saved";
  const userVisibleSaveStateLabels: {
    [K in typeof userVisibleSaveState]: string;
  } = {
    saved: "All changes saved",
    saving: "Saving...",
  };

  useAppLayout(AppLayout.FullscreenWithHeader);

  const onParentStructureSelect = (
    entry: PlainMessage<atlasPb.NameSearchResponse_Entry> | undefined,
  ) => {
    if (entry) {
      setSelectedParentLabel(
        new quizPb.ModelLabel({
          structureId: entry.structureId,
          id: entry.structureId,
          name: entry.displayName,
        }),
      );
      setStructureIdToInfer(entry.structureId);
    } else {
      setSelectedParentLabel(undefined);
    }
    setSelectedChildLabel(undefined);
  };

  const onChildLabelSelect = (newLabel: PlainMessage<quizPb.ModelLabel>) => {
    setSelectedChildLabel(newLabel);
  };

  const onAddChildLabel = () => {
    if (!selectedParentLabel) {
      throw new Error("Failed to add new label. No parent label selected.");
    }
    toggleCreateNewDirectedEdgeModal();
  };

  return (
    <>
      <Group
        style={{ height: "100%", overflow: "hidden" }}
        align="stretch"
        wrap="nowrap"
        gap={0}
      >
        <Box
          style={{
            borderRight: "1px solid #dee2e6",
            position: "relative",
          }}
        >
          <Stack
            p="sm"
            style={{
              height: "100%",
              width: "40vw",
              maxWidth: "600px",
              minWidth: "300px",
            }}
          >
            <Text fs="italic" c="dimmed">
              {userVisibleSaveStateLabels[userVisibleSaveState]}
            </Text>
            <Stack gap={5} mb="sm">
              <Title order={5}>Parent structure</Title>
              <StructureSelect
                structureId={selectedParentLabel?.id}
                onStructureIdChange={(_structureId, entry) =>
                  onParentStructureSelect(entry)
                }
                spotlightRootProps={annotatorLeftModalProps}
              />
            </Stack>
            <div style={{ height: 300, flexGrow: 1, overflow: "hidden" }}>
              <ChildLabelList
                childLabelList={childLabelList}
                selectedChildLabelId={selectedChildLabel?.id}
                onChildLabelSelect={onChildLabelSelect}
                otherVisibleLabelIds={new Set()}
                toggleLabelVisible={() => {}}
                onAddChildLabel={selectedParentLabel && onAddChildLabel}
                useEyes={false}
                useXes={true}
                title="Branches"
                xFn={(labelId: string) => {
                  setLabelToDelete({
                    id: labelId,
                    name: childLabelList.find((label) => label.id === labelId)
                      ?.name,
                  });
                }}
                itemWidth="100%"
              />
            </div>
          </Stack>
          {!mediaItemId && (
            <Overlay backgroundOpacity={0.3} blur={15}>
              <Center h="100%">
                <Stack>
                  <Center>
                    <IconPhoto color="white" size={80} />
                  </Center>
                  <Text c="white">
                    Select an image on the right to start annotating
                  </Text>
                </Stack>
              </Center>
            </Overlay>
          )}
        </Box>
        <Box
          style={{
            flexGrow: 1,
            overflow: "hidden",
            cursor: "not-allowed",
          }}
        >
          <PageMenu setImageId={(imageId) => setMediaItemId(imageId)} />
          <ScrollArea h={"100%"}>
            <Zoom>
              <Image
                src={imageQuery.data?.downloadUrl}
                alt="Chose an image above"
              />
            </Zoom>
          </ScrollArea>
        </Box>
      </Group>
      <Modal
        opened={!!labelToDelete}
        onClose={() => setLabelToDelete(undefined)}
        title="Delete branch?"
      >
        <Stack>
          <Text>
            {`Are you sure you want to delete the branch "${labelToDelete?.name ?? labelToDelete?.id}"?`}
          </Text>
          <Button
            color="red"
            onClick={() =>
              deleteEdgeMutation.mutate({
                upstreamStructureId: selectedParentLabel?.id,
                downstreamStructureId: labelToDelete?.id,
              })
            }
            fullWidth
          >
            Delete
          </Button>
        </Stack>
      </Modal>
      {selectedParentLabel && (
        <CreateNewDirectedEdgeModal
          mediaItemId={mediaItemId}
          upstreamStructure={selectedParentLabel}
          isModalOpen={isCreateNewDirectedEdgeModalOpen}
          createEdgeMutation={createEdgeMutation}
          closeModal={closeCreateNewDirectedEdgeModal}
        />
      )}
      <Modal
        {...annotatorLeftModalProps}
        opened={!!structureIdToInfer}
        withCloseButton
        onClose={() => setStructureIdToInfer(undefined)}
        title="Add suggested branches"
      >
        <Sentry.ErrorBoundary fallback={FallbackWithReset}>
          <Suspense fallback={<LargeLoader />}>
            {structureIdToInfer && (
              <AnnotatorInferChildrenModalContent
                parentStructureId={structureIdToInfer}
                mediaItemId={mediaItemId}
                onClose={() => setStructureIdToInfer(undefined)}
              />
            )}
          </Suspense>
        </Sentry.ErrorBoundary>
      </Modal>
    </>
  );
};
