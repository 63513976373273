/* eslint-disable react/no-unknown-property */
import { useLoader } from "@react-three/fiber";
import { OBJLoader } from "three-stdlib";

interface ComponentSettings {
  index: number;
  url: string;
  color: string;
}

interface Props {
  componentSettings: ComponentSettings[];
  onSelectComponent: (settings: ComponentSettings) => void;
}

export const TreeAnatomyCanvasContent = ({
  componentSettings,
  onSelectComponent,
}: Props) => {
  const loadedModels = useLoader(
    OBJLoader,
    componentSettings.map((c) => c.url),
  );

  return (
    <>
      <ambientLight intensity={2.0} />
      {componentSettings.map((settings, i) => (
        <mesh
          key={settings.url}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          geometry={(loadedModels[i].children[0] as any).geometry}
          onClick={() => onSelectComponent(settings)}
        >
          <meshStandardMaterial color={settings.color} />
        </mesh>
      ))}
    </>
  );
};
