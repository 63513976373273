// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file proto/quiz.proto (package augmedi.quiz, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum augmedi.quiz.ModelAssetType
 */
export enum ModelAssetType {
  /**
   * @generated from enum value: MODEL_ASSET_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: MODEL_ASSET_TYPE_GLTF = 1;
   */
  GLTF = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ModelAssetType)
proto3.util.setEnumType(ModelAssetType, "augmedi.quiz.ModelAssetType", [
  { no: 0, name: "MODEL_ASSET_TYPE_UNKNOWN" },
  { no: 1, name: "MODEL_ASSET_TYPE_GLTF" },
]);

/**
 * @generated from enum augmedi.quiz.FrozenModelAssetType
 */
export enum FrozenModelAssetType {
  /**
   * @generated from enum value: FROZEN_MODEL_ASSET_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: FROZEN_MODEL_ASSET_TYPE_GLTF = 1;
   */
  GLTF = 1,

  /**
   * @generated from enum value: FROZEN_MODEL_ASSET_TYPE_GLTF_WEB_V2 = 3;
   */
  GLTF_WEB_V2 = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(FrozenModelAssetType)
proto3.util.setEnumType(FrozenModelAssetType, "augmedi.quiz.FrozenModelAssetType", [
  { no: 0, name: "FROZEN_MODEL_ASSET_TYPE_UNKNOWN" },
  { no: 1, name: "FROZEN_MODEL_ASSET_TYPE_GLTF" },
  { no: 3, name: "FROZEN_MODEL_ASSET_TYPE_GLTF_WEB_V2" },
]);

/**
 * @generated from enum augmedi.quiz.MediaItemType
 */
export enum MediaItemType {
  /**
   * @generated from enum value: MEDIA_ITEM_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: MEDIA_ITEM_TYPE_IMAGE = 1;
   */
  IMAGE = 1,

  /**
   * @generated from enum value: MEDIA_ITEM_TYPE_VIDEO = 2;
   */
  VIDEO = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(MediaItemType)
proto3.util.setEnumType(MediaItemType, "augmedi.quiz.MediaItemType", [
  { no: 0, name: "MEDIA_ITEM_TYPE_UNKNOWN" },
  { no: 1, name: "MEDIA_ITEM_TYPE_IMAGE" },
  { no: 2, name: "MEDIA_ITEM_TYPE_VIDEO" },
]);

/**
 * @generated from enum augmedi.quiz.QuizMode
 */
export enum QuizMode {
  /**
   * @generated from enum value: QUIZ_MODE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: QUIZ_MODE_LEARN_NEW = 1;
   */
  LEARN_NEW = 1,

  /**
   * @generated from enum value: QUIZ_MODE_REPEAT_OLD = 2;
   */
  REPEAT_OLD = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(QuizMode)
proto3.util.setEnumType(QuizMode, "augmedi.quiz.QuizMode", [
  { no: 0, name: "QUIZ_MODE_UNKNOWN" },
  { no: 1, name: "QUIZ_MODE_LEARN_NEW" },
  { no: 2, name: "QUIZ_MODE_REPEAT_OLD" },
]);

/**
 * @generated from enum augmedi.quiz.BodyPart
 */
export enum BodyPart {
  /**
   * @generated from enum value: BODYPART_UNKNOWN = 0;
   */
  BODYPART_UNKNOWN = 0,

  /**
   * @generated from enum value: BODYPART_SHOULDER = 1;
   */
  BODYPART_SHOULDER = 1,

  /**
   * @generated from enum value: BODYPART_ELBOW = 2;
   */
  BODYPART_ELBOW = 2,

  /**
   * @generated from enum value: BODYPART_HAND = 3;
   */
  BODYPART_HAND = 3,

  /**
   * @generated from enum value: BODYPART_HIP = 4;
   */
  BODYPART_HIP = 4,

  /**
   * @generated from enum value: BODYPART_KNEE = 5;
   */
  BODYPART_KNEE = 5,

  /**
   * @generated from enum value: BODYPART_FEET = 6;
   */
  BODYPART_FEET = 6,

  /**
   * @generated from enum value: BODYPART_SPINE = 7;
   */
  BODYPART_SPINE = 7,

  /**
   * @generated from enum value: BODYPART_HEAD = 8;
   */
  BODYPART_HEAD = 8,

  /**
   * @generated from enum value: BODYPART_THORAX = 10;
   */
  BODYPART_THORAX = 10,

  /**
   * @generated from enum value: BODYPART_ABDOMEN = 11;
   */
  BODYPART_ABDOMEN = 11,
}
// Retrieve enum metadata with: proto3.getEnumType(BodyPart)
proto3.util.setEnumType(BodyPart, "augmedi.quiz.BodyPart", [
  { no: 0, name: "BODYPART_UNKNOWN" },
  { no: 1, name: "BODYPART_SHOULDER" },
  { no: 2, name: "BODYPART_ELBOW" },
  { no: 3, name: "BODYPART_HAND" },
  { no: 4, name: "BODYPART_HIP" },
  { no: 5, name: "BODYPART_KNEE" },
  { no: 6, name: "BODYPART_FEET" },
  { no: 7, name: "BODYPART_SPINE" },
  { no: 8, name: "BODYPART_HEAD" },
  { no: 10, name: "BODYPART_THORAX" },
  { no: 11, name: "BODYPART_ABDOMEN" },
]);

/**
 * @generated from enum augmedi.quiz.Permission
 */
export enum Permission {
  /**
   * @generated from enum value: PERMISSION_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * HACK The ListModels permission will let users see some information about
   * models even if they don't have one of the "Read..." permissions. This is
   * necessary because we used the ListModels RPC on non-admin clients (Unity)
   * instead of creating a separate ListFrozenModels RPC, so we can't fully
   * isolate permissions related to models without changing those clients.
   *
   * @generated from enum value: PERMISSION_LIST_MODELS = 1;
   */
  LIST_MODELS = 1,

  /**
   * @generated from enum value: PERMISSION_READ_NON_FROZEN_MODELS = 2;
   */
  READ_NON_FROZEN_MODELS = 2,

  /**
   * @generated from enum value: PERMISSION_READ_FROZEN_MODELS = 3;
   */
  READ_FROZEN_MODELS = 3,

  /**
   * @generated from enum value: PERMISSION_EDIT_MODELS = 4;
   */
  EDIT_MODELS = 4,

  /**
   * @generated from enum value: PERMISSION_READ_MEDIA_ITEMS = 5;
   */
  READ_MEDIA_ITEMS = 5,

  /**
   * @generated from enum value: PERMISSION_EDIT_MEDIA_ITEMS = 6;
   */
  EDIT_MEDIA_ITEMS = 6,

  /**
   * @generated from enum value: PERMISSION_USE_ANATOMY_QUIZ = 7;
   */
  USE_ANATOMY_QUIZ = 7,

  /**
   * @generated from enum value: PERMISSION_EDIT_CURRICULUM = 8;
   */
  EDIT_CURRICULUM = 8,

  /**
   * @generated from enum value: PERMISSION_IMPORT_QUESTIONS = 9;
   */
  IMPORT_QUESTIONS = 9,

  /**
   * @generated from enum value: PERMISSION_USE_FULL_ANATOMY_QUIZ = 18;
   */
  USE_FULL_ANATOMY_QUIZ = 18,

  /**
   * @generated from enum value: PERMISSION_SEARCH_QUIZ_ITEMS = 24;
   */
  SEARCH_QUIZ_ITEMS = 24,

  /**
   * @generated from enum value: PERMISSION_LIST_APPROACHES = 10;
   */
  LIST_APPROACHES = 10,

  /**
   * @generated from enum value: PERMISSION_READ_NON_FROZEN_APPROACHES = 11;
   */
  READ_NON_FROZEN_APPROACHES = 11,

  /**
   * @generated from enum value: PERMISSION_READ_FROZEN_APPROACHES = 12;
   */
  READ_FROZEN_APPROACHES = 12,

  /**
   * @generated from enum value: PERMISSION_EDIT_APPROACHES = 13;
   */
  EDIT_APPROACHES = 13,

  /**
   * @generated from enum value: PERMISSION_FREEZE_APPROACHES = 23;
   */
  FREEZE_APPROACHES = 23,

  /**
   * @generated from enum value: PERMISSION_DELETE_USERS = 14;
   */
  DELETE_USERS = 14,

  /**
   * @generated from enum value: PERMISSION_READ_USERS = 20;
   */
  READ_USERS = 20,

  /**
   * @generated from enum value: PERMISSION_EDIT_USERS = 21;
   */
  EDIT_USERS = 21,

  /**
   * @generated from enum value: PERMISSION_READ_ATLAS_BASIC = 15;
   */
  READ_ATLAS_BASIC = 15,

  /**
   * @generated from enum value: PERMISSION_READ_ATLAS_AUGMEDI_INTERNAL = 16;
   */
  READ_ATLAS_AUGMEDI_INTERNAL = 16,

  /**
   * @generated from enum value: PERMISSION_QR_CODE = 19;
   */
  QR_CODE = 19,

  /**
   * @generated from enum value: PERMISSION_ANNOTATE = 22;
   */
  ANNOTATE = 22,
}
// Retrieve enum metadata with: proto3.getEnumType(Permission)
proto3.util.setEnumType(Permission, "augmedi.quiz.Permission", [
  { no: 0, name: "PERMISSION_UNKNOWN" },
  { no: 1, name: "PERMISSION_LIST_MODELS" },
  { no: 2, name: "PERMISSION_READ_NON_FROZEN_MODELS" },
  { no: 3, name: "PERMISSION_READ_FROZEN_MODELS" },
  { no: 4, name: "PERMISSION_EDIT_MODELS" },
  { no: 5, name: "PERMISSION_READ_MEDIA_ITEMS" },
  { no: 6, name: "PERMISSION_EDIT_MEDIA_ITEMS" },
  { no: 7, name: "PERMISSION_USE_ANATOMY_QUIZ" },
  { no: 8, name: "PERMISSION_EDIT_CURRICULUM" },
  { no: 9, name: "PERMISSION_IMPORT_QUESTIONS" },
  { no: 18, name: "PERMISSION_USE_FULL_ANATOMY_QUIZ" },
  { no: 24, name: "PERMISSION_SEARCH_QUIZ_ITEMS" },
  { no: 10, name: "PERMISSION_LIST_APPROACHES" },
  { no: 11, name: "PERMISSION_READ_NON_FROZEN_APPROACHES" },
  { no: 12, name: "PERMISSION_READ_FROZEN_APPROACHES" },
  { no: 13, name: "PERMISSION_EDIT_APPROACHES" },
  { no: 23, name: "PERMISSION_FREEZE_APPROACHES" },
  { no: 14, name: "PERMISSION_DELETE_USERS" },
  { no: 20, name: "PERMISSION_READ_USERS" },
  { no: 21, name: "PERMISSION_EDIT_USERS" },
  { no: 15, name: "PERMISSION_READ_ATLAS_BASIC" },
  { no: 16, name: "PERMISSION_READ_ATLAS_AUGMEDI_INTERNAL" },
  { no: 19, name: "PERMISSION_QR_CODE" },
  { no: 22, name: "PERMISSION_ANNOTATE" },
]);

/**
 * @generated from enum augmedi.quiz.RecentStatsRange
 */
export enum RecentStatsRange {
  /**
   * @generated from enum value: RECENT_STATS_RANGE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: RECENT_STATS_RANGE_LAST_24_HOURS = 1;
   */
  LAST_24_HOURS = 1,

  /**
   * @generated from enum value: RECENT_STATS_RANGE_LAST_7_DAYS = 2;
   */
  LAST_7_DAYS = 2,

  /**
   * @generated from enum value: RECENT_STATS_RANGE_LAST_30_DAYS = 3;
   */
  LAST_30_DAYS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(RecentStatsRange)
proto3.util.setEnumType(RecentStatsRange, "augmedi.quiz.RecentStatsRange", [
  { no: 0, name: "RECENT_STATS_RANGE_UNKNOWN" },
  { no: 1, name: "RECENT_STATS_RANGE_LAST_24_HOURS" },
  { no: 2, name: "RECENT_STATS_RANGE_LAST_7_DAYS" },
  { no: 3, name: "RECENT_STATS_RANGE_LAST_30_DAYS" },
]);

/**
 * @generated from enum augmedi.quiz.WellKnownQuizId
 */
export enum WellKnownQuizId {
  /**
   * @generated from enum value: WELL_KNOWN_QUIZ_ID_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: WELL_KNOWN_QUIZ_ID_AR_TUTORIAL = 1;
   */
  AR_TUTORIAL = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(WellKnownQuizId)
proto3.util.setEnumType(WellKnownQuizId, "augmedi.quiz.WellKnownQuizId", [
  { no: 0, name: "WELL_KNOWN_QUIZ_ID_UNKNOWN" },
  { no: 1, name: "WELL_KNOWN_QUIZ_ID_AR_TUTORIAL" },
]);

/**
 * @generated from message augmedi.quiz.Model
 */
export class Model extends Message<Model> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated string partition_ids = 4;
   */
  partitionIds: string[] = [];

  /**
   * @generated from field: string latest_frozen_model_id = 3;
   */
  latestFrozenModelId = "";

  constructor(data?: PartialMessage<Model>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.Model";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "partition_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "latest_frozen_model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Model {
    return new Model().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Model {
    return new Model().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Model {
    return new Model().fromJsonString(jsonString, options);
  }

  static equals(a: Model | PlainMessage<Model> | undefined, b: Model | PlainMessage<Model> | undefined): boolean {
    return proto3.util.equals(Model, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.MaskImage
 */
export class MaskImage extends Message<MaskImage> {
  /**
   * @generated from field: uint32 width = 1;
   */
  width = 0;

  /**
   * @generated from field: uint32 height = 2;
   */
  height = 0;

  /**
   * @generated from field: bytes compressed_data = 3;
   */
  compressedData = new Uint8Array(0);

  constructor(data?: PartialMessage<MaskImage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.MaskImage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "width", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "height", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "compressed_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MaskImage {
    return new MaskImage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MaskImage {
    return new MaskImage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MaskImage {
    return new MaskImage().fromJsonString(jsonString, options);
  }

  static equals(a: MaskImage | PlainMessage<MaskImage> | undefined, b: MaskImage | PlainMessage<MaskImage> | undefined): boolean {
    return proto3.util.equals(MaskImage, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FrozenModel
 */
export class FrozenModel extends Message<FrozenModel> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string model_id = 2;
   */
  modelId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: augmedi.quiz.FrozenModel.Manifest manifest = 4;
   */
  manifest?: FrozenModel_Manifest;

  constructor(data?: PartialMessage<FrozenModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FrozenModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "manifest", kind: "message", T: FrozenModel_Manifest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FrozenModel {
    return new FrozenModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FrozenModel {
    return new FrozenModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FrozenModel {
    return new FrozenModel().fromJsonString(jsonString, options);
  }

  static equals(a: FrozenModel | PlainMessage<FrozenModel> | undefined, b: FrozenModel | PlainMessage<FrozenModel> | undefined): boolean {
    return proto3.util.equals(FrozenModel, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FrozenModel.Label
 */
export class FrozenModel_Label extends Message<FrozenModel_Label> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string mesh_id = 6;
   */
  meshId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string structure_id = 8;
   */
  structureId = "";

  /**
   * @generated from field: bool is_whole_mesh_label = 7;
   */
  isWholeMeshLabel = false;

  constructor(data?: PartialMessage<FrozenModel_Label>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FrozenModel.Label";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_whole_mesh_label", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FrozenModel_Label {
    return new FrozenModel_Label().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FrozenModel_Label {
    return new FrozenModel_Label().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FrozenModel_Label {
    return new FrozenModel_Label().fromJsonString(jsonString, options);
  }

  static equals(a: FrozenModel_Label | PlainMessage<FrozenModel_Label> | undefined, b: FrozenModel_Label | PlainMessage<FrozenModel_Label> | undefined): boolean {
    return proto3.util.equals(FrozenModel_Label, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FrozenModel.Mesh
 */
export class FrozenModel_Mesh extends Message<FrozenModel_Mesh> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string partition_id = 2;
   */
  partitionId = "";

  /**
   * @generated from field: string gltf_mesh_name = 5;
   */
  gltfMeshName = "";

  /**
   * @generated from field: string whole_mesh_label_id = 6;
   */
  wholeMeshLabelId = "";

  constructor(data?: PartialMessage<FrozenModel_Mesh>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FrozenModel.Mesh";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "partition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "gltf_mesh_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "whole_mesh_label_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FrozenModel_Mesh {
    return new FrozenModel_Mesh().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FrozenModel_Mesh {
    return new FrozenModel_Mesh().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FrozenModel_Mesh {
    return new FrozenModel_Mesh().fromJsonString(jsonString, options);
  }

  static equals(a: FrozenModel_Mesh | PlainMessage<FrozenModel_Mesh> | undefined, b: FrozenModel_Mesh | PlainMessage<FrozenModel_Mesh> | undefined): boolean {
    return proto3.util.equals(FrozenModel_Mesh, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FrozenModel.Manifest
 */
export class FrozenModel_Manifest extends Message<FrozenModel_Manifest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated string partition_ids = 4;
   */
  partitionIds: string[] = [];

  /**
   * @generated from field: repeated augmedi.quiz.FrozenModel.Label labels = 2;
   */
  labels: FrozenModel_Label[] = [];

  /**
   * @generated from field: repeated augmedi.quiz.FrozenModel.Mesh meshes = 3;
   */
  meshes: FrozenModel_Mesh[] = [];

  constructor(data?: PartialMessage<FrozenModel_Manifest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FrozenModel.Manifest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "partition_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "labels", kind: "message", T: FrozenModel_Label, repeated: true },
    { no: 3, name: "meshes", kind: "message", T: FrozenModel_Mesh, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FrozenModel_Manifest {
    return new FrozenModel_Manifest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FrozenModel_Manifest {
    return new FrozenModel_Manifest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FrozenModel_Manifest {
    return new FrozenModel_Manifest().fromJsonString(jsonString, options);
  }

  static equals(a: FrozenModel_Manifest | PlainMessage<FrozenModel_Manifest> | undefined, b: FrozenModel_Manifest | PlainMessage<FrozenModel_Manifest> | undefined): boolean {
    return proto3.util.equals(FrozenModel_Manifest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ModelLabel
 */
export class ModelLabel extends Message<ModelLabel> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string model_id = 2;
   */
  modelId = "";

  /**
   * @generated from field: string mesh_id = 7;
   */
  meshId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * not set for whole mesh labels
   *
   * @generated from field: augmedi.quiz.MaskImage mask = 4;
   */
  mask?: MaskImage;

  /**
   * optional
   *
   * @generated from field: string structure_id = 8;
   */
  structureId = "";

  /**
   * @generated from field: bool is_whole_mesh_label = 6;
   */
  isWholeMeshLabel = false;

  constructor(data?: PartialMessage<ModelLabel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ModelLabel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "mask", kind: "message", T: MaskImage },
    { no: 8, name: "structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_whole_mesh_label", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModelLabel {
    return new ModelLabel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModelLabel {
    return new ModelLabel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModelLabel {
    return new ModelLabel().fromJsonString(jsonString, options);
  }

  static equals(a: ModelLabel | PlainMessage<ModelLabel> | undefined, b: ModelLabel | PlainMessage<ModelLabel> | undefined): boolean {
    return proto3.util.equals(ModelLabel, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ModelMesh
 */
export class ModelMesh extends Message<ModelMesh> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string model_id = 2;
   */
  modelId = "";

  /**
   * @generated from field: string partition_id = 5;
   */
  partitionId = "";

  /**
   * unique within the gltf for the relevant partition
   *
   * @generated from field: string gltf_mesh_name = 6;
   */
  gltfMeshName = "";

  /**
   * @generated from field: string whole_mesh_label_id = 7;
   */
  wholeMeshLabelId = "";

  constructor(data?: PartialMessage<ModelMesh>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ModelMesh";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "partition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "gltf_mesh_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "whole_mesh_label_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModelMesh {
    return new ModelMesh().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModelMesh {
    return new ModelMesh().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModelMesh {
    return new ModelMesh().fromJsonString(jsonString, options);
  }

  static equals(a: ModelMesh | PlainMessage<ModelMesh> | undefined, b: ModelMesh | PlainMessage<ModelMesh> | undefined): boolean {
    return proto3.util.equals(ModelMesh, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.MediaItem
 */
export class MediaItem extends Message<MediaItem> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<MediaItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.MediaItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaItem {
    return new MediaItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaItem {
    return new MediaItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaItem {
    return new MediaItem().fromJsonString(jsonString, options);
  }

  static equals(a: MediaItem | PlainMessage<MediaItem> | undefined, b: MediaItem | PlainMessage<MediaItem> | undefined): boolean {
    return proto3.util.equals(MediaItem, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.MediaItemWithPath
 */
export class MediaItemWithPath extends Message<MediaItemWithPath> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string path = 2;
   */
  path = "";

  constructor(data?: PartialMessage<MediaItemWithPath>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.MediaItemWithPath";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaItemWithPath {
    return new MediaItemWithPath().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaItemWithPath {
    return new MediaItemWithPath().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaItemWithPath {
    return new MediaItemWithPath().fromJsonString(jsonString, options);
  }

  static equals(a: MediaItemWithPath | PlainMessage<MediaItemWithPath> | undefined, b: MediaItemWithPath | PlainMessage<MediaItemWithPath> | undefined): boolean {
    return proto3.util.equals(MediaItemWithPath, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ApproachStepInfoSection
 */
export class ApproachStepInfoSection extends Message<ApproachStepInfoSection> {
  /**
   * This ID is managed by the client. It must be a UUID. It must be unique
   * within an ApproachStep. It will be generated by the server if not supplied.
   *
   * @generated from field: string id = 3;
   */
  id = "";

  /**
   * @generated from oneof augmedi.quiz.ApproachStepInfoSection.content
   */
  content: {
    /**
     * @generated from field: string text = 1;
     */
    value: string;
    case: "text";
  } | {
    /**
     * @generated from field: string media_item_id = 2;
     */
    value: string;
    case: "mediaItemId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ApproachStepInfoSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ApproachStepInfoSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "content" },
    { no: 2, name: "media_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "content" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApproachStepInfoSection {
    return new ApproachStepInfoSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApproachStepInfoSection {
    return new ApproachStepInfoSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApproachStepInfoSection {
    return new ApproachStepInfoSection().fromJsonString(jsonString, options);
  }

  static equals(a: ApproachStepInfoSection | PlainMessage<ApproachStepInfoSection> | undefined, b: ApproachStepInfoSection | PlainMessage<ApproachStepInfoSection> | undefined): boolean {
    return proto3.util.equals(ApproachStepInfoSection, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ApproachStep
 */
export class ApproachStep extends Message<ApproachStep> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string model_id = 3;
   */
  modelId = "";

  /**
   * @generated from field: repeated augmedi.quiz.ApproachStepInfoSection info_sections = 4;
   */
  infoSections: ApproachStepInfoSection[] = [];

  constructor(data?: PartialMessage<ApproachStep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ApproachStep";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "info_sections", kind: "message", T: ApproachStepInfoSection, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApproachStep {
    return new ApproachStep().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApproachStep {
    return new ApproachStep().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApproachStep {
    return new ApproachStep().fromJsonString(jsonString, options);
  }

  static equals(a: ApproachStep | PlainMessage<ApproachStep> | undefined, b: ApproachStep | PlainMessage<ApproachStep> | undefined): boolean {
    return proto3.util.equals(ApproachStep, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.Approach
 */
export class Approach extends Message<Approach> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated augmedi.quiz.ApproachStep steps = 3;
   */
  steps: ApproachStep[] = [];

  /**
   * @generated from field: string latest_frozen_approach_id = 4;
   */
  latestFrozenApproachId = "";

  constructor(data?: PartialMessage<Approach>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.Approach";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "steps", kind: "message", T: ApproachStep, repeated: true },
    { no: 4, name: "latest_frozen_approach_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Approach {
    return new Approach().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Approach {
    return new Approach().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Approach {
    return new Approach().fromJsonString(jsonString, options);
  }

  static equals(a: Approach | PlainMessage<Approach> | undefined, b: Approach | PlainMessage<Approach> | undefined): boolean {
    return proto3.util.equals(Approach, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FrozenApproach
 */
export class FrozenApproach extends Message<FrozenApproach> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string approach_id = 2;
   */
  approachId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: augmedi.quiz.FrozenApproach.Manifest manifest = 4;
   */
  manifest?: FrozenApproach_Manifest;

  constructor(data?: PartialMessage<FrozenApproach>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FrozenApproach";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "approach_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "manifest", kind: "message", T: FrozenApproach_Manifest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FrozenApproach {
    return new FrozenApproach().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FrozenApproach {
    return new FrozenApproach().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FrozenApproach {
    return new FrozenApproach().fromJsonString(jsonString, options);
  }

  static equals(a: FrozenApproach | PlainMessage<FrozenApproach> | undefined, b: FrozenApproach | PlainMessage<FrozenApproach> | undefined): boolean {
    return proto3.util.equals(FrozenApproach, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FrozenApproach.Step
 */
export class FrozenApproach_Step extends Message<FrozenApproach_Step> {
  /**
   * not the same as the ID of the non-frozen ApproachStep
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string frozen_model_id = 3;
   */
  frozenModelId = "";

  /**
   * @generated from field: repeated augmedi.quiz.ApproachStepInfoSection info_sections = 4;
   */
  infoSections: ApproachStepInfoSection[] = [];

  constructor(data?: PartialMessage<FrozenApproach_Step>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FrozenApproach.Step";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "frozen_model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "info_sections", kind: "message", T: ApproachStepInfoSection, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FrozenApproach_Step {
    return new FrozenApproach_Step().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FrozenApproach_Step {
    return new FrozenApproach_Step().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FrozenApproach_Step {
    return new FrozenApproach_Step().fromJsonString(jsonString, options);
  }

  static equals(a: FrozenApproach_Step | PlainMessage<FrozenApproach_Step> | undefined, b: FrozenApproach_Step | PlainMessage<FrozenApproach_Step> | undefined): boolean {
    return proto3.util.equals(FrozenApproach_Step, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FrozenApproach.Manifest
 */
export class FrozenApproach_Manifest extends Message<FrozenApproach_Manifest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated augmedi.quiz.FrozenApproach.Step steps = 2;
   */
  steps: FrozenApproach_Step[] = [];

  constructor(data?: PartialMessage<FrozenApproach_Manifest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FrozenApproach.Manifest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "steps", kind: "message", T: FrozenApproach_Step, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FrozenApproach_Manifest {
    return new FrozenApproach_Manifest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FrozenApproach_Manifest {
    return new FrozenApproach_Manifest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FrozenApproach_Manifest {
    return new FrozenApproach_Manifest().fromJsonString(jsonString, options);
  }

  static equals(a: FrozenApproach_Manifest | PlainMessage<FrozenApproach_Manifest> | undefined, b: FrozenApproach_Manifest | PlainMessage<FrozenApproach_Manifest> | undefined): boolean {
    return proto3.util.equals(FrozenApproach_Manifest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.PickLocationAnswerRequest
 */
export class PickLocationAnswerRequest extends Message<PickLocationAnswerRequest> {
  /**
   * @generated from field: repeated string desired_label_ids = 1;
   */
  desiredLabelIds: string[] = [];

  constructor(data?: PartialMessage<PickLocationAnswerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.PickLocationAnswerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "desired_label_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PickLocationAnswerRequest {
    return new PickLocationAnswerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PickLocationAnswerRequest {
    return new PickLocationAnswerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PickLocationAnswerRequest {
    return new PickLocationAnswerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PickLocationAnswerRequest | PlainMessage<PickLocationAnswerRequest> | undefined, b: PickLocationAnswerRequest | PlainMessage<PickLocationAnswerRequest> | undefined): boolean {
    return proto3.util.equals(PickLocationAnswerRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.MultipleChoiceAnswerRequest
 */
export class MultipleChoiceAnswerRequest extends Message<MultipleChoiceAnswerRequest> {
  /**
   * @generated from field: repeated augmedi.quiz.MultipleChoiceAnswerRequest.Item options = 1;
   */
  options: MultipleChoiceAnswerRequest_Item[] = [];

  constructor(data?: PartialMessage<MultipleChoiceAnswerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.MultipleChoiceAnswerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "options", kind: "message", T: MultipleChoiceAnswerRequest_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MultipleChoiceAnswerRequest {
    return new MultipleChoiceAnswerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MultipleChoiceAnswerRequest {
    return new MultipleChoiceAnswerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MultipleChoiceAnswerRequest {
    return new MultipleChoiceAnswerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MultipleChoiceAnswerRequest | PlainMessage<MultipleChoiceAnswerRequest> | undefined, b: MultipleChoiceAnswerRequest | PlainMessage<MultipleChoiceAnswerRequest> | undefined): boolean {
    return proto3.util.equals(MultipleChoiceAnswerRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.MultipleChoiceAnswerRequest.Item
 */
export class MultipleChoiceAnswerRequest_Item extends Message<MultipleChoiceAnswerRequest_Item> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * @generated from field: bool correct = 3;
   */
  correct = false;

  constructor(data?: PartialMessage<MultipleChoiceAnswerRequest_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.MultipleChoiceAnswerRequest.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "correct", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MultipleChoiceAnswerRequest_Item {
    return new MultipleChoiceAnswerRequest_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MultipleChoiceAnswerRequest_Item {
    return new MultipleChoiceAnswerRequest_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MultipleChoiceAnswerRequest_Item {
    return new MultipleChoiceAnswerRequest_Item().fromJsonString(jsonString, options);
  }

  static equals(a: MultipleChoiceAnswerRequest_Item | PlainMessage<MultipleChoiceAnswerRequest_Item> | undefined, b: MultipleChoiceAnswerRequest_Item | PlainMessage<MultipleChoiceAnswerRequest_Item> | undefined): boolean {
    return proto3.util.equals(MultipleChoiceAnswerRequest_Item, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AnswerRequest
 */
export class AnswerRequest extends Message<AnswerRequest> {
  /**
   * @generated from oneof augmedi.quiz.AnswerRequest.content
   */
  content: {
    /**
     * @generated from field: augmedi.quiz.PickLocationAnswerRequest pick_location = 1;
     */
    value: PickLocationAnswerRequest;
    case: "pickLocation";
  } | {
    /**
     * @generated from field: augmedi.quiz.MultipleChoiceAnswerRequest multiple_choice = 2;
     */
    value: MultipleChoiceAnswerRequest;
    case: "multipleChoice";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<AnswerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AnswerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pick_location", kind: "message", T: PickLocationAnswerRequest, oneof: "content" },
    { no: 2, name: "multiple_choice", kind: "message", T: MultipleChoiceAnswerRequest, oneof: "content" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnswerRequest {
    return new AnswerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnswerRequest {
    return new AnswerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnswerRequest {
    return new AnswerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AnswerRequest | PlainMessage<AnswerRequest> | undefined, b: AnswerRequest | PlainMessage<AnswerRequest> | undefined): boolean {
    return proto3.util.equals(AnswerRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.CameraPosition
 */
export class CameraPosition extends Message<CameraPosition> {
  /**
   * @generated from field: float x = 1;
   */
  x = 0;

  /**
   * @generated from field: float y = 2;
   */
  y = 0;

  /**
   * @generated from field: float z = 3;
   */
  z = 0;

  constructor(data?: PartialMessage<CameraPosition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.CameraPosition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "x", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "y", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "z", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CameraPosition {
    return new CameraPosition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CameraPosition {
    return new CameraPosition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CameraPosition {
    return new CameraPosition().fromJsonString(jsonString, options);
  }

  static equals(a: CameraPosition | PlainMessage<CameraPosition> | undefined, b: CameraPosition | PlainMessage<CameraPosition> | undefined): boolean {
    return proto3.util.equals(CameraPosition, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ConcreteQuestion
 */
export class ConcreteQuestion extends Message<ConcreteQuestion> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * optional
   *
   * @generated from field: string frozen_model_id = 3;
   */
  frozenModelId = "";

  /**
   * @generated from field: repeated string visible_mesh_ids = 6;
   */
  visibleMeshIds: string[] = [];

  /**
   * @generated from field: repeated string highlighted_label_ids = 4;
   */
  highlightedLabelIds: string[] = [];

  /**
   * @generated from field: augmedi.quiz.CameraPosition initial_camera_position = 7;
   */
  initialCameraPosition?: CameraPosition;

  /**
   * @generated from field: augmedi.quiz.AnswerRequest answer_request = 5;
   */
  answerRequest?: AnswerRequest;

  constructor(data?: PartialMessage<ConcreteQuestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ConcreteQuestion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "frozen_model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "visible_mesh_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "highlighted_label_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "initial_camera_position", kind: "message", T: CameraPosition },
    { no: 5, name: "answer_request", kind: "message", T: AnswerRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConcreteQuestion {
    return new ConcreteQuestion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConcreteQuestion {
    return new ConcreteQuestion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConcreteQuestion {
    return new ConcreteQuestion().fromJsonString(jsonString, options);
  }

  static equals(a: ConcreteQuestion | PlainMessage<ConcreteQuestion> | undefined, b: ConcreteQuestion | PlainMessage<ConcreteQuestion> | undefined): boolean {
    return proto3.util.equals(ConcreteQuestion, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.IntroductionGroup
 */
export class IntroductionGroup extends Message<IntroductionGroup> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * @generated from field: string frozen_model_id = 4;
   */
  frozenModelId = "";

  /**
   * @generated from field: repeated augmedi.quiz.IntroductionGroup.Item items = 3;
   */
  items: IntroductionGroup_Item[] = [];

  /**
   * @generated from field: repeated string ambient_highlighted_label_ids = 5;
   */
  ambientHighlightedLabelIds: string[] = [];

  /**
   * @generated from field: repeated string visible_mesh_ids = 6;
   */
  visibleMeshIds: string[] = [];

  /**
   * @generated from field: augmedi.quiz.CameraPosition initial_camera_position = 7;
   */
  initialCameraPosition?: CameraPosition;

  /**
   * @generated from field: bool is_trial = 8;
   */
  isTrial = false;

  /**
   * @generated from field: augmedi.quiz.BodyPart body_part = 9;
   */
  bodyPart = BodyPart.BODYPART_UNKNOWN;

  constructor(data?: PartialMessage<IntroductionGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.IntroductionGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "frozen_model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "items", kind: "message", T: IntroductionGroup_Item, repeated: true },
    { no: 5, name: "ambient_highlighted_label_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "visible_mesh_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "initial_camera_position", kind: "message", T: CameraPosition },
    { no: 8, name: "is_trial", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "body_part", kind: "enum", T: proto3.getEnumType(BodyPart) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IntroductionGroup {
    return new IntroductionGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IntroductionGroup {
    return new IntroductionGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IntroductionGroup {
    return new IntroductionGroup().fromJsonString(jsonString, options);
  }

  static equals(a: IntroductionGroup | PlainMessage<IntroductionGroup> | undefined, b: IntroductionGroup | PlainMessage<IntroductionGroup> | undefined): boolean {
    return proto3.util.equals(IntroductionGroup, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.IntroductionGroup.Item
 */
export class IntroductionGroup_Item extends Message<IntroductionGroup_Item> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * @generated from field: string legacy_description = 5;
   */
  legacyDescription = "";

  /**
   * @generated from field: repeated string highlighted_label_ids = 4;
   */
  highlightedLabelIds: string[] = [];

  /**
   * @generated from field: repeated string secondary_label_ids = 7;
   */
  secondaryLabelIds: string[] = [];

  /**
   * this item is not very important, so don't force the user to click on it
   *
   * @generated from field: bool preselected = 6;
   */
  preselected = false;

  constructor(data?: PartialMessage<IntroductionGroup_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.IntroductionGroup.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "legacy_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "highlighted_label_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "secondary_label_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "preselected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IntroductionGroup_Item {
    return new IntroductionGroup_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IntroductionGroup_Item {
    return new IntroductionGroup_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IntroductionGroup_Item {
    return new IntroductionGroup_Item().fromJsonString(jsonString, options);
  }

  static equals(a: IntroductionGroup_Item | PlainMessage<IntroductionGroup_Item> | undefined, b: IntroductionGroup_Item | PlainMessage<IntroductionGroup_Item> | undefined): boolean {
    return proto3.util.equals(IntroductionGroup_Item, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.QuestionRetryPlan
 */
export class QuestionRetryPlan extends Message<QuestionRetryPlan> {
  /**
   * at least 1
   *
   * @generated from field: int32 max_attempts = 1;
   */
  maxAttempts = 0;

  constructor(data?: PartialMessage<QuestionRetryPlan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.QuestionRetryPlan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "max_attempts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionRetryPlan {
    return new QuestionRetryPlan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionRetryPlan {
    return new QuestionRetryPlan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionRetryPlan {
    return new QuestionRetryPlan().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionRetryPlan | PlainMessage<QuestionRetryPlan> | undefined, b: QuestionRetryPlan | PlainMessage<QuestionRetryPlan> | undefined): boolean {
    return proto3.util.equals(QuestionRetryPlan, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.QuizItem
 */
export class QuizItem extends Message<QuizItem> {
  /**
   * Not necessarily the same as the ID of the Question or IntroductionGroup. Stable across quiz requests. Unique for all quiz items in a response. Not guaranteed to be a UUID.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from oneof augmedi.quiz.QuizItem.content
   */
  content: {
    /**
     * @generated from field: augmedi.quiz.QuizItem.QuestionContent question = 2;
     */
    value: QuizItem_QuestionContent;
    case: "question";
  } | {
    /**
     * @generated from field: augmedi.quiz.QuizItem.IntroductionGroupContent introduction_group = 3;
     */
    value: QuizItem_IntroductionGroupContent;
    case: "introductionGroup";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<QuizItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.QuizItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question", kind: "message", T: QuizItem_QuestionContent, oneof: "content" },
    { no: 3, name: "introduction_group", kind: "message", T: QuizItem_IntroductionGroupContent, oneof: "content" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuizItem {
    return new QuizItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuizItem {
    return new QuizItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuizItem {
    return new QuizItem().fromJsonString(jsonString, options);
  }

  static equals(a: QuizItem | PlainMessage<QuizItem> | undefined, b: QuizItem | PlainMessage<QuizItem> | undefined): boolean {
    return proto3.util.equals(QuizItem, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.QuizItem.QuestionContent
 */
export class QuizItem_QuestionContent extends Message<QuizItem_QuestionContent> {
  /**
   * @generated from field: augmedi.quiz.ConcreteQuestion question = 1;
   */
  question?: ConcreteQuestion;

  /**
   * @generated from field: augmedi.quiz.QuestionRetryPlan retry_plan = 2;
   */
  retryPlan?: QuestionRetryPlan;

  constructor(data?: PartialMessage<QuizItem_QuestionContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.QuizItem.QuestionContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question", kind: "message", T: ConcreteQuestion },
    { no: 2, name: "retry_plan", kind: "message", T: QuestionRetryPlan },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuizItem_QuestionContent {
    return new QuizItem_QuestionContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuizItem_QuestionContent {
    return new QuizItem_QuestionContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuizItem_QuestionContent {
    return new QuizItem_QuestionContent().fromJsonString(jsonString, options);
  }

  static equals(a: QuizItem_QuestionContent | PlainMessage<QuizItem_QuestionContent> | undefined, b: QuizItem_QuestionContent | PlainMessage<QuizItem_QuestionContent> | undefined): boolean {
    return proto3.util.equals(QuizItem_QuestionContent, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.QuizItem.IntroductionGroupContent
 */
export class QuizItem_IntroductionGroupContent extends Message<QuizItem_IntroductionGroupContent> {
  /**
   * @generated from field: augmedi.quiz.IntroductionGroup introduction_group = 1;
   */
  introductionGroup?: IntroductionGroup;

  constructor(data?: PartialMessage<QuizItem_IntroductionGroupContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.QuizItem.IntroductionGroupContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "introduction_group", kind: "message", T: IntroductionGroup },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuizItem_IntroductionGroupContent {
    return new QuizItem_IntroductionGroupContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuizItem_IntroductionGroupContent {
    return new QuizItem_IntroductionGroupContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuizItem_IntroductionGroupContent {
    return new QuizItem_IntroductionGroupContent().fromJsonString(jsonString, options);
  }

  static equals(a: QuizItem_IntroductionGroupContent | PlainMessage<QuizItem_IntroductionGroupContent> | undefined, b: QuizItem_IntroductionGroupContent | PlainMessage<QuizItem_IntroductionGroupContent> | undefined): boolean {
    return proto3.util.equals(QuizItem_IntroductionGroupContent, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ConsumedQuizItem
 */
export class ConsumedQuizItem extends Message<ConsumedQuizItem> {
  /**
   * A unique client-generated UUID. Used to prevent double-counting. Not the same as the ID of the QuizItem.
   *
   * @generated from field: string id = 4;
   */
  id = "";

  /**
   * @generated from field: augmedi.quiz.QuizItem quiz_item = 1;
   */
  quizItem?: QuizItem;

  /**
   * For PickLocationAnswerRequest a single ID corresponding to desired_label_ids. For MultipleChoiceAnswerRequest multiple IDs corresponding MultipleChoiceAnswerRequest.Item.id.
   *
   * @generated from field: repeated string answer_ids = 5;
   */
  answerIds: string[] = [];

  /**
   * Whether the client's calculation of correctness said that this answer is correct. This can be used by the server to detect issues with clients. The server may ignore this field.
   *
   * @generated from field: bool expect_correct = 6;
   */
  expectCorrect = false;

  /**
   * [1, max_attempts] for questions, unused for introduction groups
   *
   * @generated from field: int32 attempt_index = 7;
   */
  attemptIndex = 0;

  /**
   * @generated from field: google.protobuf.Timestamp consumed_at = 2;
   */
  consumedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Duration time_taken = 3;
   */
  timeTaken?: Duration;

  constructor(data?: PartialMessage<ConsumedQuizItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ConsumedQuizItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "quiz_item", kind: "message", T: QuizItem },
    { no: 5, name: "answer_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "expect_correct", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "attempt_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "consumed_at", kind: "message", T: Timestamp },
    { no: 3, name: "time_taken", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConsumedQuizItem {
    return new ConsumedQuizItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConsumedQuizItem {
    return new ConsumedQuizItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConsumedQuizItem {
    return new ConsumedQuizItem().fromJsonString(jsonString, options);
  }

  static equals(a: ConsumedQuizItem | PlainMessage<ConsumedQuizItem> | undefined, b: ConsumedQuizItem | PlainMessage<ConsumedQuizItem> | undefined): boolean {
    return proto3.util.equals(ConsumedQuizItem, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.KnowledgeBit
 */
export class KnowledgeBit extends Message<KnowledgeBit> {
  /**
   * @generated from field: string subject = 1;
   */
  subject = "";

  /**
   * @generated from field: string relation = 2;
   */
  relation = "";

  constructor(data?: PartialMessage<KnowledgeBit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.KnowledgeBit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "relation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeBit {
    return new KnowledgeBit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeBit {
    return new KnowledgeBit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeBit {
    return new KnowledgeBit().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeBit | PlainMessage<KnowledgeBit> | undefined, b: KnowledgeBit | PlainMessage<KnowledgeBit> | undefined): boolean {
    return proto3.util.equals(KnowledgeBit, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.Chapter
 */
export class Chapter extends Message<Chapter> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated augmedi.quiz.Chapter.IntroductionGroupQuery introduction_group_queries = 3;
   */
  introductionGroupQueries: Chapter_IntroductionGroupQuery[] = [];

  /**
   * @generated from field: bool notReady = 4;
   */
  notReady = false;

  constructor(data?: PartialMessage<Chapter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.Chapter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "introduction_group_queries", kind: "message", T: Chapter_IntroductionGroupQuery, repeated: true },
    { no: 4, name: "notReady", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Chapter {
    return new Chapter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Chapter {
    return new Chapter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Chapter {
    return new Chapter().fromJsonString(jsonString, options);
  }

  static equals(a: Chapter | PlainMessage<Chapter> | undefined, b: Chapter | PlainMessage<Chapter> | undefined): boolean {
    return proto3.util.equals(Chapter, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.Chapter.IntroductionGroupQuery
 */
export class Chapter_IntroductionGroupQuery extends Message<Chapter_IntroductionGroupQuery> {
  /**
   * @generated from field: string generator_id = 1;
   */
  generatorId = "";

  /**
   * @generated from field: string tag = 2;
   */
  tag = "";

  constructor(data?: PartialMessage<Chapter_IntroductionGroupQuery>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.Chapter.IntroductionGroupQuery";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "generator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Chapter_IntroductionGroupQuery {
    return new Chapter_IntroductionGroupQuery().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Chapter_IntroductionGroupQuery {
    return new Chapter_IntroductionGroupQuery().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Chapter_IntroductionGroupQuery {
    return new Chapter_IntroductionGroupQuery().fromJsonString(jsonString, options);
  }

  static equals(a: Chapter_IntroductionGroupQuery | PlainMessage<Chapter_IntroductionGroupQuery> | undefined, b: Chapter_IntroductionGroupQuery | PlainMessage<Chapter_IntroductionGroupQuery> | undefined): boolean {
    return proto3.util.equals(Chapter_IntroductionGroupQuery, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.Curriculum
 */
export class Curriculum extends Message<Curriculum> {
  /**
   * @generated from field: repeated augmedi.quiz.Chapter chapters = 1;
   */
  chapters: Chapter[] = [];

  constructor(data?: PartialMessage<Curriculum>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.Curriculum";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapters", kind: "message", T: Chapter, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Curriculum {
    return new Curriculum().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Curriculum {
    return new Curriculum().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Curriculum {
    return new Curriculum().fromJsonString(jsonString, options);
  }

  static equals(a: Curriculum | PlainMessage<Curriculum> | undefined, b: Curriculum | PlainMessage<Curriculum> | undefined): boolean {
    return proto3.util.equals(Curriculum, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FrozenChapter
 */
export class FrozenChapter extends Message<FrozenChapter> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: bool notReady = 3;
   */
  notReady = false;

  constructor(data?: PartialMessage<FrozenChapter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FrozenChapter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "notReady", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FrozenChapter {
    return new FrozenChapter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FrozenChapter {
    return new FrozenChapter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FrozenChapter {
    return new FrozenChapter().fromJsonString(jsonString, options);
  }

  static equals(a: FrozenChapter | PlainMessage<FrozenChapter> | undefined, b: FrozenChapter | PlainMessage<FrozenChapter> | undefined): boolean {
    return proto3.util.equals(FrozenChapter, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FrozenCurriculum
 */
export class FrozenCurriculum extends Message<FrozenCurriculum> {
  /**
   * @generated from field: repeated augmedi.quiz.FrozenChapter chapters = 1;
   */
  chapters: FrozenChapter[] = [];

  constructor(data?: PartialMessage<FrozenCurriculum>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FrozenCurriculum";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapters", kind: "message", T: FrozenChapter, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FrozenCurriculum {
    return new FrozenCurriculum().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FrozenCurriculum {
    return new FrozenCurriculum().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FrozenCurriculum {
    return new FrozenCurriculum().fromJsonString(jsonString, options);
  }

  static equals(a: FrozenCurriculum | PlainMessage<FrozenCurriculum> | undefined, b: FrozenCurriculum | PlainMessage<FrozenCurriculum> | undefined): boolean {
    return proto3.util.equals(FrozenCurriculum, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GeneratorWithStatus
 */
export class GeneratorWithStatus extends Message<GeneratorWithStatus> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: bool generated_since_last_curriculum_freeze = 3;
   */
  generatedSinceLastCurriculumFreeze = false;

  /**
   * @generated from field: augmedi.quiz.GeneratorWithStatus.GeneratedCounts counts_for_next_curriculum_freeze = 4;
   */
  countsForNextCurriculumFreeze?: GeneratorWithStatus_GeneratedCounts;

  constructor(data?: PartialMessage<GeneratorWithStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GeneratorWithStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "generated_since_last_curriculum_freeze", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "counts_for_next_curriculum_freeze", kind: "message", T: GeneratorWithStatus_GeneratedCounts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeneratorWithStatus {
    return new GeneratorWithStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeneratorWithStatus {
    return new GeneratorWithStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeneratorWithStatus {
    return new GeneratorWithStatus().fromJsonString(jsonString, options);
  }

  static equals(a: GeneratorWithStatus | PlainMessage<GeneratorWithStatus> | undefined, b: GeneratorWithStatus | PlainMessage<GeneratorWithStatus> | undefined): boolean {
    return proto3.util.equals(GeneratorWithStatus, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GeneratorWithStatus.GeneratedCounts
 */
export class GeneratorWithStatus_GeneratedCounts extends Message<GeneratorWithStatus_GeneratedCounts> {
  /**
   * @generated from field: int32 introduction_groups = 1;
   */
  introductionGroups = 0;

  /**
   * @generated from field: int32 concrete_questions = 2;
   */
  concreteQuestions = 0;

  constructor(data?: PartialMessage<GeneratorWithStatus_GeneratedCounts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GeneratorWithStatus.GeneratedCounts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "introduction_groups", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "concrete_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeneratorWithStatus_GeneratedCounts {
    return new GeneratorWithStatus_GeneratedCounts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeneratorWithStatus_GeneratedCounts {
    return new GeneratorWithStatus_GeneratedCounts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeneratorWithStatus_GeneratedCounts {
    return new GeneratorWithStatus_GeneratedCounts().fromJsonString(jsonString, options);
  }

  static equals(a: GeneratorWithStatus_GeneratedCounts | PlainMessage<GeneratorWithStatus_GeneratedCounts> | undefined, b: GeneratorWithStatus_GeneratedCounts | PlainMessage<GeneratorWithStatus_GeneratedCounts> | undefined): boolean {
    return proto3.util.equals(GeneratorWithStatus_GeneratedCounts, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AuthRequest
 */
export class AuthRequest extends Message<AuthRequest> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  /**
   * @generated from field: bool return_token = 3;
   */
  returnToken = false;

  constructor(data?: PartialMessage<AuthRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AuthRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "return_token", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthRequest {
    return new AuthRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthRequest {
    return new AuthRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthRequest {
    return new AuthRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AuthRequest | PlainMessage<AuthRequest> | undefined, b: AuthRequest | PlainMessage<AuthRequest> | undefined): boolean {
    return proto3.util.equals(AuthRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AuthResponse
 */
export class AuthResponse extends Message<AuthResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<AuthResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AuthResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthResponse {
    return new AuthResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthResponse {
    return new AuthResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthResponse {
    return new AuthResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AuthResponse | PlainMessage<AuthResponse> | undefined, b: AuthResponse | PlainMessage<AuthResponse> | undefined): boolean {
    return proto3.util.equals(AuthResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.LogoutRequest
 */
export class LogoutRequest extends Message<LogoutRequest> {
  constructor(data?: PartialMessage<LogoutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.LogoutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutRequest {
    return new LogoutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutRequest | PlainMessage<LogoutRequest> | undefined, b: LogoutRequest | PlainMessage<LogoutRequest> | undefined): boolean {
    return proto3.util.equals(LogoutRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.LogoutResponse
 */
export class LogoutResponse extends Message<LogoutResponse> {
  constructor(data?: PartialMessage<LogoutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.LogoutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutResponse {
    return new LogoutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutResponse | PlainMessage<LogoutResponse> | undefined, b: LogoutResponse | PlainMessage<LogoutResponse> | undefined): boolean {
    return proto3.util.equals(LogoutResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.WhoAmIRequest
 */
export class WhoAmIRequest extends Message<WhoAmIRequest> {
  constructor(data?: PartialMessage<WhoAmIRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.WhoAmIRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WhoAmIRequest {
    return new WhoAmIRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WhoAmIRequest {
    return new WhoAmIRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WhoAmIRequest {
    return new WhoAmIRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WhoAmIRequest | PlainMessage<WhoAmIRequest> | undefined, b: WhoAmIRequest | PlainMessage<WhoAmIRequest> | undefined): boolean {
    return proto3.util.equals(WhoAmIRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.WhoAmIResponse
 */
export class WhoAmIResponse extends Message<WhoAmIResponse> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: repeated augmedi.quiz.Permission permissions = 3;
   */
  permissions: Permission[] = [];

  /**
   * @generated from field: string id = 4;
   */
  id = "";

  /**
   * @generated from field: string email = 5;
   */
  email = "";

  /**
   * @generated from field: bool seen_tutorial = 6;
   */
  seenTutorial = false;

  constructor(data?: PartialMessage<WhoAmIResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.WhoAmIResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "permissions", kind: "enum", T: proto3.getEnumType(Permission), repeated: true },
    { no: 4, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "seen_tutorial", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WhoAmIResponse {
    return new WhoAmIResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WhoAmIResponse {
    return new WhoAmIResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WhoAmIResponse {
    return new WhoAmIResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WhoAmIResponse | PlainMessage<WhoAmIResponse> | undefined, b: WhoAmIResponse | PlainMessage<WhoAmIResponse> | undefined): boolean {
    return proto3.util.equals(WhoAmIResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetAuthQrCodeRequest
 */
export class GetAuthQrCodeRequest extends Message<GetAuthQrCodeRequest> {
  constructor(data?: PartialMessage<GetAuthQrCodeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetAuthQrCodeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthQrCodeRequest {
    return new GetAuthQrCodeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthQrCodeRequest {
    return new GetAuthQrCodeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthQrCodeRequest {
    return new GetAuthQrCodeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthQrCodeRequest | PlainMessage<GetAuthQrCodeRequest> | undefined, b: GetAuthQrCodeRequest | PlainMessage<GetAuthQrCodeRequest> | undefined): boolean {
    return proto3.util.equals(GetAuthQrCodeRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetAuthQrCodeResponse
 */
export class GetAuthQrCodeResponse extends Message<GetAuthQrCodeResponse> {
  /**
   * @generated from field: bytes qr_code_png = 1;
   */
  qrCodePng = new Uint8Array(0);

  /**
   * @generated from field: google.protobuf.Duration refresh_in = 2;
   */
  refreshIn?: Duration;

  /**
   * @generated from field: google.protobuf.Duration expires_in = 3;
   */
  expiresIn?: Duration;

  constructor(data?: PartialMessage<GetAuthQrCodeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetAuthQrCodeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "qr_code_png", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "refresh_in", kind: "message", T: Duration },
    { no: 3, name: "expires_in", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthQrCodeResponse {
    return new GetAuthQrCodeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthQrCodeResponse {
    return new GetAuthQrCodeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthQrCodeResponse {
    return new GetAuthQrCodeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthQrCodeResponse | PlainMessage<GetAuthQrCodeResponse> | undefined, b: GetAuthQrCodeResponse | PlainMessage<GetAuthQrCodeResponse> | undefined): boolean {
    return proto3.util.equals(GetAuthQrCodeResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UseAuthQrCodeRequest
 */
export class UseAuthQrCodeRequest extends Message<UseAuthQrCodeRequest> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<UseAuthQrCodeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UseAuthQrCodeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UseAuthQrCodeRequest {
    return new UseAuthQrCodeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UseAuthQrCodeRequest {
    return new UseAuthQrCodeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UseAuthQrCodeRequest {
    return new UseAuthQrCodeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UseAuthQrCodeRequest | PlainMessage<UseAuthQrCodeRequest> | undefined, b: UseAuthQrCodeRequest | PlainMessage<UseAuthQrCodeRequest> | undefined): boolean {
    return proto3.util.equals(UseAuthQrCodeRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UseAuthQrCodeResponse
 */
export class UseAuthQrCodeResponse extends Message<UseAuthQrCodeResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<UseAuthQrCodeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UseAuthQrCodeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UseAuthQrCodeResponse {
    return new UseAuthQrCodeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UseAuthQrCodeResponse {
    return new UseAuthQrCodeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UseAuthQrCodeResponse {
    return new UseAuthQrCodeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UseAuthQrCodeResponse | PlainMessage<UseAuthQrCodeResponse> | undefined, b: UseAuthQrCodeResponse | PlainMessage<UseAuthQrCodeResponse> | undefined): boolean {
    return proto3.util.equals(UseAuthQrCodeResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteUserRequest
 */
export class DeleteUserRequest extends Message<DeleteUserRequest> {
  /**
   * Only non-admin users can be deleted via the API. To delete an admin user,
   * first make them non-admin with a manual database change and then delete
   * them with this RPC.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteUserRequest {
    return new DeleteUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteUserRequest {
    return new DeleteUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteUserRequest {
    return new DeleteUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteUserRequest | PlainMessage<DeleteUserRequest> | undefined, b: DeleteUserRequest | PlainMessage<DeleteUserRequest> | undefined): boolean {
    return proto3.util.equals(DeleteUserRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteUserResponse
 */
export class DeleteUserResponse extends Message<DeleteUserResponse> {
  constructor(data?: PartialMessage<DeleteUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteUserResponse {
    return new DeleteUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteUserResponse {
    return new DeleteUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteUserResponse {
    return new DeleteUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteUserResponse | PlainMessage<DeleteUserResponse> | undefined, b: DeleteUserResponse | PlainMessage<DeleteUserResponse> | undefined): boolean {
    return proto3.util.equals(DeleteUserResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListUsersRequest
 */
export class ListUsersRequest extends Message<ListUsersRequest> {
  constructor(data?: PartialMessage<ListUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListUsersRequest | PlainMessage<ListUsersRequest> | undefined, b: ListUsersRequest | PlainMessage<ListUsersRequest> | undefined): boolean {
    return proto3.util.equals(ListUsersRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string auth0Id = 3;
   */
  auth0Id = "";

  /**
   * @generated from field: repeated string roles = 4;
   */
  roles: string[] = [];

  /**
   * @generated from field: string name = 5;
   */
  name = "";

  /**
   * @generated from field: string email = 6;
   */
  email = "";

  /**
   * @generated from field: google.protobuf.Duration learning_time = 7;
   */
  learningTime?: Duration;

  /**
   * @generated from field: google.protobuf.Timestamp last_active = 8;
   */
  lastActive?: Timestamp;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "auth0Id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "roles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "learning_time", kind: "message", T: Duration },
    { no: 8, name: "last_active", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UserSettings
 */
export class UserSettings extends Message<UserSettings> {
  /**
   * @generated from field: bool mute_audio = 1;
   */
  muteAudio = false;

  constructor(data?: PartialMessage<UserSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UserSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mute_audio", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserSettings {
    return new UserSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserSettings {
    return new UserSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserSettings {
    return new UserSettings().fromJsonString(jsonString, options);
  }

  static equals(a: UserSettings | PlainMessage<UserSettings> | undefined, b: UserSettings | PlainMessage<UserSettings> | undefined): boolean {
    return proto3.util.equals(UserSettings, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListUsersResponse
 */
export class ListUsersResponse extends Message<ListUsersResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.User users = 1;
   */
  users: User[] = [];

  constructor(data?: PartialMessage<ListUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListUsersResponse | PlainMessage<ListUsersResponse> | undefined, b: ListUsersResponse | PlainMessage<ListUsersResponse> | undefined): boolean {
    return proto3.util.equals(ListUsersResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.SetUserSeenTutorialRequest
 */
export class SetUserSeenTutorialRequest extends Message<SetUserSeenTutorialRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<SetUserSeenTutorialRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.SetUserSeenTutorialRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetUserSeenTutorialRequest {
    return new SetUserSeenTutorialRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetUserSeenTutorialRequest {
    return new SetUserSeenTutorialRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetUserSeenTutorialRequest {
    return new SetUserSeenTutorialRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetUserSeenTutorialRequest | PlainMessage<SetUserSeenTutorialRequest> | undefined, b: SetUserSeenTutorialRequest | PlainMessage<SetUserSeenTutorialRequest> | undefined): boolean {
    return proto3.util.equals(SetUserSeenTutorialRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.SetUserSeenTutorialResponse
 */
export class SetUserSeenTutorialResponse extends Message<SetUserSeenTutorialResponse> {
  constructor(data?: PartialMessage<SetUserSeenTutorialResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.SetUserSeenTutorialResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetUserSeenTutorialResponse {
    return new SetUserSeenTutorialResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetUserSeenTutorialResponse {
    return new SetUserSeenTutorialResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetUserSeenTutorialResponse {
    return new SetUserSeenTutorialResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetUserSeenTutorialResponse | PlainMessage<SetUserSeenTutorialResponse> | undefined, b: SetUserSeenTutorialResponse | PlainMessage<SetUserSeenTutorialResponse> | undefined): boolean {
    return proto3.util.equals(SetUserSeenTutorialResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UpdateUserRequest
 */
export class UpdateUserRequest extends Message<UpdateUserRequest> {
  /**
   * @generated from field: augmedi.quiz.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<UpdateUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UpdateUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined, b: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined): boolean {
    return proto3.util.equals(UpdateUserRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UpdateUserResponse
 */
export class UpdateUserResponse extends Message<UpdateUserResponse> {
  constructor(data?: PartialMessage<UpdateUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UpdateUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserResponse {
    return new UpdateUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserResponse {
    return new UpdateUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserResponse {
    return new UpdateUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserResponse | PlainMessage<UpdateUserResponse> | undefined, b: UpdateUserResponse | PlainMessage<UpdateUserResponse> | undefined): boolean {
    return proto3.util.equals(UpdateUserResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.SetUserTeachAnatomySubscriptionRequest
 */
export class SetUserTeachAnatomySubscriptionRequest extends Message<SetUserTeachAnatomySubscriptionRequest> {
  constructor(data?: PartialMessage<SetUserTeachAnatomySubscriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.SetUserTeachAnatomySubscriptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetUserTeachAnatomySubscriptionRequest {
    return new SetUserTeachAnatomySubscriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetUserTeachAnatomySubscriptionRequest {
    return new SetUserTeachAnatomySubscriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetUserTeachAnatomySubscriptionRequest {
    return new SetUserTeachAnatomySubscriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetUserTeachAnatomySubscriptionRequest | PlainMessage<SetUserTeachAnatomySubscriptionRequest> | undefined, b: SetUserTeachAnatomySubscriptionRequest | PlainMessage<SetUserTeachAnatomySubscriptionRequest> | undefined): boolean {
    return proto3.util.equals(SetUserTeachAnatomySubscriptionRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.SetUserTeachAnatomySubscriptionResponse
 */
export class SetUserTeachAnatomySubscriptionResponse extends Message<SetUserTeachAnatomySubscriptionResponse> {
  constructor(data?: PartialMessage<SetUserTeachAnatomySubscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.SetUserTeachAnatomySubscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetUserTeachAnatomySubscriptionResponse {
    return new SetUserTeachAnatomySubscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetUserTeachAnatomySubscriptionResponse {
    return new SetUserTeachAnatomySubscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetUserTeachAnatomySubscriptionResponse {
    return new SetUserTeachAnatomySubscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetUserTeachAnatomySubscriptionResponse | PlainMessage<SetUserTeachAnatomySubscriptionResponse> | undefined, b: SetUserTeachAnatomySubscriptionResponse | PlainMessage<SetUserTeachAnatomySubscriptionResponse> | undefined): boolean {
    return proto3.util.equals(SetUserTeachAnatomySubscriptionResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DemoResetRequest
 */
export class DemoResetRequest extends Message<DemoResetRequest> {
  constructor(data?: PartialMessage<DemoResetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DemoResetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DemoResetRequest {
    return new DemoResetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DemoResetRequest {
    return new DemoResetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DemoResetRequest {
    return new DemoResetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DemoResetRequest | PlainMessage<DemoResetRequest> | undefined, b: DemoResetRequest | PlainMessage<DemoResetRequest> | undefined): boolean {
    return proto3.util.equals(DemoResetRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DemoResetResponse
 */
export class DemoResetResponse extends Message<DemoResetResponse> {
  constructor(data?: PartialMessage<DemoResetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DemoResetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DemoResetResponse {
    return new DemoResetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DemoResetResponse {
    return new DemoResetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DemoResetResponse {
    return new DemoResetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DemoResetResponse | PlainMessage<DemoResetResponse> | undefined, b: DemoResetResponse | PlainMessage<DemoResetResponse> | undefined): boolean {
    return proto3.util.equals(DemoResetResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DemoPasswordRequest
 */
export class DemoPasswordRequest extends Message<DemoPasswordRequest> {
  constructor(data?: PartialMessage<DemoPasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DemoPasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DemoPasswordRequest {
    return new DemoPasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DemoPasswordRequest {
    return new DemoPasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DemoPasswordRequest {
    return new DemoPasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DemoPasswordRequest | PlainMessage<DemoPasswordRequest> | undefined, b: DemoPasswordRequest | PlainMessage<DemoPasswordRequest> | undefined): boolean {
    return proto3.util.equals(DemoPasswordRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DemoPasswordResponse
 */
export class DemoPasswordResponse extends Message<DemoPasswordResponse> {
  /**
   * @generated from field: string demoPassword = 1;
   */
  demoPassword = "";

  constructor(data?: PartialMessage<DemoPasswordResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DemoPasswordResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "demoPassword", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DemoPasswordResponse {
    return new DemoPasswordResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DemoPasswordResponse {
    return new DemoPasswordResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DemoPasswordResponse {
    return new DemoPasswordResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DemoPasswordResponse | PlainMessage<DemoPasswordResponse> | undefined, b: DemoPasswordResponse | PlainMessage<DemoPasswordResponse> | undefined): boolean {
    return proto3.util.equals(DemoPasswordResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ResetUserProgressRequest
 */
export class ResetUserProgressRequest extends Message<ResetUserProgressRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<ResetUserProgressRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ResetUserProgressRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetUserProgressRequest {
    return new ResetUserProgressRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetUserProgressRequest {
    return new ResetUserProgressRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetUserProgressRequest {
    return new ResetUserProgressRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetUserProgressRequest | PlainMessage<ResetUserProgressRequest> | undefined, b: ResetUserProgressRequest | PlainMessage<ResetUserProgressRequest> | undefined): boolean {
    return proto3.util.equals(ResetUserProgressRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ResetUserProgressResponse
 */
export class ResetUserProgressResponse extends Message<ResetUserProgressResponse> {
  constructor(data?: PartialMessage<ResetUserProgressResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ResetUserProgressResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetUserProgressResponse {
    return new ResetUserProgressResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetUserProgressResponse {
    return new ResetUserProgressResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetUserProgressResponse {
    return new ResetUserProgressResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResetUserProgressResponse | PlainMessage<ResetUserProgressResponse> | undefined, b: ResetUserProgressResponse | PlainMessage<ResetUserProgressResponse> | undefined): boolean {
    return proto3.util.equals(ResetUserProgressResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UpdateUserSettingsRequest
 */
export class UpdateUserSettingsRequest extends Message<UpdateUserSettingsRequest> {
  /**
   * @generated from field: augmedi.quiz.UserSettings settings = 2;
   */
  settings?: UserSettings;

  constructor(data?: PartialMessage<UpdateUserSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UpdateUserSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "settings", kind: "message", T: UserSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserSettingsRequest {
    return new UpdateUserSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserSettingsRequest {
    return new UpdateUserSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserSettingsRequest {
    return new UpdateUserSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserSettingsRequest | PlainMessage<UpdateUserSettingsRequest> | undefined, b: UpdateUserSettingsRequest | PlainMessage<UpdateUserSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateUserSettingsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UpdateUserSettingsResponse
 */
export class UpdateUserSettingsResponse extends Message<UpdateUserSettingsResponse> {
  constructor(data?: PartialMessage<UpdateUserSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UpdateUserSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserSettingsResponse {
    return new UpdateUserSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserSettingsResponse {
    return new UpdateUserSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserSettingsResponse {
    return new UpdateUserSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserSettingsResponse | PlainMessage<UpdateUserSettingsResponse> | undefined, b: UpdateUserSettingsResponse | PlainMessage<UpdateUserSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateUserSettingsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetUserSettingsRequest
 */
export class GetUserSettingsRequest extends Message<GetUserSettingsRequest> {
  constructor(data?: PartialMessage<GetUserSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetUserSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserSettingsRequest {
    return new GetUserSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserSettingsRequest {
    return new GetUserSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserSettingsRequest {
    return new GetUserSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserSettingsRequest | PlainMessage<GetUserSettingsRequest> | undefined, b: GetUserSettingsRequest | PlainMessage<GetUserSettingsRequest> | undefined): boolean {
    return proto3.util.equals(GetUserSettingsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetUserSettingsResponse
 */
export class GetUserSettingsResponse extends Message<GetUserSettingsResponse> {
  /**
   * @generated from field: augmedi.quiz.UserSettings settings = 1;
   */
  settings?: UserSettings;

  constructor(data?: PartialMessage<GetUserSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetUserSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: UserSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserSettingsResponse {
    return new GetUserSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserSettingsResponse {
    return new GetUserSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserSettingsResponse {
    return new GetUserSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserSettingsResponse | PlainMessage<GetUserSettingsResponse> | undefined, b: GetUserSettingsResponse | PlainMessage<GetUserSettingsResponse> | undefined): boolean {
    return proto3.util.equals(GetUserSettingsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListModelsRequest
 */
export class ListModelsRequest extends Message<ListModelsRequest> {
  constructor(data?: PartialMessage<ListModelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListModelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelsRequest {
    return new ListModelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelsRequest {
    return new ListModelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelsRequest {
    return new ListModelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelsRequest | PlainMessage<ListModelsRequest> | undefined, b: ListModelsRequest | PlainMessage<ListModelsRequest> | undefined): boolean {
    return proto3.util.equals(ListModelsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListModelsResponse
 */
export class ListModelsResponse extends Message<ListModelsResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.Model models = 1;
   */
  models: Model[] = [];

  constructor(data?: PartialMessage<ListModelsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListModelsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "models", kind: "message", T: Model, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelsResponse {
    return new ListModelsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelsResponse {
    return new ListModelsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelsResponse {
    return new ListModelsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelsResponse | PlainMessage<ListModelsResponse> | undefined, b: ListModelsResponse | PlainMessage<ListModelsResponse> | undefined): boolean {
    return proto3.util.equals(ListModelsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetModelRequest
 */
export class GetModelRequest extends Message<GetModelRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetModelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetModelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModelRequest {
    return new GetModelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModelRequest {
    return new GetModelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModelRequest {
    return new GetModelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetModelRequest | PlainMessage<GetModelRequest> | undefined, b: GetModelRequest | PlainMessage<GetModelRequest> | undefined): boolean {
    return proto3.util.equals(GetModelRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.CreateModelRequest
 */
export class CreateModelRequest extends Message<CreateModelRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * one per partition
   *
   * @generated from field: repeated string base_model_paths = 2;
   */
  baseModelPaths: string[] = [];

  constructor(data?: PartialMessage<CreateModelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.CreateModelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "base_model_paths", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateModelRequest {
    return new CreateModelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateModelRequest {
    return new CreateModelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateModelRequest {
    return new CreateModelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateModelRequest | PlainMessage<CreateModelRequest> | undefined, b: CreateModelRequest | PlainMessage<CreateModelRequest> | undefined): boolean {
    return proto3.util.equals(CreateModelRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetModelAssetRequest
 */
export class GetModelAssetRequest extends Message<GetModelAssetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string partition_id = 3;
   */
  partitionId = "";

  /**
   * @generated from field: augmedi.quiz.ModelAssetType type = 2;
   */
  type = ModelAssetType.UNKNOWN;

  constructor(data?: PartialMessage<GetModelAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetModelAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "partition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ModelAssetType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModelAssetRequest {
    return new GetModelAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModelAssetRequest {
    return new GetModelAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModelAssetRequest {
    return new GetModelAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetModelAssetRequest | PlainMessage<GetModelAssetRequest> | undefined, b: GetModelAssetRequest | PlainMessage<GetModelAssetRequest> | undefined): boolean {
    return proto3.util.equals(GetModelAssetRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetModelAssetResponse
 */
export class GetModelAssetResponse extends Message<GetModelAssetResponse> {
  /**
   * @generated from field: string download_url = 1;
   */
  downloadUrl = "";

  constructor(data?: PartialMessage<GetModelAssetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetModelAssetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "download_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModelAssetResponse {
    return new GetModelAssetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModelAssetResponse {
    return new GetModelAssetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModelAssetResponse {
    return new GetModelAssetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetModelAssetResponse | PlainMessage<GetModelAssetResponse> | undefined, b: GetModelAssetResponse | PlainMessage<GetModelAssetResponse> | undefined): boolean {
    return proto3.util.equals(GetModelAssetResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FreezeModelRequest
 */
export class FreezeModelRequest extends Message<FreezeModelRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<FreezeModelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FreezeModelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FreezeModelRequest {
    return new FreezeModelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FreezeModelRequest {
    return new FreezeModelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FreezeModelRequest {
    return new FreezeModelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FreezeModelRequest | PlainMessage<FreezeModelRequest> | undefined, b: FreezeModelRequest | PlainMessage<FreezeModelRequest> | undefined): boolean {
    return proto3.util.equals(FreezeModelRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FreezeModelResponse
 */
export class FreezeModelResponse extends Message<FreezeModelResponse> {
  /**
   * @generated from field: string frozen_model_id = 1;
   */
  frozenModelId = "";

  constructor(data?: PartialMessage<FreezeModelResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FreezeModelResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "frozen_model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FreezeModelResponse {
    return new FreezeModelResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FreezeModelResponse {
    return new FreezeModelResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FreezeModelResponse {
    return new FreezeModelResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FreezeModelResponse | PlainMessage<FreezeModelResponse> | undefined, b: FreezeModelResponse | PlainMessage<FreezeModelResponse> | undefined): boolean {
    return proto3.util.equals(FreezeModelResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetFrozenModelRequest
 */
export class GetFrozenModelRequest extends Message<GetFrozenModelRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetFrozenModelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetFrozenModelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFrozenModelRequest {
    return new GetFrozenModelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFrozenModelRequest {
    return new GetFrozenModelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFrozenModelRequest {
    return new GetFrozenModelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFrozenModelRequest | PlainMessage<GetFrozenModelRequest> | undefined, b: GetFrozenModelRequest | PlainMessage<GetFrozenModelRequest> | undefined): boolean {
    return proto3.util.equals(GetFrozenModelRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetFrozenModelAssetRequest
 */
export class GetFrozenModelAssetRequest extends Message<GetFrozenModelAssetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string partition_id = 3;
   */
  partitionId = "";

  /**
   * @generated from field: augmedi.quiz.FrozenModelAssetType type = 2;
   */
  type = FrozenModelAssetType.UNKNOWN;

  constructor(data?: PartialMessage<GetFrozenModelAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetFrozenModelAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "partition_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(FrozenModelAssetType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFrozenModelAssetRequest {
    return new GetFrozenModelAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFrozenModelAssetRequest {
    return new GetFrozenModelAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFrozenModelAssetRequest {
    return new GetFrozenModelAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFrozenModelAssetRequest | PlainMessage<GetFrozenModelAssetRequest> | undefined, b: GetFrozenModelAssetRequest | PlainMessage<GetFrozenModelAssetRequest> | undefined): boolean {
    return proto3.util.equals(GetFrozenModelAssetRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetFrozenModelAssetResponse
 */
export class GetFrozenModelAssetResponse extends Message<GetFrozenModelAssetResponse> {
  /**
   * @generated from field: string download_url = 1;
   */
  downloadUrl = "";

  constructor(data?: PartialMessage<GetFrozenModelAssetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetFrozenModelAssetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "download_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFrozenModelAssetResponse {
    return new GetFrozenModelAssetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFrozenModelAssetResponse {
    return new GetFrozenModelAssetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFrozenModelAssetResponse {
    return new GetFrozenModelAssetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFrozenModelAssetResponse | PlainMessage<GetFrozenModelAssetResponse> | undefined, b: GetFrozenModelAssetResponse | PlainMessage<GetFrozenModelAssetResponse> | undefined): boolean {
    return proto3.util.equals(GetFrozenModelAssetResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListModelLabelsRequest
 */
export class ListModelLabelsRequest extends Message<ListModelLabelsRequest> {
  /**
   * @generated from field: string model_id = 1;
   */
  modelId = "";

  constructor(data?: PartialMessage<ListModelLabelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListModelLabelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelLabelsRequest {
    return new ListModelLabelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelLabelsRequest {
    return new ListModelLabelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelLabelsRequest {
    return new ListModelLabelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelLabelsRequest | PlainMessage<ListModelLabelsRequest> | undefined, b: ListModelLabelsRequest | PlainMessage<ListModelLabelsRequest> | undefined): boolean {
    return proto3.util.equals(ListModelLabelsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListModelLabelsResponse
 */
export class ListModelLabelsResponse extends Message<ListModelLabelsResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.ModelLabel labels = 1;
   */
  labels: ModelLabel[] = [];

  constructor(data?: PartialMessage<ListModelLabelsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListModelLabelsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "labels", kind: "message", T: ModelLabel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelLabelsResponse {
    return new ListModelLabelsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelLabelsResponse {
    return new ListModelLabelsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelLabelsResponse {
    return new ListModelLabelsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelLabelsResponse | PlainMessage<ListModelLabelsResponse> | undefined, b: ListModelLabelsResponse | PlainMessage<ListModelLabelsResponse> | undefined): boolean {
    return proto3.util.equals(ListModelLabelsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.CreateModelLabelRequest
 */
export class CreateModelLabelRequest extends Message<CreateModelLabelRequest> {
  /**
   * @generated from field: string model_id = 1;
   */
  modelId = "";

  /**
   * @generated from field: string mesh_id = 5;
   */
  meshId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: augmedi.quiz.MaskImage mask = 3;
   */
  mask?: MaskImage;

  /**
   * optional
   *
   * @generated from field: string structure_id = 6;
   */
  structureId = "";

  constructor(data?: PartialMessage<CreateModelLabelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.CreateModelLabelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mask", kind: "message", T: MaskImage },
    { no: 6, name: "structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateModelLabelRequest {
    return new CreateModelLabelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateModelLabelRequest {
    return new CreateModelLabelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateModelLabelRequest {
    return new CreateModelLabelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateModelLabelRequest | PlainMessage<CreateModelLabelRequest> | undefined, b: CreateModelLabelRequest | PlainMessage<CreateModelLabelRequest> | undefined): boolean {
    return proto3.util.equals(CreateModelLabelRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UpdateModelLabelRequest
 */
export class UpdateModelLabelRequest extends Message<UpdateModelLabelRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * required for normal labels and not allowed for whole mesh labels
   *
   * @generated from field: augmedi.quiz.MaskImage mask = 3;
   */
  mask?: MaskImage;

  /**
   * @generated from field: string structure_id = 5;
   */
  structureId = "";

  constructor(data?: PartialMessage<UpdateModelLabelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UpdateModelLabelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mask", kind: "message", T: MaskImage },
    { no: 5, name: "structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateModelLabelRequest {
    return new UpdateModelLabelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateModelLabelRequest {
    return new UpdateModelLabelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateModelLabelRequest {
    return new UpdateModelLabelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateModelLabelRequest | PlainMessage<UpdateModelLabelRequest> | undefined, b: UpdateModelLabelRequest | PlainMessage<UpdateModelLabelRequest> | undefined): boolean {
    return proto3.util.equals(UpdateModelLabelRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteModelLabelRequest
 */
export class DeleteModelLabelRequest extends Message<DeleteModelLabelRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteModelLabelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteModelLabelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteModelLabelRequest {
    return new DeleteModelLabelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteModelLabelRequest {
    return new DeleteModelLabelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteModelLabelRequest {
    return new DeleteModelLabelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteModelLabelRequest | PlainMessage<DeleteModelLabelRequest> | undefined, b: DeleteModelLabelRequest | PlainMessage<DeleteModelLabelRequest> | undefined): boolean {
    return proto3.util.equals(DeleteModelLabelRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteModelLabelResponse
 */
export class DeleteModelLabelResponse extends Message<DeleteModelLabelResponse> {
  constructor(data?: PartialMessage<DeleteModelLabelResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteModelLabelResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteModelLabelResponse {
    return new DeleteModelLabelResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteModelLabelResponse {
    return new DeleteModelLabelResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteModelLabelResponse {
    return new DeleteModelLabelResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteModelLabelResponse | PlainMessage<DeleteModelLabelResponse> | undefined, b: DeleteModelLabelResponse | PlainMessage<DeleteModelLabelResponse> | undefined): boolean {
    return proto3.util.equals(DeleteModelLabelResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListModelMeshesRequest
 */
export class ListModelMeshesRequest extends Message<ListModelMeshesRequest> {
  /**
   * @generated from field: string model_id = 1;
   */
  modelId = "";

  constructor(data?: PartialMessage<ListModelMeshesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListModelMeshesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "model_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelMeshesRequest {
    return new ListModelMeshesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelMeshesRequest {
    return new ListModelMeshesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelMeshesRequest {
    return new ListModelMeshesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelMeshesRequest | PlainMessage<ListModelMeshesRequest> | undefined, b: ListModelMeshesRequest | PlainMessage<ListModelMeshesRequest> | undefined): boolean {
    return proto3.util.equals(ListModelMeshesRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListModelMeshesResponse
 */
export class ListModelMeshesResponse extends Message<ListModelMeshesResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.ModelMesh meshes = 1;
   */
  meshes: ModelMesh[] = [];

  constructor(data?: PartialMessage<ListModelMeshesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListModelMeshesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "meshes", kind: "message", T: ModelMesh, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelMeshesResponse {
    return new ListModelMeshesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelMeshesResponse {
    return new ListModelMeshesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelMeshesResponse {
    return new ListModelMeshesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelMeshesResponse | PlainMessage<ListModelMeshesResponse> | undefined, b: ListModelMeshesResponse | PlainMessage<ListModelMeshesResponse> | undefined): boolean {
    return proto3.util.equals(ListModelMeshesResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.CreateMediaItemRequest
 */
export class CreateMediaItemRequest extends Message<CreateMediaItemRequest> {
  /**
   * @generated from field: string path = 1;
   */
  path = "";

  constructor(data?: PartialMessage<CreateMediaItemRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.CreateMediaItemRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMediaItemRequest {
    return new CreateMediaItemRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMediaItemRequest {
    return new CreateMediaItemRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMediaItemRequest {
    return new CreateMediaItemRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMediaItemRequest | PlainMessage<CreateMediaItemRequest> | undefined, b: CreateMediaItemRequest | PlainMessage<CreateMediaItemRequest> | undefined): boolean {
    return proto3.util.equals(CreateMediaItemRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetMediaItemAssetRequest
 */
export class GetMediaItemAssetRequest extends Message<GetMediaItemAssetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetMediaItemAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetMediaItemAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMediaItemAssetRequest {
    return new GetMediaItemAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMediaItemAssetRequest {
    return new GetMediaItemAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMediaItemAssetRequest {
    return new GetMediaItemAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMediaItemAssetRequest | PlainMessage<GetMediaItemAssetRequest> | undefined, b: GetMediaItemAssetRequest | PlainMessage<GetMediaItemAssetRequest> | undefined): boolean {
    return proto3.util.equals(GetMediaItemAssetRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetMediaItemAssetResponse
 */
export class GetMediaItemAssetResponse extends Message<GetMediaItemAssetResponse> {
  /**
   * @generated from field: string download_url = 1;
   */
  downloadUrl = "";

  /**
   * @generated from field: augmedi.quiz.MediaItemType type = 2;
   */
  type = MediaItemType.UNKNOWN;

  constructor(data?: PartialMessage<GetMediaItemAssetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetMediaItemAssetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "download_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(MediaItemType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMediaItemAssetResponse {
    return new GetMediaItemAssetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMediaItemAssetResponse {
    return new GetMediaItemAssetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMediaItemAssetResponse {
    return new GetMediaItemAssetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMediaItemAssetResponse | PlainMessage<GetMediaItemAssetResponse> | undefined, b: GetMediaItemAssetResponse | PlainMessage<GetMediaItemAssetResponse> | undefined): boolean {
    return proto3.util.equals(GetMediaItemAssetResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListMediaItemsRequest
 */
export class ListMediaItemsRequest extends Message<ListMediaItemsRequest> {
  constructor(data?: PartialMessage<ListMediaItemsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListMediaItemsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMediaItemsRequest {
    return new ListMediaItemsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMediaItemsRequest {
    return new ListMediaItemsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMediaItemsRequest {
    return new ListMediaItemsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListMediaItemsRequest | PlainMessage<ListMediaItemsRequest> | undefined, b: ListMediaItemsRequest | PlainMessage<ListMediaItemsRequest> | undefined): boolean {
    return proto3.util.equals(ListMediaItemsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListMediaItemsResponse
 */
export class ListMediaItemsResponse extends Message<ListMediaItemsResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.MediaItemWithPath paths = 1;
   */
  paths: MediaItemWithPath[] = [];

  constructor(data?: PartialMessage<ListMediaItemsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListMediaItemsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "paths", kind: "message", T: MediaItemWithPath, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMediaItemsResponse {
    return new ListMediaItemsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMediaItemsResponse {
    return new ListMediaItemsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMediaItemsResponse {
    return new ListMediaItemsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListMediaItemsResponse | PlainMessage<ListMediaItemsResponse> | undefined, b: ListMediaItemsResponse | PlainMessage<ListMediaItemsResponse> | undefined): boolean {
    return proto3.util.equals(ListMediaItemsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizItemRequest
 */
export class GetQuizItemRequest extends Message<GetQuizItemRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetQuizItemRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizItemRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizItemRequest {
    return new GetQuizItemRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizItemRequest {
    return new GetQuizItemRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizItemRequest {
    return new GetQuizItemRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizItemRequest | PlainMessage<GetQuizItemRequest> | undefined, b: GetQuizItemRequest | PlainMessage<GetQuizItemRequest> | undefined): boolean {
    return proto3.util.equals(GetQuizItemRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizItemResponse
 */
export class GetQuizItemResponse extends Message<GetQuizItemResponse> {
  /**
   * @generated from field: augmedi.quiz.QuizItem quiz_item = 1;
   */
  quizItem?: QuizItem;

  constructor(data?: PartialMessage<GetQuizItemResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizItemResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quiz_item", kind: "message", T: QuizItem },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizItemResponse {
    return new GetQuizItemResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizItemResponse {
    return new GetQuizItemResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizItemResponse {
    return new GetQuizItemResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizItemResponse | PlainMessage<GetQuizItemResponse> | undefined, b: GetQuizItemResponse | PlainMessage<GetQuizItemResponse> | undefined): boolean {
    return proto3.util.equals(GetQuizItemResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizItemsRequest
 */
export class GetQuizItemsRequest extends Message<GetQuizItemsRequest> {
  /**
   * @generated from field: string search_term = 1;
   */
  searchTerm = "";

  constructor(data?: PartialMessage<GetQuizItemsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizItemsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_term", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizItemsRequest {
    return new GetQuizItemsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizItemsRequest {
    return new GetQuizItemsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizItemsRequest {
    return new GetQuizItemsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizItemsRequest | PlainMessage<GetQuizItemsRequest> | undefined, b: GetQuizItemsRequest | PlainMessage<GetQuizItemsRequest> | undefined): boolean {
    return proto3.util.equals(GetQuizItemsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizItemsResponse
 */
export class GetQuizItemsResponse extends Message<GetQuizItemsResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.GetQuizItemsResponse.Question questions = 1;
   */
  questions: GetQuizItemsResponse_Question[] = [];

  constructor(data?: PartialMessage<GetQuizItemsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizItemsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questions", kind: "message", T: GetQuizItemsResponse_Question, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizItemsResponse {
    return new GetQuizItemsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizItemsResponse {
    return new GetQuizItemsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizItemsResponse {
    return new GetQuizItemsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizItemsResponse | PlainMessage<GetQuizItemsResponse> | undefined, b: GetQuizItemsResponse | PlainMessage<GetQuizItemsResponse> | undefined): boolean {
    return proto3.util.equals(GetQuizItemsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizItemsResponse.Question
 */
export class GetQuizItemsResponse_Question extends Message<GetQuizItemsResponse_Question> {
  /**
   * @generated from field: string text = 1;
   */
  text = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<GetQuizItemsResponse_Question>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizItemsResponse.Question";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizItemsResponse_Question {
    return new GetQuizItemsResponse_Question().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizItemsResponse_Question {
    return new GetQuizItemsResponse_Question().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizItemsResponse_Question {
    return new GetQuizItemsResponse_Question().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizItemsResponse_Question | PlainMessage<GetQuizItemsResponse_Question> | undefined, b: GetQuizItemsResponse_Question | PlainMessage<GetQuizItemsResponse_Question> | undefined): boolean {
    return proto3.util.equals(GetQuizItemsResponse_Question, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.QuizRequest
 */
export class QuizRequest extends Message<QuizRequest> {
  /**
   * optional
   *
   * @generated from field: string frozen_chapter_id = 1;
   */
  frozenChapterId = "";

  /**
   * @generated from field: augmedi.quiz.QuizMode mode = 2;
   */
  mode = QuizMode.UNKNOWN;

  /**
   * Only valid with QuizMode.REPEAT_OLD. If true, questions will be repeated even if they're not due yet.
   *
   * @generated from field: bool early_repeat = 5;
   */
  earlyRepeat = false;

  /**
   * @generated from field: repeated augmedi.quiz.ConsumedQuizItem consumed_quiz_items = 3;
   */
  consumedQuizItems: ConsumedQuizItem[] = [];

  /**
   * optional
   *
   * @generated from field: augmedi.quiz.BodyPart body_part = 6;
   */
  bodyPart = BodyPart.BODYPART_UNKNOWN;

  constructor(data?: PartialMessage<QuizRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.QuizRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "frozen_chapter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mode", kind: "enum", T: proto3.getEnumType(QuizMode) },
    { no: 5, name: "early_repeat", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "consumed_quiz_items", kind: "message", T: ConsumedQuizItem, repeated: true },
    { no: 6, name: "body_part", kind: "enum", T: proto3.getEnumType(BodyPart) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuizRequest {
    return new QuizRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuizRequest {
    return new QuizRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuizRequest {
    return new QuizRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QuizRequest | PlainMessage<QuizRequest> | undefined, b: QuizRequest | PlainMessage<QuizRequest> | undefined): boolean {
    return proto3.util.equals(QuizRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.QuizResponse
 */
export class QuizResponse extends Message<QuizResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.QuizItem quiz_items = 1;
   */
  quizItems: QuizItem[] = [];

  constructor(data?: PartialMessage<QuizResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.QuizResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quiz_items", kind: "message", T: QuizItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuizResponse {
    return new QuizResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuizResponse {
    return new QuizResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuizResponse {
    return new QuizResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QuizResponse | PlainMessage<QuizResponse> | undefined, b: QuizResponse | PlainMessage<QuizResponse> | undefined): boolean {
    return proto3.util.equals(QuizResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizProgressRequest
 */
export class GetQuizProgressRequest extends Message<GetQuizProgressRequest> {
  constructor(data?: PartialMessage<GetQuizProgressRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizProgressRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizProgressRequest {
    return new GetQuizProgressRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizProgressRequest {
    return new GetQuizProgressRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizProgressRequest {
    return new GetQuizProgressRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizProgressRequest | PlainMessage<GetQuizProgressRequest> | undefined, b: GetQuizProgressRequest | PlainMessage<GetQuizProgressRequest> | undefined): boolean {
    return proto3.util.equals(GetQuizProgressRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizProgressResponse
 */
export class GetQuizProgressResponse extends Message<GetQuizProgressResponse> {
  /**
   * Chapters whose ratios are all 0 may (or may not) be missing from the map.
   *
   * @generated from field: map<string, augmedi.quiz.GetQuizProgressResponse.ChapterProgress> progress_by_frozen_chapter_id = 1;
   */
  progressByFrozenChapterId: { [key: string]: GetQuizProgressResponse_ChapterProgress } = {};

  constructor(data?: PartialMessage<GetQuizProgressResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizProgressResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "progress_by_frozen_chapter_id", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: GetQuizProgressResponse_ChapterProgress} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizProgressResponse {
    return new GetQuizProgressResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizProgressResponse {
    return new GetQuizProgressResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizProgressResponse {
    return new GetQuizProgressResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizProgressResponse | PlainMessage<GetQuizProgressResponse> | undefined, b: GetQuizProgressResponse | PlainMessage<GetQuizProgressResponse> | undefined): boolean {
    return proto3.util.equals(GetQuizProgressResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizProgressResponse.ChapterProgress
 */
export class GetQuizProgressResponse_ChapterProgress extends Message<GetQuizProgressResponse_ChapterProgress> {
  /**
   * @generated from field: float learned_ratio = 2;
   */
  learnedRatio = 0;

  /**
   * @generated from field: float needs_review_ratio = 3;
   */
  needsReviewRatio = 0;

  constructor(data?: PartialMessage<GetQuizProgressResponse_ChapterProgress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizProgressResponse.ChapterProgress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "learned_ratio", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "needs_review_ratio", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizProgressResponse_ChapterProgress {
    return new GetQuizProgressResponse_ChapterProgress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizProgressResponse_ChapterProgress {
    return new GetQuizProgressResponse_ChapterProgress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizProgressResponse_ChapterProgress {
    return new GetQuizProgressResponse_ChapterProgress().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizProgressResponse_ChapterProgress | PlainMessage<GetQuizProgressResponse_ChapterProgress> | undefined, b: GetQuizProgressResponse_ChapterProgress | PlainMessage<GetQuizProgressResponse_ChapterProgress> | undefined): boolean {
    return proto3.util.equals(GetQuizProgressResponse_ChapterProgress, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizRecentStatsRequest
 */
export class GetQuizRecentStatsRequest extends Message<GetQuizRecentStatsRequest> {
  /**
   * @generated from field: augmedi.quiz.RecentStatsRange range = 1;
   */
  range = RecentStatsRange.UNKNOWN;

  constructor(data?: PartialMessage<GetQuizRecentStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizRecentStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "range", kind: "enum", T: proto3.getEnumType(RecentStatsRange) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizRecentStatsRequest {
    return new GetQuizRecentStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizRecentStatsRequest {
    return new GetQuizRecentStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizRecentStatsRequest {
    return new GetQuizRecentStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizRecentStatsRequest | PlainMessage<GetQuizRecentStatsRequest> | undefined, b: GetQuizRecentStatsRequest | PlainMessage<GetQuizRecentStatsRequest> | undefined): boolean {
    return proto3.util.equals(GetQuizRecentStatsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizDailyEffortStatsRequest
 */
export class GetQuizDailyEffortStatsRequest extends Message<GetQuizDailyEffortStatsRequest> {
  /**
   * @generated from field: int32 utc_offset = 1;
   */
  utcOffset = 0;

  constructor(data?: PartialMessage<GetQuizDailyEffortStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizDailyEffortStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "utc_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizDailyEffortStatsRequest {
    return new GetQuizDailyEffortStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizDailyEffortStatsRequest {
    return new GetQuizDailyEffortStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizDailyEffortStatsRequest {
    return new GetQuizDailyEffortStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizDailyEffortStatsRequest | PlainMessage<GetQuizDailyEffortStatsRequest> | undefined, b: GetQuizDailyEffortStatsRequest | PlainMessage<GetQuizDailyEffortStatsRequest> | undefined): boolean {
    return proto3.util.equals(GetQuizDailyEffortStatsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizDailyEffortStatsResponse
 */
export class GetQuizDailyEffortStatsResponse extends Message<GetQuizDailyEffortStatsResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.GetQuizDailyEffortStatsResponse.DailyStats daily_stats_by_date = 1;
   */
  dailyStatsByDate: GetQuizDailyEffortStatsResponse_DailyStats[] = [];

  constructor(data?: PartialMessage<GetQuizDailyEffortStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizDailyEffortStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "daily_stats_by_date", kind: "message", T: GetQuizDailyEffortStatsResponse_DailyStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizDailyEffortStatsResponse {
    return new GetQuizDailyEffortStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizDailyEffortStatsResponse {
    return new GetQuizDailyEffortStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizDailyEffortStatsResponse {
    return new GetQuizDailyEffortStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizDailyEffortStatsResponse | PlainMessage<GetQuizDailyEffortStatsResponse> | undefined, b: GetQuizDailyEffortStatsResponse | PlainMessage<GetQuizDailyEffortStatsResponse> | undefined): boolean {
    return proto3.util.equals(GetQuizDailyEffortStatsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizDailyEffortStatsResponse.DailyStats
 */
export class GetQuizDailyEffortStatsResponse_DailyStats extends Message<GetQuizDailyEffortStatsResponse_DailyStats> {
  /**
   * @generated from field: string date = 1;
   */
  date = "";

  /**
   * @generated from field: int32 correct_answers = 2;
   */
  correctAnswers = 0;

  /**
   * @generated from field: int32 wrong_answers = 3;
   */
  wrongAnswers = 0;

  /**
   * @generated from field: google.protobuf.Duration answering_time = 4;
   */
  answeringTime?: Duration;

  constructor(data?: PartialMessage<GetQuizDailyEffortStatsResponse_DailyStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizDailyEffortStatsResponse.DailyStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "correct_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "wrong_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "answering_time", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizDailyEffortStatsResponse_DailyStats {
    return new GetQuizDailyEffortStatsResponse_DailyStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizDailyEffortStatsResponse_DailyStats {
    return new GetQuizDailyEffortStatsResponse_DailyStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizDailyEffortStatsResponse_DailyStats {
    return new GetQuizDailyEffortStatsResponse_DailyStats().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizDailyEffortStatsResponse_DailyStats | PlainMessage<GetQuizDailyEffortStatsResponse_DailyStats> | undefined, b: GetQuizDailyEffortStatsResponse_DailyStats | PlainMessage<GetQuizDailyEffortStatsResponse_DailyStats> | undefined): boolean {
    return proto3.util.equals(GetQuizDailyEffortStatsResponse_DailyStats, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizRecentStatsResponse
 */
export class GetQuizRecentStatsResponse extends Message<GetQuizRecentStatsResponse> {
  /**
   * @generated from field: int32 correct_answers = 1;
   */
  correctAnswers = 0;

  /**
   * @generated from field: int32 wrong_answers = 2;
   */
  wrongAnswers = 0;

  /**
   * @generated from field: google.protobuf.Duration answering_time = 3;
   */
  answeringTime?: Duration;

  constructor(data?: PartialMessage<GetQuizRecentStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizRecentStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "correct_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "wrong_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "answering_time", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizRecentStatsResponse {
    return new GetQuizRecentStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizRecentStatsResponse {
    return new GetQuizRecentStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizRecentStatsResponse {
    return new GetQuizRecentStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizRecentStatsResponse | PlainMessage<GetQuizRecentStatsResponse> | undefined, b: GetQuizRecentStatsResponse | PlainMessage<GetQuizRecentStatsResponse> | undefined): boolean {
    return proto3.util.equals(GetQuizRecentStatsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizDailyRepetitionStatsRequest
 */
export class GetQuizDailyRepetitionStatsRequest extends Message<GetQuizDailyRepetitionStatsRequest> {
  /**
   * @generated from field: int32 utc_offset = 1;
   */
  utcOffset = 0;

  constructor(data?: PartialMessage<GetQuizDailyRepetitionStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizDailyRepetitionStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "utc_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizDailyRepetitionStatsRequest {
    return new GetQuizDailyRepetitionStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizDailyRepetitionStatsRequest {
    return new GetQuizDailyRepetitionStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizDailyRepetitionStatsRequest {
    return new GetQuizDailyRepetitionStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizDailyRepetitionStatsRequest | PlainMessage<GetQuizDailyRepetitionStatsRequest> | undefined, b: GetQuizDailyRepetitionStatsRequest | PlainMessage<GetQuizDailyRepetitionStatsRequest> | undefined): boolean {
    return proto3.util.equals(GetQuizDailyRepetitionStatsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizDailyRepetitionStatsResponse
 */
export class GetQuizDailyRepetitionStatsResponse extends Message<GetQuizDailyRepetitionStatsResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.GetQuizDailyRepetitionStatsResponse.DailyProgress daily_progress_by_date = 1;
   */
  dailyProgressByDate: GetQuizDailyRepetitionStatsResponse_DailyProgress[] = [];

  constructor(data?: PartialMessage<GetQuizDailyRepetitionStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizDailyRepetitionStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "daily_progress_by_date", kind: "message", T: GetQuizDailyRepetitionStatsResponse_DailyProgress, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizDailyRepetitionStatsResponse {
    return new GetQuizDailyRepetitionStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizDailyRepetitionStatsResponse {
    return new GetQuizDailyRepetitionStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizDailyRepetitionStatsResponse {
    return new GetQuizDailyRepetitionStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizDailyRepetitionStatsResponse | PlainMessage<GetQuizDailyRepetitionStatsResponse> | undefined, b: GetQuizDailyRepetitionStatsResponse | PlainMessage<GetQuizDailyRepetitionStatsResponse> | undefined): boolean {
    return proto3.util.equals(GetQuizDailyRepetitionStatsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetQuizDailyRepetitionStatsResponse.DailyProgress
 */
export class GetQuizDailyRepetitionStatsResponse_DailyProgress extends Message<GetQuizDailyRepetitionStatsResponse_DailyProgress> {
  /**
   * @generated from field: string date = 1;
   */
  date = "";

  /**
   * @generated from field: float learned_ratio = 2;
   */
  learnedRatio = 0;

  /**
   * @generated from field: float needs_review_ratio = 3;
   */
  needsReviewRatio = 0;

  constructor(data?: PartialMessage<GetQuizDailyRepetitionStatsResponse_DailyProgress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetQuizDailyRepetitionStatsResponse.DailyProgress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "learned_ratio", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "needs_review_ratio", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuizDailyRepetitionStatsResponse_DailyProgress {
    return new GetQuizDailyRepetitionStatsResponse_DailyProgress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuizDailyRepetitionStatsResponse_DailyProgress {
    return new GetQuizDailyRepetitionStatsResponse_DailyProgress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuizDailyRepetitionStatsResponse_DailyProgress {
    return new GetQuizDailyRepetitionStatsResponse_DailyProgress().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuizDailyRepetitionStatsResponse_DailyProgress | PlainMessage<GetQuizDailyRepetitionStatsResponse_DailyProgress> | undefined, b: GetQuizDailyRepetitionStatsResponse_DailyProgress | PlainMessage<GetQuizDailyRepetitionStatsResponse_DailyProgress> | undefined): boolean {
    return proto3.util.equals(GetQuizDailyRepetitionStatsResponse_DailyProgress, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetWellKnownQuizRequest
 */
export class GetWellKnownQuizRequest extends Message<GetWellKnownQuizRequest> {
  /**
   * @generated from field: augmedi.quiz.WellKnownQuizId id = 1;
   */
  id = WellKnownQuizId.UNKNOWN;

  constructor(data?: PartialMessage<GetWellKnownQuizRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetWellKnownQuizRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "enum", T: proto3.getEnumType(WellKnownQuizId) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWellKnownQuizRequest {
    return new GetWellKnownQuizRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWellKnownQuizRequest {
    return new GetWellKnownQuizRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWellKnownQuizRequest {
    return new GetWellKnownQuizRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetWellKnownQuizRequest | PlainMessage<GetWellKnownQuizRequest> | undefined, b: GetWellKnownQuizRequest | PlainMessage<GetWellKnownQuizRequest> | undefined): boolean {
    return proto3.util.equals(GetWellKnownQuizRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetWellKnownQuizResponse
 */
export class GetWellKnownQuizResponse extends Message<GetWellKnownQuizResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.QuizItem quiz_items = 1;
   */
  quizItems: QuizItem[] = [];

  constructor(data?: PartialMessage<GetWellKnownQuizResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetWellKnownQuizResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quiz_items", kind: "message", T: QuizItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWellKnownQuizResponse {
    return new GetWellKnownQuizResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWellKnownQuizResponse {
    return new GetWellKnownQuizResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWellKnownQuizResponse {
    return new GetWellKnownQuizResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetWellKnownQuizResponse | PlainMessage<GetWellKnownQuizResponse> | undefined, b: GetWellKnownQuizResponse | PlainMessage<GetWellKnownQuizResponse> | undefined): boolean {
    return proto3.util.equals(GetWellKnownQuizResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetCurriculumRequest
 */
export class GetCurriculumRequest extends Message<GetCurriculumRequest> {
  constructor(data?: PartialMessage<GetCurriculumRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetCurriculumRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCurriculumRequest {
    return new GetCurriculumRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCurriculumRequest {
    return new GetCurriculumRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCurriculumRequest {
    return new GetCurriculumRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCurriculumRequest | PlainMessage<GetCurriculumRequest> | undefined, b: GetCurriculumRequest | PlainMessage<GetCurriculumRequest> | undefined): boolean {
    return proto3.util.equals(GetCurriculumRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UpdateCurriculumRequest
 */
export class UpdateCurriculumRequest extends Message<UpdateCurriculumRequest> {
  /**
   * @generated from field: augmedi.quiz.Curriculum curriculum = 1;
   */
  curriculum?: Curriculum;

  constructor(data?: PartialMessage<UpdateCurriculumRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UpdateCurriculumRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "curriculum", kind: "message", T: Curriculum },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCurriculumRequest {
    return new UpdateCurriculumRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCurriculumRequest {
    return new UpdateCurriculumRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCurriculumRequest {
    return new UpdateCurriculumRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCurriculumRequest | PlainMessage<UpdateCurriculumRequest> | undefined, b: UpdateCurriculumRequest | PlainMessage<UpdateCurriculumRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCurriculumRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FreezeCurriculumRequest
 */
export class FreezeCurriculumRequest extends Message<FreezeCurriculumRequest> {
  constructor(data?: PartialMessage<FreezeCurriculumRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FreezeCurriculumRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FreezeCurriculumRequest {
    return new FreezeCurriculumRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FreezeCurriculumRequest {
    return new FreezeCurriculumRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FreezeCurriculumRequest {
    return new FreezeCurriculumRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FreezeCurriculumRequest | PlainMessage<FreezeCurriculumRequest> | undefined, b: FreezeCurriculumRequest | PlainMessage<FreezeCurriculumRequest> | undefined): boolean {
    return proto3.util.equals(FreezeCurriculumRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FreezeCurriculumResponse
 */
export class FreezeCurriculumResponse extends Message<FreezeCurriculumResponse> {
  constructor(data?: PartialMessage<FreezeCurriculumResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FreezeCurriculumResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FreezeCurriculumResponse {
    return new FreezeCurriculumResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FreezeCurriculumResponse {
    return new FreezeCurriculumResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FreezeCurriculumResponse {
    return new FreezeCurriculumResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FreezeCurriculumResponse | PlainMessage<FreezeCurriculumResponse> | undefined, b: FreezeCurriculumResponse | PlainMessage<FreezeCurriculumResponse> | undefined): boolean {
    return proto3.util.equals(FreezeCurriculumResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AutocompleteTagsForCurriculumRequest
 */
export class AutocompleteTagsForCurriculumRequest extends Message<AutocompleteTagsForCurriculumRequest> {
  /**
   * @generated from field: string prefix = 1;
   */
  prefix = "";

  /**
   * @generated from field: string generator_id = 2;
   */
  generatorId = "";

  constructor(data?: PartialMessage<AutocompleteTagsForCurriculumRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AutocompleteTagsForCurriculumRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "generator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteTagsForCurriculumRequest {
    return new AutocompleteTagsForCurriculumRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteTagsForCurriculumRequest {
    return new AutocompleteTagsForCurriculumRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteTagsForCurriculumRequest {
    return new AutocompleteTagsForCurriculumRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteTagsForCurriculumRequest | PlainMessage<AutocompleteTagsForCurriculumRequest> | undefined, b: AutocompleteTagsForCurriculumRequest | PlainMessage<AutocompleteTagsForCurriculumRequest> | undefined): boolean {
    return proto3.util.equals(AutocompleteTagsForCurriculumRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AutocompleteTagsForCurriculumResponse
 */
export class AutocompleteTagsForCurriculumResponse extends Message<AutocompleteTagsForCurriculumResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.AutocompleteTagsForCurriculumResponse.TagWithGenerator tags = 1;
   */
  tags: AutocompleteTagsForCurriculumResponse_TagWithGenerator[] = [];

  constructor(data?: PartialMessage<AutocompleteTagsForCurriculumResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AutocompleteTagsForCurriculumResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tags", kind: "message", T: AutocompleteTagsForCurriculumResponse_TagWithGenerator, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteTagsForCurriculumResponse {
    return new AutocompleteTagsForCurriculumResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteTagsForCurriculumResponse {
    return new AutocompleteTagsForCurriculumResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteTagsForCurriculumResponse {
    return new AutocompleteTagsForCurriculumResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteTagsForCurriculumResponse | PlainMessage<AutocompleteTagsForCurriculumResponse> | undefined, b: AutocompleteTagsForCurriculumResponse | PlainMessage<AutocompleteTagsForCurriculumResponse> | undefined): boolean {
    return proto3.util.equals(AutocompleteTagsForCurriculumResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AutocompleteTagsForCurriculumResponse.TagWithGenerator
 */
export class AutocompleteTagsForCurriculumResponse_TagWithGenerator extends Message<AutocompleteTagsForCurriculumResponse_TagWithGenerator> {
  /**
   * @generated from field: string tag = 1;
   */
  tag = "";

  /**
   * @generated from field: string generator_id = 2;
   */
  generatorId = "";

  constructor(data?: PartialMessage<AutocompleteTagsForCurriculumResponse_TagWithGenerator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AutocompleteTagsForCurriculumResponse.TagWithGenerator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "generator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteTagsForCurriculumResponse_TagWithGenerator {
    return new AutocompleteTagsForCurriculumResponse_TagWithGenerator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteTagsForCurriculumResponse_TagWithGenerator {
    return new AutocompleteTagsForCurriculumResponse_TagWithGenerator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteTagsForCurriculumResponse_TagWithGenerator {
    return new AutocompleteTagsForCurriculumResponse_TagWithGenerator().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteTagsForCurriculumResponse_TagWithGenerator | PlainMessage<AutocompleteTagsForCurriculumResponse_TagWithGenerator> | undefined, b: AutocompleteTagsForCurriculumResponse_TagWithGenerator | PlainMessage<AutocompleteTagsForCurriculumResponse_TagWithGenerator> | undefined): boolean {
    return proto3.util.equals(AutocompleteTagsForCurriculumResponse_TagWithGenerator, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetFrozenCurriculumRequest
 */
export class GetFrozenCurriculumRequest extends Message<GetFrozenCurriculumRequest> {
  constructor(data?: PartialMessage<GetFrozenCurriculumRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetFrozenCurriculumRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFrozenCurriculumRequest {
    return new GetFrozenCurriculumRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFrozenCurriculumRequest {
    return new GetFrozenCurriculumRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFrozenCurriculumRequest {
    return new GetFrozenCurriculumRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFrozenCurriculumRequest | PlainMessage<GetFrozenCurriculumRequest> | undefined, b: GetFrozenCurriculumRequest | PlainMessage<GetFrozenCurriculumRequest> | undefined): boolean {
    return proto3.util.equals(GetFrozenCurriculumRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListGeneratorsRequest
 */
export class ListGeneratorsRequest extends Message<ListGeneratorsRequest> {
  constructor(data?: PartialMessage<ListGeneratorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListGeneratorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGeneratorsRequest {
    return new ListGeneratorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGeneratorsRequest {
    return new ListGeneratorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGeneratorsRequest {
    return new ListGeneratorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListGeneratorsRequest | PlainMessage<ListGeneratorsRequest> | undefined, b: ListGeneratorsRequest | PlainMessage<ListGeneratorsRequest> | undefined): boolean {
    return proto3.util.equals(ListGeneratorsRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListGeneratorsResponse
 */
export class ListGeneratorsResponse extends Message<ListGeneratorsResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.GeneratorWithStatus generators = 1;
   */
  generators: GeneratorWithStatus[] = [];

  constructor(data?: PartialMessage<ListGeneratorsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListGeneratorsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "generators", kind: "message", T: GeneratorWithStatus, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGeneratorsResponse {
    return new ListGeneratorsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGeneratorsResponse {
    return new ListGeneratorsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGeneratorsResponse {
    return new ListGeneratorsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListGeneratorsResponse | PlainMessage<ListGeneratorsResponse> | undefined, b: ListGeneratorsResponse | PlainMessage<ListGeneratorsResponse> | undefined): boolean {
    return proto3.util.equals(ListGeneratorsResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ImportFromGeneratorRequest
 */
export class ImportFromGeneratorRequest extends Message<ImportFromGeneratorRequest> {
  /**
   * The stream of requests must be:
   *
   *   - Exactly one InitializeItem
   *   - Zero or more of the following:
   *     - Exactly one IntroductionGroupItem
   *     - One or more QuestionItem
   *
   * Questions will automatically be assigned to the introduction group that was
   * sent before them. The order of questions within an introduction group will
   * be the same as in the stream.
   *
   * @generated from oneof augmedi.quiz.ImportFromGeneratorRequest.item
   */
  item: {
    /**
     * @generated from field: augmedi.quiz.ImportFromGeneratorRequest.InitializeItem initialize = 1;
     */
    value: ImportFromGeneratorRequest_InitializeItem;
    case: "initialize";
  } | {
    /**
     * @generated from field: augmedi.quiz.ImportFromGeneratorRequest.IntroductionGroupItem introduction_group = 2;
     */
    value: ImportFromGeneratorRequest_IntroductionGroupItem;
    case: "introductionGroup";
  } | {
    /**
     * @generated from field: augmedi.quiz.ImportFromGeneratorRequest.QuestionItem question = 3;
     */
    value: ImportFromGeneratorRequest_QuestionItem;
    case: "question";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ImportFromGeneratorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ImportFromGeneratorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "initialize", kind: "message", T: ImportFromGeneratorRequest_InitializeItem, oneof: "item" },
    { no: 2, name: "introduction_group", kind: "message", T: ImportFromGeneratorRequest_IntroductionGroupItem, oneof: "item" },
    { no: 3, name: "question", kind: "message", T: ImportFromGeneratorRequest_QuestionItem, oneof: "item" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportFromGeneratorRequest {
    return new ImportFromGeneratorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportFromGeneratorRequest {
    return new ImportFromGeneratorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportFromGeneratorRequest {
    return new ImportFromGeneratorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImportFromGeneratorRequest | PlainMessage<ImportFromGeneratorRequest> | undefined, b: ImportFromGeneratorRequest | PlainMessage<ImportFromGeneratorRequest> | undefined): boolean {
    return proto3.util.equals(ImportFromGeneratorRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ImportFromGeneratorRequest.InitializeItem
 */
export class ImportFromGeneratorRequest_InitializeItem extends Message<ImportFromGeneratorRequest_InitializeItem> {
  /**
   * @generated from field: string generator_id = 1;
   */
  generatorId = "";

  constructor(data?: PartialMessage<ImportFromGeneratorRequest_InitializeItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ImportFromGeneratorRequest.InitializeItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "generator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportFromGeneratorRequest_InitializeItem {
    return new ImportFromGeneratorRequest_InitializeItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportFromGeneratorRequest_InitializeItem {
    return new ImportFromGeneratorRequest_InitializeItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportFromGeneratorRequest_InitializeItem {
    return new ImportFromGeneratorRequest_InitializeItem().fromJsonString(jsonString, options);
  }

  static equals(a: ImportFromGeneratorRequest_InitializeItem | PlainMessage<ImportFromGeneratorRequest_InitializeItem> | undefined, b: ImportFromGeneratorRequest_InitializeItem | PlainMessage<ImportFromGeneratorRequest_InitializeItem> | undefined): boolean {
    return proto3.util.equals(ImportFromGeneratorRequest_InitializeItem, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ImportFromGeneratorRequest.IntroductionGroupItem
 */
export class ImportFromGeneratorRequest_IntroductionGroupItem extends Message<ImportFromGeneratorRequest_IntroductionGroupItem> {
  /**
   * IntroductionGroup.id and IntroductionGroup.Item.id must be empty
   *
   * @generated from field: augmedi.quiz.IntroductionGroup introduction_group = 1;
   */
  introductionGroup?: IntroductionGroup;

  /**
   * @generated from field: string tag = 2;
   */
  tag = "";

  constructor(data?: PartialMessage<ImportFromGeneratorRequest_IntroductionGroupItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ImportFromGeneratorRequest.IntroductionGroupItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "introduction_group", kind: "message", T: IntroductionGroup },
    { no: 2, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportFromGeneratorRequest_IntroductionGroupItem {
    return new ImportFromGeneratorRequest_IntroductionGroupItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportFromGeneratorRequest_IntroductionGroupItem {
    return new ImportFromGeneratorRequest_IntroductionGroupItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportFromGeneratorRequest_IntroductionGroupItem {
    return new ImportFromGeneratorRequest_IntroductionGroupItem().fromJsonString(jsonString, options);
  }

  static equals(a: ImportFromGeneratorRequest_IntroductionGroupItem | PlainMessage<ImportFromGeneratorRequest_IntroductionGroupItem> | undefined, b: ImportFromGeneratorRequest_IntroductionGroupItem | PlainMessage<ImportFromGeneratorRequest_IntroductionGroupItem> | undefined): boolean {
    return proto3.util.equals(ImportFromGeneratorRequest_IntroductionGroupItem, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ImportFromGeneratorRequest.QuestionItem
 */
export class ImportFromGeneratorRequest_QuestionItem extends Message<ImportFromGeneratorRequest_QuestionItem> {
  /**
   * ConcreteQuestion.id must be empty
   *
   * @generated from field: augmedi.quiz.ConcreteQuestion concrete_question = 1;
   */
  concreteQuestion?: ConcreteQuestion;

  /**
   * @generated from field: repeated augmedi.quiz.KnowledgeBit knowledge_bits = 2;
   */
  knowledgeBits: KnowledgeBit[] = [];

  constructor(data?: PartialMessage<ImportFromGeneratorRequest_QuestionItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ImportFromGeneratorRequest.QuestionItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "concrete_question", kind: "message", T: ConcreteQuestion },
    { no: 2, name: "knowledge_bits", kind: "message", T: KnowledgeBit, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportFromGeneratorRequest_QuestionItem {
    return new ImportFromGeneratorRequest_QuestionItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportFromGeneratorRequest_QuestionItem {
    return new ImportFromGeneratorRequest_QuestionItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportFromGeneratorRequest_QuestionItem {
    return new ImportFromGeneratorRequest_QuestionItem().fromJsonString(jsonString, options);
  }

  static equals(a: ImportFromGeneratorRequest_QuestionItem | PlainMessage<ImportFromGeneratorRequest_QuestionItem> | undefined, b: ImportFromGeneratorRequest_QuestionItem | PlainMessage<ImportFromGeneratorRequest_QuestionItem> | undefined): boolean {
    return proto3.util.equals(ImportFromGeneratorRequest_QuestionItem, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ImportFromGeneratorResponse
 */
export class ImportFromGeneratorResponse extends Message<ImportFromGeneratorResponse> {
  constructor(data?: PartialMessage<ImportFromGeneratorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ImportFromGeneratorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportFromGeneratorResponse {
    return new ImportFromGeneratorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportFromGeneratorResponse {
    return new ImportFromGeneratorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportFromGeneratorResponse {
    return new ImportFromGeneratorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ImportFromGeneratorResponse | PlainMessage<ImportFromGeneratorResponse> | undefined, b: ImportFromGeneratorResponse | PlainMessage<ImportFromGeneratorResponse> | undefined): boolean {
    return proto3.util.equals(ImportFromGeneratorResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListApproachesRequest
 */
export class ListApproachesRequest extends Message<ListApproachesRequest> {
  constructor(data?: PartialMessage<ListApproachesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListApproachesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListApproachesRequest {
    return new ListApproachesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListApproachesRequest {
    return new ListApproachesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListApproachesRequest {
    return new ListApproachesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListApproachesRequest | PlainMessage<ListApproachesRequest> | undefined, b: ListApproachesRequest | PlainMessage<ListApproachesRequest> | undefined): boolean {
    return proto3.util.equals(ListApproachesRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListApproachesResponse
 */
export class ListApproachesResponse extends Message<ListApproachesResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.Approach approaches = 1;
   */
  approaches: Approach[] = [];

  constructor(data?: PartialMessage<ListApproachesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListApproachesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "approaches", kind: "message", T: Approach, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListApproachesResponse {
    return new ListApproachesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListApproachesResponse {
    return new ListApproachesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListApproachesResponse {
    return new ListApproachesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListApproachesResponse | PlainMessage<ListApproachesResponse> | undefined, b: ListApproachesResponse | PlainMessage<ListApproachesResponse> | undefined): boolean {
    return proto3.util.equals(ListApproachesResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetApproachRequest
 */
export class GetApproachRequest extends Message<GetApproachRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetApproachRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetApproachRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetApproachRequest {
    return new GetApproachRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetApproachRequest {
    return new GetApproachRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetApproachRequest {
    return new GetApproachRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetApproachRequest | PlainMessage<GetApproachRequest> | undefined, b: GetApproachRequest | PlainMessage<GetApproachRequest> | undefined): boolean {
    return proto3.util.equals(GetApproachRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.CreateApproachRequest
 */
export class CreateApproachRequest extends Message<CreateApproachRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<CreateApproachRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.CreateApproachRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateApproachRequest {
    return new CreateApproachRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateApproachRequest {
    return new CreateApproachRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateApproachRequest {
    return new CreateApproachRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateApproachRequest | PlainMessage<CreateApproachRequest> | undefined, b: CreateApproachRequest | PlainMessage<CreateApproachRequest> | undefined): boolean {
    return proto3.util.equals(CreateApproachRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UpdateApproachRequest
 */
export class UpdateApproachRequest extends Message<UpdateApproachRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated string step_order = 3;
   */
  stepOrder: string[] = [];

  constructor(data?: PartialMessage<UpdateApproachRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UpdateApproachRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "step_order", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateApproachRequest {
    return new UpdateApproachRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateApproachRequest {
    return new UpdateApproachRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateApproachRequest {
    return new UpdateApproachRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateApproachRequest | PlainMessage<UpdateApproachRequest> | undefined, b: UpdateApproachRequest | PlainMessage<UpdateApproachRequest> | undefined): boolean {
    return proto3.util.equals(UpdateApproachRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteApproachRequest
 */
export class DeleteApproachRequest extends Message<DeleteApproachRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteApproachRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteApproachRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteApproachRequest {
    return new DeleteApproachRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteApproachRequest {
    return new DeleteApproachRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteApproachRequest {
    return new DeleteApproachRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteApproachRequest | PlainMessage<DeleteApproachRequest> | undefined, b: DeleteApproachRequest | PlainMessage<DeleteApproachRequest> | undefined): boolean {
    return proto3.util.equals(DeleteApproachRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteApproachResponse
 */
export class DeleteApproachResponse extends Message<DeleteApproachResponse> {
  constructor(data?: PartialMessage<DeleteApproachResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteApproachResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteApproachResponse {
    return new DeleteApproachResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteApproachResponse {
    return new DeleteApproachResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteApproachResponse {
    return new DeleteApproachResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteApproachResponse | PlainMessage<DeleteApproachResponse> | undefined, b: DeleteApproachResponse | PlainMessage<DeleteApproachResponse> | undefined): boolean {
    return proto3.util.equals(DeleteApproachResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.CreateApproachStepRequest
 */
export class CreateApproachStepRequest extends Message<CreateApproachStepRequest> {
  /**
   * @generated from field: string approach_id = 1;
   */
  approachId = "";

  constructor(data?: PartialMessage<CreateApproachStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.CreateApproachStepRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "approach_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateApproachStepRequest {
    return new CreateApproachStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateApproachStepRequest {
    return new CreateApproachStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateApproachStepRequest {
    return new CreateApproachStepRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateApproachStepRequest | PlainMessage<CreateApproachStepRequest> | undefined, b: CreateApproachStepRequest | PlainMessage<CreateApproachStepRequest> | undefined): boolean {
    return proto3.util.equals(CreateApproachStepRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.UpdateApproachStepRequest
 */
export class UpdateApproachStepRequest extends Message<UpdateApproachStepRequest> {
  /**
   * @generated from field: augmedi.quiz.ApproachStep step = 1;
   */
  step?: ApproachStep;

  constructor(data?: PartialMessage<UpdateApproachStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.UpdateApproachStepRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "step", kind: "message", T: ApproachStep },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateApproachStepRequest {
    return new UpdateApproachStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateApproachStepRequest {
    return new UpdateApproachStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateApproachStepRequest {
    return new UpdateApproachStepRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateApproachStepRequest | PlainMessage<UpdateApproachStepRequest> | undefined, b: UpdateApproachStepRequest | PlainMessage<UpdateApproachStepRequest> | undefined): boolean {
    return proto3.util.equals(UpdateApproachStepRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteApproachStepRequest
 */
export class DeleteApproachStepRequest extends Message<DeleteApproachStepRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteApproachStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteApproachStepRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteApproachStepRequest {
    return new DeleteApproachStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteApproachStepRequest {
    return new DeleteApproachStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteApproachStepRequest {
    return new DeleteApproachStepRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteApproachStepRequest | PlainMessage<DeleteApproachStepRequest> | undefined, b: DeleteApproachStepRequest | PlainMessage<DeleteApproachStepRequest> | undefined): boolean {
    return proto3.util.equals(DeleteApproachStepRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteApproachStepResponse
 */
export class DeleteApproachStepResponse extends Message<DeleteApproachStepResponse> {
  constructor(data?: PartialMessage<DeleteApproachStepResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteApproachStepResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteApproachStepResponse {
    return new DeleteApproachStepResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteApproachStepResponse {
    return new DeleteApproachStepResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteApproachStepResponse {
    return new DeleteApproachStepResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteApproachStepResponse | PlainMessage<DeleteApproachStepResponse> | undefined, b: DeleteApproachStepResponse | PlainMessage<DeleteApproachStepResponse> | undefined): boolean {
    return proto3.util.equals(DeleteApproachStepResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FreezeApproachRequest
 */
export class FreezeApproachRequest extends Message<FreezeApproachRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<FreezeApproachRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FreezeApproachRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FreezeApproachRequest {
    return new FreezeApproachRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FreezeApproachRequest {
    return new FreezeApproachRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FreezeApproachRequest {
    return new FreezeApproachRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FreezeApproachRequest | PlainMessage<FreezeApproachRequest> | undefined, b: FreezeApproachRequest | PlainMessage<FreezeApproachRequest> | undefined): boolean {
    return proto3.util.equals(FreezeApproachRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.FreezeApproachResponse
 */
export class FreezeApproachResponse extends Message<FreezeApproachResponse> {
  /**
   * @generated from field: string frozen_approach_id = 1;
   */
  frozenApproachId = "";

  constructor(data?: PartialMessage<FreezeApproachResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.FreezeApproachResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "frozen_approach_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FreezeApproachResponse {
    return new FreezeApproachResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FreezeApproachResponse {
    return new FreezeApproachResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FreezeApproachResponse {
    return new FreezeApproachResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FreezeApproachResponse | PlainMessage<FreezeApproachResponse> | undefined, b: FreezeApproachResponse | PlainMessage<FreezeApproachResponse> | undefined): boolean {
    return proto3.util.equals(FreezeApproachResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.GetFrozenApproachRequest
 */
export class GetFrozenApproachRequest extends Message<GetFrozenApproachRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetFrozenApproachRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.GetFrozenApproachRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFrozenApproachRequest {
    return new GetFrozenApproachRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFrozenApproachRequest {
    return new GetFrozenApproachRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFrozenApproachRequest {
    return new GetFrozenApproachRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFrozenApproachRequest | PlainMessage<GetFrozenApproachRequest> | undefined, b: GetFrozenApproachRequest | PlainMessage<GetFrozenApproachRequest> | undefined): boolean {
    return proto3.util.equals(GetFrozenApproachRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AugmediMeshWithLabelMasksGltfExtensionContent
 */
export class AugmediMeshWithLabelMasksGltfExtensionContent extends Message<AugmediMeshWithLabelMasksGltfExtensionContent> {
  /**
   * @generated from field: map<string, augmedi.quiz.AugmediMeshWithLabelMasksGltfExtensionContent.LabelMask> label_masks = 1;
   */
  labelMasks: { [key: string]: AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask } = {};

  constructor(data?: PartialMessage<AugmediMeshWithLabelMasksGltfExtensionContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AugmediMeshWithLabelMasksGltfExtensionContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "label_masks", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AugmediMeshWithLabelMasksGltfExtensionContent {
    return new AugmediMeshWithLabelMasksGltfExtensionContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AugmediMeshWithLabelMasksGltfExtensionContent {
    return new AugmediMeshWithLabelMasksGltfExtensionContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AugmediMeshWithLabelMasksGltfExtensionContent {
    return new AugmediMeshWithLabelMasksGltfExtensionContent().fromJsonString(jsonString, options);
  }

  static equals(a: AugmediMeshWithLabelMasksGltfExtensionContent | PlainMessage<AugmediMeshWithLabelMasksGltfExtensionContent> | undefined, b: AugmediMeshWithLabelMasksGltfExtensionContent | PlainMessage<AugmediMeshWithLabelMasksGltfExtensionContent> | undefined): boolean {
    return proto3.util.equals(AugmediMeshWithLabelMasksGltfExtensionContent, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AugmediMeshWithLabelMasksGltfExtensionContent.LabelMask
 */
export class AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask extends Message<AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask> {
  /**
   * @generated from field: uint32 texture_index = 1;
   */
  textureIndex = 0;

  /**
   * @generated from field: uint32 texture_bit = 2;
   */
  textureBit = 0;

  constructor(data?: PartialMessage<AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AugmediMeshWithLabelMasksGltfExtensionContent.LabelMask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "texture_index", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "texture_bit", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask {
    return new AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask {
    return new AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask {
    return new AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask().fromJsonString(jsonString, options);
  }

  static equals(a: AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask | PlainMessage<AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask> | undefined, b: AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask | PlainMessage<AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask> | undefined): boolean {
    return proto3.util.equals(AugmediMeshWithLabelMasksGltfExtensionContent_LabelMask, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.PartitionDiskCacheHeader
 */
export class PartitionDiskCacheHeader extends Message<PartitionDiskCacheHeader> {
  /**
   * @generated from field: bool is_gltf_binary = 1;
   */
  isGltfBinary = false;

  constructor(data?: PartialMessage<PartitionDiskCacheHeader>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.PartitionDiskCacheHeader";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_gltf_binary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PartitionDiskCacheHeader {
    return new PartitionDiskCacheHeader().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PartitionDiskCacheHeader {
    return new PartitionDiskCacheHeader().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PartitionDiskCacheHeader {
    return new PartitionDiskCacheHeader().fromJsonString(jsonString, options);
  }

  static equals(a: PartitionDiskCacheHeader | PlainMessage<PartitionDiskCacheHeader> | undefined, b: PartitionDiskCacheHeader | PlainMessage<PartitionDiskCacheHeader> | undefined): boolean {
    return proto3.util.equals(PartitionDiskCacheHeader, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AddEdgeRequest
 */
export class AddEdgeRequest extends Message<AddEdgeRequest> {
  /**
   * @generated from field: string upstream_structure_id = 1;
   */
  upstreamStructureId = "";

  /**
   * @generated from field: string downstream_structure_id = 2;
   */
  downstreamStructureId = "";

  /**
   * @generated from field: string media_item_id = 3;
   */
  mediaItemId = "";

  /**
   * @generated from field: string notes = 4;
   */
  notes = "";

  constructor(data?: PartialMessage<AddEdgeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AddEdgeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upstream_structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "downstream_structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "media_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddEdgeRequest {
    return new AddEdgeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddEdgeRequest {
    return new AddEdgeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddEdgeRequest {
    return new AddEdgeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddEdgeRequest | PlainMessage<AddEdgeRequest> | undefined, b: AddEdgeRequest | PlainMessage<AddEdgeRequest> | undefined): boolean {
    return proto3.util.equals(AddEdgeRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.AddEdgeResponse
 */
export class AddEdgeResponse extends Message<AddEdgeResponse> {
  constructor(data?: PartialMessage<AddEdgeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.AddEdgeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddEdgeResponse {
    return new AddEdgeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddEdgeResponse {
    return new AddEdgeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddEdgeResponse {
    return new AddEdgeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddEdgeResponse | PlainMessage<AddEdgeResponse> | undefined, b: AddEdgeResponse | PlainMessage<AddEdgeResponse> | undefined): boolean {
    return proto3.util.equals(AddEdgeResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteEdgeRequest
 */
export class DeleteEdgeRequest extends Message<DeleteEdgeRequest> {
  /**
   * @generated from field: string upstream_structure_id = 1;
   */
  upstreamStructureId = "";

  /**
   * @generated from field: string downstream_structure_id = 2;
   */
  downstreamStructureId = "";

  constructor(data?: PartialMessage<DeleteEdgeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteEdgeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upstream_structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "downstream_structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteEdgeRequest {
    return new DeleteEdgeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteEdgeRequest {
    return new DeleteEdgeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteEdgeRequest {
    return new DeleteEdgeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteEdgeRequest | PlainMessage<DeleteEdgeRequest> | undefined, b: DeleteEdgeRequest | PlainMessage<DeleteEdgeRequest> | undefined): boolean {
    return proto3.util.equals(DeleteEdgeRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.DeleteEdgeResponse
 */
export class DeleteEdgeResponse extends Message<DeleteEdgeResponse> {
  constructor(data?: PartialMessage<DeleteEdgeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.DeleteEdgeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteEdgeResponse {
    return new DeleteEdgeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteEdgeResponse {
    return new DeleteEdgeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteEdgeResponse {
    return new DeleteEdgeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteEdgeResponse | PlainMessage<DeleteEdgeResponse> | undefined, b: DeleteEdgeResponse | PlainMessage<DeleteEdgeResponse> | undefined): boolean {
    return proto3.util.equals(DeleteEdgeResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListDownstreamStructuresRequest
 */
export class ListDownstreamStructuresRequest extends Message<ListDownstreamStructuresRequest> {
  /**
   * @generated from field: string upstream_structure_id = 1;
   */
  upstreamStructureId = "";

  /**
   * if true, returns structures estimated by atlas instead of user annotated structures
   *
   * @generated from field: bool infer_from_atlas = 2;
   */
  inferFromAtlas = false;

  constructor(data?: PartialMessage<ListDownstreamStructuresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListDownstreamStructuresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upstream_structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "infer_from_atlas", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDownstreamStructuresRequest {
    return new ListDownstreamStructuresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDownstreamStructuresRequest {
    return new ListDownstreamStructuresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDownstreamStructuresRequest {
    return new ListDownstreamStructuresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDownstreamStructuresRequest | PlainMessage<ListDownstreamStructuresRequest> | undefined, b: ListDownstreamStructuresRequest | PlainMessage<ListDownstreamStructuresRequest> | undefined): boolean {
    return proto3.util.equals(ListDownstreamStructuresRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListDownstreamStructuresResponse
 */
export class ListDownstreamStructuresResponse extends Message<ListDownstreamStructuresResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.ListDownstreamStructuresResponse.DownstreamStructure downstream_structures = 1;
   */
  downstreamStructures: ListDownstreamStructuresResponse_DownstreamStructure[] = [];

  constructor(data?: PartialMessage<ListDownstreamStructuresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListDownstreamStructuresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "downstream_structures", kind: "message", T: ListDownstreamStructuresResponse_DownstreamStructure, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDownstreamStructuresResponse {
    return new ListDownstreamStructuresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDownstreamStructuresResponse {
    return new ListDownstreamStructuresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDownstreamStructuresResponse {
    return new ListDownstreamStructuresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDownstreamStructuresResponse | PlainMessage<ListDownstreamStructuresResponse> | undefined, b: ListDownstreamStructuresResponse | PlainMessage<ListDownstreamStructuresResponse> | undefined): boolean {
    return proto3.util.equals(ListDownstreamStructuresResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListDownstreamStructuresResponse.DownstreamStructure
 */
export class ListDownstreamStructuresResponse_DownstreamStructure extends Message<ListDownstreamStructuresResponse_DownstreamStructure> {
  /**
   * @generated from field: string downstream_structure_id = 1;
   */
  downstreamStructureId = "";

  /**
   * @generated from field: string notes = 2;
   */
  notes = "";

  constructor(data?: PartialMessage<ListDownstreamStructuresResponse_DownstreamStructure>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListDownstreamStructuresResponse.DownstreamStructure";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "downstream_structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDownstreamStructuresResponse_DownstreamStructure {
    return new ListDownstreamStructuresResponse_DownstreamStructure().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDownstreamStructuresResponse_DownstreamStructure {
    return new ListDownstreamStructuresResponse_DownstreamStructure().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDownstreamStructuresResponse_DownstreamStructure {
    return new ListDownstreamStructuresResponse_DownstreamStructure().fromJsonString(jsonString, options);
  }

  static equals(a: ListDownstreamStructuresResponse_DownstreamStructure | PlainMessage<ListDownstreamStructuresResponse_DownstreamStructure> | undefined, b: ListDownstreamStructuresResponse_DownstreamStructure | PlainMessage<ListDownstreamStructuresResponse_DownstreamStructure> | undefined): boolean {
    return proto3.util.equals(ListDownstreamStructuresResponse_DownstreamStructure, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListUpstreamStructuresRequest
 */
export class ListUpstreamStructuresRequest extends Message<ListUpstreamStructuresRequest> {
  /**
   * @generated from field: string media_item_id = 1;
   */
  mediaItemId = "";

  constructor(data?: PartialMessage<ListUpstreamStructuresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListUpstreamStructuresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUpstreamStructuresRequest {
    return new ListUpstreamStructuresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUpstreamStructuresRequest {
    return new ListUpstreamStructuresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUpstreamStructuresRequest {
    return new ListUpstreamStructuresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListUpstreamStructuresRequest | PlainMessage<ListUpstreamStructuresRequest> | undefined, b: ListUpstreamStructuresRequest | PlainMessage<ListUpstreamStructuresRequest> | undefined): boolean {
    return proto3.util.equals(ListUpstreamStructuresRequest, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListUpstreamStructuresResponse
 */
export class ListUpstreamStructuresResponse extends Message<ListUpstreamStructuresResponse> {
  /**
   * @generated from field: repeated augmedi.quiz.ListUpstreamStructuresResponse.UpstreamStructure upstream_structures = 1;
   */
  upstreamStructures: ListUpstreamStructuresResponse_UpstreamStructure[] = [];

  constructor(data?: PartialMessage<ListUpstreamStructuresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListUpstreamStructuresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upstream_structures", kind: "message", T: ListUpstreamStructuresResponse_UpstreamStructure, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUpstreamStructuresResponse {
    return new ListUpstreamStructuresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUpstreamStructuresResponse {
    return new ListUpstreamStructuresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUpstreamStructuresResponse {
    return new ListUpstreamStructuresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListUpstreamStructuresResponse | PlainMessage<ListUpstreamStructuresResponse> | undefined, b: ListUpstreamStructuresResponse | PlainMessage<ListUpstreamStructuresResponse> | undefined): boolean {
    return proto3.util.equals(ListUpstreamStructuresResponse, a, b);
  }
}

/**
 * @generated from message augmedi.quiz.ListUpstreamStructuresResponse.UpstreamStructure
 */
export class ListUpstreamStructuresResponse_UpstreamStructure extends Message<ListUpstreamStructuresResponse_UpstreamStructure> {
  /**
   * @generated from field: string upstream_structure_id = 1;
   */
  upstreamStructureId = "";

  constructor(data?: PartialMessage<ListUpstreamStructuresResponse_UpstreamStructure>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "augmedi.quiz.ListUpstreamStructuresResponse.UpstreamStructure";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upstream_structure_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUpstreamStructuresResponse_UpstreamStructure {
    return new ListUpstreamStructuresResponse_UpstreamStructure().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUpstreamStructuresResponse_UpstreamStructure {
    return new ListUpstreamStructuresResponse_UpstreamStructure().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUpstreamStructuresResponse_UpstreamStructure {
    return new ListUpstreamStructuresResponse_UpstreamStructure().fromJsonString(jsonString, options);
  }

  static equals(a: ListUpstreamStructuresResponse_UpstreamStructure | PlainMessage<ListUpstreamStructuresResponse_UpstreamStructure> | undefined, b: ListUpstreamStructuresResponse_UpstreamStructure | PlainMessage<ListUpstreamStructuresResponse_UpstreamStructure> | undefined): boolean {
    return proto3.util.equals(ListUpstreamStructuresResponse_UpstreamStructure, a, b);
  }
}

