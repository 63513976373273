import { quizConnectQuery } from "@augmedi/proto-gen";
import { assertDefined } from "@augmedi/type-utils";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { Container, Stack, Title } from "@mantine/core";
import { useParams } from "wouter";
import { AppLayout, useAppLayout } from "../logic/app-layout";
import { StatefulIntroductionGroupViewer } from "./StatefulIntroductionGroupViewer";
import { StatefulQuestionAsker } from "./StatefulQuestionAsker";

export const QuestionPage = () => {
  const { questionId } = useParams<{
    questionId: string;
  }>();
  const questionQuery = useSuspenseQuery(quizConnectQuery.getQuizItem, {
    id: questionId,
  });

  const quizItemContent = questionQuery.data.quizItem?.content;

  useAppLayout(AppLayout.FullscreenWithHeader);

  return (
    <>
      {quizItemContent?.case === "introductionGroup" && (
        <StatefulIntroductionGroupViewer
          introductionGroup={assertDefined(
            quizItemContent.value.introductionGroup,
          )}
          onConsumeAndSwitchToNextQuestion={() => {
            window.location.href = "/questions";
          }}
        />
      )}
      {quizItemContent?.case === "question" && (
        <StatefulQuestionAsker
          selectedQuestion={assertDefined(quizItemContent.value.question)}
          onAnswerQuestion={() => {}}
          onSwitchToNextQuestion={() => (window.location.href = "/questions")}
          maxAttempts={quizItemContent.value.retryPlan?.maxAttempts || 2}
          muteAudio={true}
        />
      )}
      {quizItemContent?.case === undefined && (
        <Container>
          <Stack mt="md">
            <Title>
              <i>These aren&apos;t the droids you&apos;re looking for...</i>
            </Title>
          </Stack>
        </Container>
      )}
    </>
  );
};
