import { atlasConnectQuery, atlasPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import { useQuery } from "@connectrpc/connect-query";
import { Button, CloseButton, Flex, Group, Paper, Text } from "@mantine/core";
import { createSpotlight, type SpotlightRootProps } from "@mantine/spotlight";
import { IconSearch } from "@tabler/icons-react";
import { StructureSearch } from "./StructureSearch";

const [structureSpotlightStore, structureSpotlight] = createSpotlight();

interface Props {
  structureId: string | undefined;
  onStructureIdChange: ((structureId: undefined, entry: undefined) => void) &
    ((
      structureId: string,
      entry: PlainMessage<atlasPb.NameSearchResponse_Entry>,
    ) => void);
  spotlightRootProps?: SpotlightRootProps;
}

export const StructureSelect = ({
  structureId,
  onStructureIdChange,
  spotlightRootProps,
}: Props) => {
  const getStructureMetadataQuery = useQuery(
    atlasConnectQuery.getStructureMetadata,
    {
      id: structureId,
    },
    {
      enabled: !!structureId,
    },
  );
  const structureMetadata = getStructureMetadataQuery.data?.base;

  return (
    <>
      <Flex gap="xs">
        <Paper withBorder p="xs" w="100%">
          <Group justify="space-between">
            <Text size="sm">
              {structureMetadata
                ? structureMetadata.name
                : "No structure selected"}
            </Text>
            {structureMetadata && (
              <CloseButton
                aria-label="Clear structure"
                onClick={() => onStructureIdChange(undefined, undefined)}
                style={{ marginTop: -5, marginBottom: -5 }}
              />
            )}
          </Group>
        </Paper>
        <Button variant="filled" h="auto" onClick={structureSpotlight.open}>
          <IconSearch size={14} />
        </Button>
      </Flex>
      <StructureSearch
        onSelectStructure={(structureId, entry) => {
          onStructureIdChange(structureId, entry);
          structureSpotlight.close();
        }}
        structureSpotlightStore={structureSpotlightStore}
        spotlightRootProps={spotlightRootProps}
      />
    </>
  );
};
