import { Group, Menu, UnstyledButton, rem } from "@mantine/core";
import { IconAdjustments, IconChevronDown } from "@tabler/icons-react";
import { Link } from "wouter";
import { AvatarAndUsername } from "./AvatarAndUsername";
import { LogoutButton } from "./LogoutButton";

interface Props {
  username: string | undefined;
  showTextForSm?: boolean;
}

export const HeaderUsername = ({ username, showTextForSm }: Props) => {
  return (
    <div>
      {username && (
        <Menu
          shadow="md"
          width={150}
          position="bottom-end"
          offset={5}
          transitionProps={{ transition: "pop-top-right" }}
        >
          <Menu.Target>
            <UnstyledButton>
              <Group gap="xs">
                <AvatarAndUsername
                  username={username}
                  showTextForSm={showTextForSm}
                />
                <IconChevronDown size="1rem" />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconAdjustments
                  style={{ width: rem(16), height: rem(16) }}
                  stroke={1.5}
                />
              }
              component={Link}
              to="/user-settings"
            >
              Settings
            </Menu.Item>
            <LogoutButton />
          </Menu.Dropdown>
        </Menu>
      )}
    </div>
  );
};
