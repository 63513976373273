import { durationToMillis, quizConnectQuery } from "@augmedi/proto-gen";
import { Duration } from "@bufbuild/protobuf";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import {
  Button,
  Group,
  Menu,
  Paper,
  ScrollArea,
  Space,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import HeatMap from "@uiw/react-heat-map";
import { last, sortBy } from "lodash-es";
import { useState } from "react";
import { formatMillis } from "../utils";

export interface StatsHeatMap {
  date: string;
  count: number;
  totalAnsweringTimeMs: number;
}

export const HeatMapPanel = () => {
  const res = useSuspenseQuery(quizConnectQuery.getQuizDailyEffortStats, {
    utcOffset: Math.round(new Date().getTimezoneOffset() / 60),
  });

  const heatMapValues = res.data.dailyStatsByDate.map((stat) => ({
    year: new Date(stat.date).getFullYear(),
    date: stat.date,
    count: stat.correctAnswers + stat.wrongAnswers,
    totalAnsweringTimeMs: durationToMillis(
      stat.answeringTime || new Duration(),
    ),
  }));
  const years = sortBy([...new Set(heatMapValues.map((item) => item.year))]);

  const [selectedYear, setSelectedYear] = useState(last(years));

  return (
    <Paper withBorder p="md" radius="md">
      <Stack>
        <Group justify="space-between">
          <Text fz="xl" fw={700}>
            Answered Questions
          </Text>
          <Menu width="target">
            <Menu.Target>
              <Button variant="default" rightSection={<IconChevronDown />}>
                {selectedYear}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              {years.map((year) => (
                <Menu.Item onClick={() => setSelectedYear(year)} key={year}>
                  {year}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Group>
        <Group align="center">
          <ScrollArea w={900}>
            <HeatMap
              value={heatMapValues}
              width={1200}
              height={200}
              rectSize={20}
              panelColors={{
                0: "#EBEDF0",
                20: "#C6E48B",
                100: "#7BC96F",
                500: "#239A3B",
                1000: "#196127",
              }}
              rectProps={{
                rx: 3,
              }}
              startDate={new Date(`${selectedYear}/1/1`)}
              rectRender={(props, data) => {
                const valueItem = data as unknown as {
                  count: number;
                  totalAnsweringTimeMs: number;
                } & { column: number; row: number; index: number };
                return (
                  <Tooltip
                    withArrow
                    events={{ hover: true, focus: true, touch: true }}
                    label={`Questions Answered: ${
                      valueItem.count || 0
                    }, Learning Time: ${formatMillis(
                      valueItem.totalAnsweringTimeMs || 0,
                    )}`}
                  >
                    <rect {...props} />
                  </Tooltip>
                );
              }}
            />
            <Space h="md" />
          </ScrollArea>
        </Group>
      </Stack>
    </Paper>
  );
};
