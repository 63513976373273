import type { ModalProps } from "@mantine/core";

export const annotatorLeftModalProps: Pick<
  ModalProps,
  "styles" | "overlayProps" | "centered"
> = {
  styles: { inner: { justifyContent: "left" } },
  overlayProps: { opacity: 0.3 },
  centered: true,
};
