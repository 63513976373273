{
  "scenes": {
    "bones": {
      "sideMode": "Unmodified",
      "big": true,
      "initialCameraPosition": {
        "x": -0.02743496302198584,
        "y": 1.4938618900225107,
        "z": -2.601985485089979
      },
      "visibleMeshNames": [
        "B_Atlas_C1",
        "B_Axus_C2",
        "B_Calcaneus_L",
        "B_Calcaneus_R",
        "B_Capitate_L",
        "B_Capitate_R",
        "B_Cervical_Disc_C2",
        "B_Cervical_Disc_C3",
        "B_Cervical_Disc_C4",
        "B_Cervical_Disc_C5",
        "B_Cervical_Disc_C6",
        "B_Cervical_Disc_C7",
        "B_Cervical_Vertebra_C3",
        "B_Cervical_Vertebra_C4",
        "B_Cervical_Vertebra_C5",
        "B_Cervical_Vertebra_C6",
        "B_Cervical_Vertebra_C7",
        "B_Clavicle_L",
        "B_Clavicle_R",
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Cuboid_L",
        "B_Cuboid_R",
        "B_Distal_Phalanx_1_L",
        "B_Distal_Phalanx_1_R",
        "B_Distal_Phalanx_2_L",
        "B_Distal_Phalanx_2_R",
        "B_Distal_Phalanx_3_L",
        "B_Distal_Phalanx_3_R",
        "B_Distal_Phalanx_4_L",
        "B_Distal_Phalanx_4_R",
        "B_Distal_Phalanx_5_L",
        "B_Distal_Phalanx_5_R",
        "B_Distal_Phalanx_Foot_1_L",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_L",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_L",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_L",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_L",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Ethmoid",
        "B_Femur_L",
        "B_Femur_R",
        "B_Fibula_L",
        "B_Fibula_R",
        "B_Frontal_Bone",
        "B_Hamate_L",
        "B_Hamate_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Humerus_L",
        "B_Humerus_R",
        "B_Inferior_Nasal_Concha_Bone_L",
        "B_Inferior_Nasal_Concha_Bone_R",
        "B_Intermediate_Cuneiform_L",
        "B_Intermediate_Cuneiform_R",
        "B_Lacrimal_Bone_L",
        "B_Lacrimal_Bone_R",
        "B_Lateral_Cuneiform_L",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Lunate_L",
        "B_Lunate_R",
        "B_Mandible",
        "B_Maxilla_L",
        "B_Maxilla_R",
        "B_Medial_Cuneiform_Bone_L",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metacarpal_1_L",
        "B_Metacarpal_1_R",
        "B_Metacarpal_2_L",
        "B_Metacarpal_2_R",
        "B_Metacarpal_3_L",
        "B_Metacarpal_3_R",
        "B_Metacarpal_4_L",
        "B_Metacarpal_4_R",
        "B_Metacarpal_5_L",
        "B_Metacarpal_5_R",
        "B_Metatarsal_1_L",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_L",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_L",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_L",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_L",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_2_L",
        "B_Middle_Phalanx_2_R",
        "B_Middle_Phalanx_3_L",
        "B_Middle_Phalanx_3_R",
        "B_Middle_Phalanx_4_L",
        "B_Middle_Phalanx_4_R",
        "B_Middle_Phalanx_5_L",
        "B_Middle_Phalanx_5_R",
        "B_Middle_Phalanx_Foot_2_L",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_L",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_L",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_L",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Nasal_Bone_L",
        "B_Nasal_Bone_R",
        "B_Navicular_Bone_L",
        "B_Navicular_Bone_R",
        "B_Occipital_Sphenoid",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Parietal_Bone_L",
        "B_Parietal_Bone_R",
        "B_Patella_L",
        "B_Patella_R",
        "B_Pisiform_L",
        "B_Pisiform_R",
        "B_Proximal_Phalanx_1_L",
        "B_Proximal_Phalanx_1_R",
        "B_Proximal_Phalanx_2_L",
        "B_Proximal_Phalanx_2_R",
        "B_Proximal_Phalanx_3_L",
        "B_Proximal_Phalanx_3_R",
        "B_Proximal_Phalanx_4_L",
        "B_Proximal_Phalanx_4_R",
        "B_Proximal_Phalanx_5_L",
        "B_Proximal_Phalanx_5_R",
        "B_Proximal_Phalanx_Foot_1_L",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_L",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_L",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_L",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_L",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Radius_L",
        "B_Radius_R",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Scapula_L",
        "B_Scapula_R",
        "B_Sesamoid_Foot_L",
        "B_Sesamoid_Foot_R",
        "B_Sesamoid_hand_L",
        "B_Sesamoid_hand_R",
        "B_Sphenoid_Bone",
        "B_Sternum",
        "B_Styloid_L",
        "B_Styloid_R",
        "B_Talus_L",
        "B_Talus_R",
        "B_Temporal_Bone_L",
        "B_Temporal_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T4",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "B_Tibia_L",
        "B_Tibia_R",
        "B_Trapezium_L",
        "B_Trapezium_R",
        "B_Trapezoid_L",
        "B_Trapezoid_R",
        "B_Triquetrum_L",
        "B_Triquetrum_R",
        "B_Ulna_L",
        "B_Ulna_R",
        "T_10_Lateral_Incisor",
        "T_11_Cuspid",
        "T_12_First_Bicuspid",
        "T_13_Second_Bicuspid",
        "T_14_First_Molar",
        "T_15_Second_Molar",
        "T_16_Third_Molar",
        "T_17_Third_Molar",
        "T_18_Second_Molar",
        "T_19_First_Molar",
        "T_1_Third_Molar",
        "T_20_Second_Bicuspid",
        "T_21_First_Bicuspid",
        "T_22_Cuspid",
        "T_23_Lateral_Incisor",
        "T_24_Central_Incisor",
        "T_25_Central_Incisor",
        "T_26_Lateral_Incisor",
        "T_27_Cuspid",
        "T_28_First_Bicuspid",
        "T_29_Second_Bicuspid",
        "T_2_Second_Molar",
        "T_30_First_Molar",
        "T_31_Second_Molar",
        "T_32_Third_Molar",
        "T_3_First_Molar",
        "T_4_Second_Bicuspid",
        "T_5_First_Bicuspid",
        "T_6_Cuspid",
        "T_7_Lateral_Incisor",
        "T_8_Central_Incisor",
        "T_9_Central_Incisor",
        "hyoid_bone"
      ],
      "easyMeshNames": [
        "B_Atlas_C1",
        "B_Axus_C2",
        "B_Cervical_Disc_C2",
        "B_Cervical_Disc_C3",
        "B_Cervical_Disc_C4",
        "B_Cervical_Disc_C5",
        "B_Cervical_Disc_C6",
        "B_Cervical_Disc_C7",
        "B_Cervical_Vertebra_C3",
        "B_Cervical_Vertebra_C4",
        "B_Cervical_Vertebra_C5",
        "B_Cervical_Vertebra_C6",
        "B_Cervical_Vertebra_C7",
        "B_Clavicle_L",
        "B_Clavicle_R",
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_L",
        "B_Femur_R",
        "B_Fibula_L",
        "B_Fibula_R",
        "B_Frontal_Bone",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Humerus_L",
        "B_Humerus_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Mandible",
        "B_Maxilla_L",
        "B_Maxilla_R",
        "B_Occipital_Sphenoid",
        "B_Parietal_Bone_L",
        "B_Parietal_Bone_R",
        "B_Patella_L",
        "B_Patella_R",
        "B_Radius_L",
        "B_Radius_R",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Scapula_L",
        "B_Scapula_R",
        "B_Sphenoid_Bone",
        "B_Sternum",
        "B_Styloid_L",
        "B_Styloid_R",
        "B_Temporal_Bone_L",
        "B_Temporal_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T4",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "B_Tibia_L",
        "B_Tibia_R",
        "B_Ulna_L",
        "B_Ulna_R",
        "hyoid_bone"
      ]
    },
    "cranialBones": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.0013170670553711104,
        "y": 1.7856635697830512,
        "z": -0.3891691732547573
      },
      "visibleMeshNames": [
        "B_Ethmoid",
        "B_Frontal_Bone",
        "B_Occipital_Sphenoid",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Parietal_Bone_L",
        "B_Parietal_Bone_R",
        "B_Sphenoid_Bone",
        "B_Temporal_Bone_L",
        "B_Temporal_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R"
      ],
      "easyMeshNames": [
        "B_Ethmoid",
        "B_Frontal_Bone",
        "B_Occipital_Sphenoid",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Parietal_Bone_L",
        "B_Parietal_Bone_R",
        "B_Sphenoid_Bone",
        "B_Temporal_Bone_L",
        "B_Temporal_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R"
      ]
    },
    "facialBones": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.0028946004459424047,
        "y": 1.6584778611686717,
        "z": -0.34076454418464275
      },
      "visibleMeshNames": [
        "B_Inferior_Nasal_Concha_Bone_L",
        "B_Inferior_Nasal_Concha_Bone_R",
        "B_Lacrimal_Bone_L",
        "B_Lacrimal_Bone_R",
        "B_Mandible",
        "B_Maxilla_L",
        "B_Maxilla_R",
        "B_Nasal_Bone_L",
        "B_Nasal_Bone_R",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R",
        "T_10_Lateral_Incisor",
        "T_11_Cuspid",
        "T_12_First_Bicuspid",
        "T_13_Second_Bicuspid",
        "T_14_First_Molar",
        "T_15_Second_Molar",
        "T_16_Third_Molar",
        "T_17_Third_Molar",
        "T_18_Second_Molar",
        "T_19_First_Molar",
        "T_1_Third_Molar",
        "T_20_Second_Bicuspid",
        "T_21_First_Bicuspid",
        "T_22_Cuspid",
        "T_23_Lateral_Incisor",
        "T_24_Central_Incisor",
        "T_25_Central_Incisor",
        "T_26_Lateral_Incisor",
        "T_27_Cuspid",
        "T_28_First_Bicuspid",
        "T_29_Second_Bicuspid",
        "T_2_Second_Molar",
        "T_30_First_Molar",
        "T_31_Second_Molar",
        "T_32_Third_Molar",
        "T_3_First_Molar",
        "T_4_Second_Bicuspid",
        "T_5_First_Bicuspid",
        "T_6_Cuspid",
        "T_7_Lateral_Incisor",
        "T_8_Central_Incisor",
        "T_9_Central_Incisor"
      ],
      "easyMeshNames": [
        "B_Inferior_Nasal_Concha_Bone_L",
        "B_Inferior_Nasal_Concha_Bone_R",
        "B_Lacrimal_Bone_L",
        "B_Lacrimal_Bone_R",
        "B_Mandible",
        "B_Maxilla_L",
        "B_Maxilla_R",
        "B_Nasal_Bone_L",
        "B_Nasal_Bone_R",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R"
      ]
    },
    "bonesLeg": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.011541871121135652,
        "y": 0.6988990233015874,
        "z": -1.5249898593323319
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R"
      ]
    },
    "bonesFoot": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.20570056385366856,
        "y": 0.3669717754614942,
        "z": -0.13719581743750642
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R"
      ],
      "easyMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R"
      ]
    },
    "bonesArm": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": -0.2482751019253595,
        "y": 1.1636964052704117,
        "z": -1.1045957150540064
      },
      "visibleMeshNames": [
        "B_Capitate_R",
        "B_Clavicle_R",
        "B_Distal_Phalanx_1_R",
        "B_Distal_Phalanx_2_R",
        "B_Distal_Phalanx_3_R",
        "B_Distal_Phalanx_4_R",
        "B_Distal_Phalanx_5_R",
        "B_Hamate_R",
        "B_Humerus_R",
        "B_Lunate_R",
        "B_Metacarpal_1_R",
        "B_Metacarpal_2_R",
        "B_Metacarpal_3_R",
        "B_Metacarpal_4_R",
        "B_Metacarpal_5_R",
        "B_Middle_Phalanx_2_R",
        "B_Middle_Phalanx_3_R",
        "B_Middle_Phalanx_4_R",
        "B_Middle_Phalanx_5_R",
        "B_Pisiform_R",
        "B_Proximal_Phalanx_1_R",
        "B_Proximal_Phalanx_2_R",
        "B_Proximal_Phalanx_3_R",
        "B_Proximal_Phalanx_4_R",
        "B_Proximal_Phalanx_5_R",
        "B_Radius_R",
        "B_Scapula_R",
        "B_Sesamoid_hand_R",
        "B_Styloid_R",
        "B_Trapezium_R",
        "B_Trapezoid_R",
        "B_Triquetrum_R",
        "B_Ulna_R"
      ],
      "easyMeshNames": ["B_Clavicle_R", "B_Radius_R", "B_Scapula_R", "B_Ulna_R"]
    },
    "bonesHand": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.23063263311954565,
        "y": 0.9019014369848691,
        "z": -0.3296458891582862
      },
      "visibleMeshNames": [
        "B_Capitate_R",
        "B_Distal_Phalanx_1_R",
        "B_Distal_Phalanx_2_R",
        "B_Distal_Phalanx_3_R",
        "B_Distal_Phalanx_4_R",
        "B_Distal_Phalanx_5_R",
        "B_Hamate_R",
        "B_Lunate_R",
        "B_Metacarpal_1_R",
        "B_Metacarpal_2_R",
        "B_Metacarpal_3_R",
        "B_Metacarpal_4_R",
        "B_Metacarpal_5_R",
        "B_Middle_Phalanx_2_R",
        "B_Middle_Phalanx_3_R",
        "B_Middle_Phalanx_4_R",
        "B_Middle_Phalanx_5_R",
        "B_Pisiform_R",
        "B_Proximal_Phalanx_1_R",
        "B_Proximal_Phalanx_2_R",
        "B_Proximal_Phalanx_3_R",
        "B_Proximal_Phalanx_4_R",
        "B_Proximal_Phalanx_5_R",
        "B_Sesamoid_hand_R",
        "B_Styloid_R",
        "B_Trapezium_R",
        "B_Trapezoid_R",
        "B_Triquetrum_R"
      ],
      "easyMeshNames": [
        "B_Capitate_R",
        "B_Distal_Phalanx_1_R",
        "B_Distal_Phalanx_2_R",
        "B_Distal_Phalanx_3_R",
        "B_Distal_Phalanx_4_R",
        "B_Distal_Phalanx_5_R",
        "B_Hamate_R",
        "B_Lunate_R",
        "B_Metacarpal_1_R",
        "B_Metacarpal_2_R",
        "B_Metacarpal_3_R",
        "B_Metacarpal_4_R",
        "B_Metacarpal_5_R",
        "B_Middle_Phalanx_2_R",
        "B_Middle_Phalanx_3_R",
        "B_Middle_Phalanx_4_R",
        "B_Middle_Phalanx_5_R",
        "B_Pisiform_R",
        "B_Proximal_Phalanx_1_R",
        "B_Proximal_Phalanx_2_R",
        "B_Proximal_Phalanx_3_R",
        "B_Proximal_Phalanx_4_R",
        "B_Proximal_Phalanx_5_R",
        "B_Sesamoid_hand_R",
        "B_Styloid_R",
        "B_Trapezium_R",
        "B_Trapezoid_R",
        "B_Triquetrum_R"
      ]
    },
    "musclesNervesLeg1": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": -0.06783673715005732,
        "y": 0.5786495337198678,
        "z": -1.7523630846570628
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliococcygeus_R",
        "M_Iliotibial_Tract_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_R",
        "M_Popliteus_R",
        "M_Psoas_Major_R",
        "M_Psoas_Minor_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Levator_Ani_and_Coccygeus_Muscle_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Perforating_Cutaneous_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Subcostal_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Cluneal_Nerves_L",
        "Superior_Cluneal_Nerves_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "Superomedial_Genicular_Nerve_R",
        "Sural_Nerve_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Psoas_Major_R",
        "M_Psoas_Minor_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Nerve_to_Levator_Ani_and_Coccygeus_Muscle_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Obturator_Nerve_R",
        "Perforating_Cutaneous_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Subcostal_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Cluneal_Nerves_L",
        "Superior_Cluneal_Nerves_R",
        "Sural_Nerve_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R"
      ]
    },
    "musclesNervesLeg2": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": -0.052651532377100185,
        "y": 0.571691933906087,
        "z": -1.7086429652917106
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliococcygeus_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_R",
        "M_Popliteus_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Semimembranosus_R",
        "M_Soleus_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Levator_Ani_and_Coccygeus_Muscle_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Perforating_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "Superomedial_Genicular_Nerve_R",
        "Sural_Nerve_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Medius_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Obturator_Internus_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_R",
        "M_Popliteus_R",
        "M_Quadratus_Femoris_R",
        "M_Semimembranosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Nerve_to_Levator_Ani_and_Coccygeus_Muscle_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Obturator_Nerve_R",
        "Perforating_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Sural_Nerve_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R"
      ]
    },
    "musclesNervesLeg3": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": -0.06848188547916392,
        "y": 0.7044919588629198,
        "z": -1.7036845729363819
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliococcygeus_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Popliteus_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Semimembranosus_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "Superomedial_Genicular_Nerve_R",
        "T12_Spinal_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gracilis_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Popliteus_R",
        "M_Quadratus_Femoris_R",
        "M_Semimembranosus_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "T12_Spinal_Nerve_R"
      ]
    },
    "musclesNervesLeg4": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.020860882187512322,
        "y": 0.6427038410868592,
        "z": -1.6084733254883665
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Iliococcygeus_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "Superomedial_Genicular_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Iliococcygeus_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R"
      ]
    },
    "musclesNervesLeg5": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.0634745775987768,
        "y": 0.619669710534984,
        "z": -1.6070111765035853
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Posterior_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superomedial_Genicular_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Lumbricals_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Posterior_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superomedial_Genicular_Nerve_R"
      ]
    },
    "musclesNervesLeg6": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": -0.05013450940528377,
        "y": 0.8660994260611767,
        "z": -1.5018757410312131
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Quadratus_Plantae_R",
        "M_Tibialis_Posterior_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Adductor_Brevis_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Quadratus_Plantae_R",
        "M_Tibialis_Posterior_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R"
      ]
    },
    "musclesNervesLeg7": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.07898874482274408,
        "y": 0.24618875681233712,
        "z": -0.7643477477907862
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Fibula_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Tibialis_Posterior_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Superficial_Peroneal_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "B_Tibia_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Lumbricals_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Tibialis_Posterior_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Superficial_Peroneal_Nerve_R"
      ]
    },
    "musclesNervesLeg8": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.08420315855100885,
        "y": 0.2684535362331695,
        "z": -0.7596601019110414
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Fibula_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Interossei_Plantar_R",
        "Superficial_Peroneal_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Calcaneus_R",
        "B_Fibula_R",
        "B_Tibia_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "Superficial_Peroneal_Nerve_R"
      ]
    },
    "musclesFoot1": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.27684044184333845,
        "y": 0.29701245582824504,
        "z": -0.2089596868958248
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Interossei_Plantar_R"
      ],
      "easyMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Interossei_Plantar_R"
      ]
    },
    "prometheus-107-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.0338955744556654,
        "y": 1.3263766604593168,
        "z": -1.4544176344994657
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Sternum",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T4",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Head_of_Ribs_L",
        "C_Articular_Surface_Head_of_Ribs_R",
        "C_Articular_Surface_Tubercle_of_Ribs_L",
        "C_Articular_Surface_Tubercle_of_Ribs_R",
        "C_Costal_Facets_of_Vertebra_L",
        "C_Costal_Facets_of_Vertebra_R",
        "C_Femur_Head_R",
        "C_Transverse_Costal_Facet_of_Vertebra_L",
        "C_Transverse_Costal_Facet_of_Vertebra_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_Iliacus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Lumborum_Muscle_R",
        "M_Rectus_Abdominus_Muscle_L"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Sternum",
        "L_Inguinal_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_Iliacus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Lumborum_Muscle_R"
      ]
    },
    "prometheus-108-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.04143118960693086,
        "y": 1.0338290159912709,
        "z": -1.4953518535892913
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_L",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Sternum",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Head_of_Ribs_L",
        "C_Articular_Surface_Head_of_Ribs_R",
        "C_Articular_Surface_Tubercle_of_Ribs_L",
        "C_Articular_Surface_Tubercle_of_Ribs_R",
        "C_Costal_Facets_of_Vertebra_L",
        "C_Costal_Facets_of_Vertebra_R",
        "C_Femur_Head_L",
        "C_Femur_Head_R",
        "C_Transverse_Costal_Facet_of_Vertebra_L",
        "C_Transverse_Costal_Facet_of_Vertebra_R",
        "Epididymis_L",
        "Epididymis_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_External_Spermatic_Fascia_L",
        "F_External_Spermatic_Fascia_R",
        "F_Internal_Spermatic_Fascia_L",
        "F_Internal_Spermatic_Fascia_R",
        "F_Tunica_Albuginea__Testis__L",
        "F_Tunica_Albuginea__Testis__R",
        "F_Tunica_Vaginalis_L",
        "F_Tunica_Vaginalis_R",
        "F_Tunica_Vasculosa_L",
        "F_Tunica_Vasculosa_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_External_Oblique_Muscle_L",
        "M_External_Oblique_Muscle_R",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Internal_Oblique_Muscle_L",
        "M_Internal_Oblique_Muscle_R",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Rectus_Abdominus_Muscle_L",
        "M_Rectus_Abdominus_Muscle_R",
        "M_Testis_Cremaster_L",
        "M_Testis_Cremaster_R",
        "M_Trasnversus_Abdominus_Muscle_L",
        "M_Trasnversus_Abdominus_Muscle_R",
        "Seminiferous_Tubules_L",
        "Seminiferous_Tubules_R"
      ],
      "easyMeshNames": [
        "F_External_Spermatic_Fascia_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "M_External_Oblique_Muscle_R",
        "M_Iliacus_R",
        "M_Pectineus_R",
        "M_Psoas_Major_R"
      ]
    },
    "prometheus-108-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.002750515720986284,
        "y": 1.150058555577162,
        "z": -1.4907406808813002
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_L",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Sternum",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Head_of_Ribs_L",
        "C_Articular_Surface_Head_of_Ribs_R",
        "C_Articular_Surface_Tubercle_of_Ribs_L",
        "C_Articular_Surface_Tubercle_of_Ribs_R",
        "C_Costal_Facets_of_Vertebra_L",
        "C_Costal_Facets_of_Vertebra_R",
        "C_Femur_Head_L",
        "C_Femur_Head_R",
        "C_Transverse_Costal_Facet_of_Vertebra_L",
        "C_Transverse_Costal_Facet_of_Vertebra_R",
        "Epididymis_L",
        "Epididymis_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_External_Spermatic_Fascia_L",
        "F_External_Spermatic_Fascia_R",
        "F_Internal_Spermatic_Fascia_L",
        "F_Internal_Spermatic_Fascia_R",
        "F_Tunica_Albuginea__Testis__L",
        "F_Tunica_Albuginea__Testis__R",
        "F_Tunica_Vaginalis_L",
        "F_Tunica_Vaginalis_R",
        "F_Tunica_Vasculosa_L",
        "F_Tunica_Vasculosa_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_External_Oblique_Muscle_L",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Internal_Oblique_Muscle_L",
        "M_Internal_Oblique_Muscle_R",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Rectus_Abdominus_Muscle_L",
        "M_Rectus_Abdominus_Muscle_R",
        "M_Testis_Cremaster_L",
        "M_Testis_Cremaster_R",
        "M_Trasnversus_Abdominus_Muscle_L",
        "M_Trasnversus_Abdominus_Muscle_R",
        "Seminiferous_Tubules_L",
        "Seminiferous_Tubules_R"
      ],
      "easyMeshNames": ["M_Internal_Oblique_Muscle_R"]
    },
    "prometheus-108-c": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.030320194430928822,
        "y": 1.275948410033405,
        "z": -1.4741151476528083
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_L",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Sternum",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Head_of_Ribs_L",
        "C_Articular_Surface_Head_of_Ribs_R",
        "C_Articular_Surface_Tubercle_of_Ribs_L",
        "C_Articular_Surface_Tubercle_of_Ribs_R",
        "C_Costal_Facets_of_Vertebra_L",
        "C_Costal_Facets_of_Vertebra_R",
        "C_Femur_Head_L",
        "C_Femur_Head_R",
        "C_Transverse_Costal_Facet_of_Vertebra_L",
        "C_Transverse_Costal_Facet_of_Vertebra_R",
        "Epididymis_L",
        "Epididymis_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_External_Spermatic_Fascia_L",
        "F_External_Spermatic_Fascia_R",
        "F_Internal_Spermatic_Fascia_L",
        "F_Internal_Spermatic_Fascia_R",
        "F_Tunica_Albuginea__Testis__L",
        "F_Tunica_Albuginea__Testis__R",
        "F_Tunica_Vaginalis_L",
        "F_Tunica_Vaginalis_R",
        "F_Tunica_Vasculosa_L",
        "F_Tunica_Vasculosa_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_External_Oblique_Muscle_L",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Internal_Oblique_Muscle_L",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Rectus_Abdominus_Muscle_L",
        "M_Rectus_Abdominus_Muscle_R",
        "M_Testis_Cremaster_L",
        "M_Testis_Cremaster_R",
        "M_Trasnversus_Abdominus_Muscle_L",
        "M_Trasnversus_Abdominus_Muscle_R",
        "Seminiferous_Tubules_L",
        "Seminiferous_Tubules_R"
      ],
      "easyMeshNames": [
        "M_Rectus_Abdominus_Muscle_R",
        "M_Trasnversus_Abdominus_Muscle_R"
      ]
    },
    "prometheus-250-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.46158183337001124,
        "y": 0.9571072371638597,
        "z": 0.11682697663194919
      },
      "visibleMeshNames": ["B_Hipbone_R", "C_Acetabulum_Lunate_Surface_R"],
      "easyMeshNames": ["B_Hipbone_R", "C_Acetabulum_Lunate_Surface_R"]
    },
    "prometheus-252-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.11090844843452509,
        "y": 0.9490271744516591,
        "z": 0.8843078851685163
      },
      "visibleMeshNames": [
        "B_Femur_L",
        "B_Hipbone_L",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Femur_Head_L"
      ],
      "easyMeshNames": ["B_Femur_L", "B_Hipbone_L"]
    },
    "prometheus-253-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.08035001391544946,
        "y": 0.9306575001009122,
        "z": 0.8876759904557137
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Femur_Head_R"
      ],
      "easyMeshNames": ["B_Femur_R", "B_Hipbone_R"]
    },
    "prometheus-254-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.012875721087525088,
        "y": 1.0192007814548198,
        "z": -0.7745649551340072
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Femur_Head_R",
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R"
      ],
      "easyMeshNames": [
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R"
      ]
    },
    "prometheus-255-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.03264246051541976,
        "y": 1.0456300811162418,
        "z": 0.48436619335353387
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "L_Iliofemoral_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R"
      ]
    },
    "prometheus-256-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.012057094003190827,
        "y": 1.009818420649106,
        "z": -0.9203555383115074
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ]
    },
    "prometheus-257-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.001054942191328331,
        "y": 1.1602843225863038,
        "z": -1.0369650660471417
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Iliofemoral_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R"
      ],
      "easyMeshNames": [
        "L_Patellar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Pectineus_R",
        "M_Vastus_Intermedius_R"
      ]
    },
    "prometheus-258-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.005494147968491899,
        "y": 1.1153341761425577,
        "z": -0.9121151349994077
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Iliofemoral_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "M_Piriformis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R"
      ]
    },
    "prometheus-259-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -1.0374983813071503,
        "y": 1.0718345383470183,
        "z": 0.3239285421070519
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Sacrum",
        "M_Adductor_Magnus_R",
        "M_Gluteus_Maximus_R",
        "M_Gracilis_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Sartorius_R",
        "M_Vastus_Medialis_R"
      ]
    },
    "prometheus-260-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.056903914142476315,
        "y": 0.8211389982467696,
        "z": 1.0886873732415026
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Iliotibial_Tract_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R"
      ]
    },
    "prometheus-261-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.06386181998851652,
        "y": 0.8576712034564877,
        "z": 1.2077962081047575
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Femoris_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gluteus_Minimus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ]
    },
    "prometheus-262-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.052951321781103636,
        "y": 0.7993321201507959,
        "z": 0.9267663226702523
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Soleus_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Sacrum",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Gracilis_R"
      ]
    },
    "prometheus-263-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 1.5174071714243185,
        "y": 1.0332498504452683,
        "z": -0.3060179595545382
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Iliofemoral_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Pectineus_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "B_Hipbone_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Iliotibial_Tract_R",
        "M_Vastus_Lateralis_R"
      ]
    },
    "prometheus-264-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.002657865856047878,
        "y": 1.1344893386592563,
        "z": -0.7340285398408007
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_12_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "C_Femur_Head_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Iliacus_R",
        "M_Psoas_Major_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_12_R",
        "L_Sacrotuberous_ligament_R",
        "M_Iliacus_R",
        "M_Psoas_Major_R"
      ]
    },
    "prometheus-265-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01663866822839258,
        "y": 0.8416606154696233,
        "z": 1.1538158481767042
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "F_Thoracolumbar_Fascia",
        "L_Interosseus_Membrane_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Iliotibial_Tract_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "B_Tibia_R",
        "F_Thoracolumbar_Fascia",
        "L_Interosseus_Membrane_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Iliotibial_Tract_R"
      ]
    },
    "prometheus-265-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.05055777939424352,
        "y": 1.1167984090570204,
        "z": 1.0097826997958286
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Iliotibial_Tract_R"
      ],
      "easyMeshNames": ["B_Hipbone_R", "B_Sacrum", "M_Iliotibial_Tract_R"]
    },
    "prometheus-266-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.03955517357600595,
        "y": 1.0056292333307282,
        "z": 0.5921721874482134
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Femur_Head_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Medius_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ]
    },
    "prometheus-267-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.04138328110622932,
        "y": 1.099477303677399,
        "z": 0.6596143986709618
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Femur_Head_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Sacrum",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ]
    },
    "prometheus-269-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.010007906501839588,
        "y": 1.0400436076657595,
        "z": -0.9546948859976304
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "M_Pectineus_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L5",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Adductor_Magnus_R",
        "M_Pectineus_R"
      ]
    },
    "prometheus-269-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01828541846138831,
        "y": 1.062223733027506,
        "z": -0.7646680316956105
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R"
      ],
      "easyMeshNames": ["M_Adductor_Magnus_R", "M_Obturator_Externus_R"]
    },
    "prometheus-270-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.005985685112624827,
        "y": 1.0422097407850917,
        "z": -0.9542384979994025
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "M_Pectineus_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Pectineus_R"
      ]
    },
    "prometheus-271-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.003616377858869063,
        "y": 0.9752091698859311,
        "z": -0.9590786797795129
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Patellar_Ligament_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ]
    },
    "prometheus-272-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.007931812041643354,
        "y": 0.9931367467113938,
        "z": -0.9401980538697098
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ]
    },
    "prometheus-272-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.0026376015555664156,
        "y": 0.9781777812777177,
        "z": -0.7841539742052105
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Rectus_Femoris_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": ["M_Rectus_Femoris_R"]
    },
    "prometheus-273-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.07580819169300454,
        "y": 0.9246679571774211,
        "z": 1.0415372719145923
      },
      "visibleMeshNames": [
        "B_Femur_L",
        "B_Fibula_L",
        "B_Hipbone_L",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Tibia_L",
        "C_Articular_Surface_Distal_Part_Femur_L",
        "C_Articular_Surface_Proximal_Tibia_L",
        "L_Interosseus_Membrane_L",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrotuberous_ligament_L",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Popliteus_L",
        "M_Semimembranosus_L",
        "M_Semitendinosus_L"
      ],
      "easyMeshNames": [
        "B_Femur_L",
        "B_Fibula_L",
        "B_Hipbone_L",
        "B_Sacrum",
        "B_Tibia_L",
        "L_Sacrotuberous_ligament_L",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Popliteus_L",
        "M_Semimembranosus_L",
        "M_Semitendinosus_L"
      ]
    },
    "prometheus-273-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.035446919847095006,
        "y": 0.9072347163116635,
        "z": 1.041722490152178
      },
      "visibleMeshNames": [
        "B_Femur_L",
        "B_Fibula_L",
        "B_Hipbone_L",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Tibia_L",
        "C_Articular_Surface_Distal_Part_Femur_L",
        "C_Articular_Surface_Proximal_Tibia_L",
        "L_Interosseus_Membrane_L",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrotuberous_ligament_L",
        "M_Biceps_Femoris_Long_Head_L",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Popliteus_L",
        "M_Semimembranosus_L",
        "M_Semitendinosus_L"
      ],
      "easyMeshNames": ["M_Biceps_Femoris_Long_Head_L"]
    },
    "prometheus-274-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.08710903600905837,
        "y": 0.9902861389009151,
        "z": 1.0171210627752456
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Popliteus_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Tibia_R",
        "L_Sacrotuberous_ligament_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Popliteus_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R"
      ]
    },
    "prometheus-312-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 1.8166764550764107,
        "y": 0.321136402377787,
        "z": 0.07448783992326488
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Coccyx",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sacrum",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_L",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_L",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_L",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_L",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "T12_Anterior_Root_R",
        "T12_Posterior_Root_Ganglion_R",
        "T12_Posterior_Root_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "Common_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Tibial_Nerve_R"
      ]
    },
    "prometheus-313-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.04517146050820271,
        "y": 1.2065631425069083,
        "z": -0.837450175951038
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_12_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "C_Femur_Head_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_L",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_L",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_L",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_L",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Iliacus_R",
        "Obturator_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Sciatic_Nerve_R",
        "Subcostal_Nerve_R",
        "T12_Anterior_Root_R",
        "T12_Posterior_Root_Ganglion_R",
        "T12_Posterior_Root_R",
        "T12_Spinal_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Lumbar_Vertebra_L1",
        "Femoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Obturator_Nerve_R",
        "Sciatic_Nerve_R"
      ]
    },
    "prometheus-314-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.00033672240639542536,
        "y": 1.1126748938290312,
        "z": -0.9151816863801301
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "Obturator_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Lumbar_Vertebra_L4",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "Obturator_Nerve_R"
      ]
    },
    "prometheus-314-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.00033672240639542536,
        "y": 1.1126748938290312,
        "z": -0.9151816863801301
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "Obturator_Nerve_R"
      ],
      "easyMeshNames": [
        "M_Adductor_Brevis_R",
        "M_Obturator_Externus_R",
        "Obturator_Nerve_R"
      ]
    },
    "prometheus-315-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.000553078149039217,
        "y": 1.1892937185995667,
        "z": -1.152882123322052
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Femoral_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Iliacus_R",
        "M_Pectineus_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Saphenous_Nerve_R"
      ],
      "easyMeshNames": [
        "Femoral_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "M_Iliacus_R",
        "M_Pectineus_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Saphenous_Nerve_R"
      ]
    },
    "prometheus-315-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.000553078149039217,
        "y": 1.1892937185995667,
        "z": -1.152882123322052
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Femoral_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Iliacus_R",
        "M_Pectineus_R",
        "M_Psoas_Major_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Saphenous_Nerve_R"
      ],
      "easyMeshNames": ["M_Vastus_Intermedius_R", "Saphenous_Nerve_R"]
    },
    "prometheus-316-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 1.6804638478580172,
        "y": 0.3429228460150353,
        "z": -0.1552477865294814
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Coccyx",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sacrum",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inferior_Extensor_Retinaculum_1_R",
        "L_Inferior_Extensor_Retinaculum_2_R",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Sheath_of_Tibialis_Anterior_R",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_R",
        "L_Tendon_sheath_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Tibialis_Anterior_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "Common_Peroneal_Nerve_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Longubs_R",
        "M_Tibialis_Anterior_R",
        "Sciatic_Nerve_R"
      ]
    },
    "prometheus-316-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 1.6804638478580172,
        "y": 0.3429228460150353,
        "z": -0.1552477865294814
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Coccyx",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sacrum",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inferior_Extensor_Retinaculum_1_R",
        "L_Inferior_Extensor_Retinaculum_2_R",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Tertius_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": ["Superficial_Peroneal_Nerve_R"]
    },
    "prometheus-318-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.3992599737331401,
        "y": 0.5767450146665861,
        "z": 1.8336342703781754
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "Deep_Peroneal_Nerve_R",
        "Dorsal_Metatarsal_Veins_R",
        "Dorsal_Venous_Arch_R",
        "Dorsalis_Metatarsal_Arteries_R",
        "F_Fascia_Lata_L",
        "F_Fascia_Lata_R",
        "F_Membranous_Layer_of_Superficial_Fascia_of_Abdomen",
        "F_Penis_Superficial_Fascia",
        "F_Superficial_Perineal__Colles__Fascia",
        "F_Thoracolumbar_Fascia",
        "Femoral_Nerve_R",
        "Great_Saphenous_Vein_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Clunial_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L_Anococcygeal_Ligament",
        "L_Anococcygeal_Raphe",
        "L_Anterior_Cruciate_Ligament_L",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_L",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_L",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_L",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Anterior_Talofibular_Ligament_L",
        "L_Anterior_Talofibular_Ligament_R",
        "L_Anterior_Tibiofibular_Ligament_L",
        "L_Anterior_Tibiofibular_Ligament_R",
        "L_Anterior_Tibiotalar_Part_L",
        "L_Anterior_Tibiotalar_Part_R",
        "L_Arcuate_Popliteal_Ligament_L",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_L",
        "L_Articular_Joint_Capsule_R",
        "L_Calcaneocuboidea_Plantare_L",
        "L_Calcaneocuboidea_Plantare_R",
        "L_Calcaneofibular_Ligament_L",
        "L_Calcaneofibular_Ligament_R",
        "L_Cervical_Ligament_1_L",
        "L_Cervical_Ligament_2_L",
        "L_Cervical_Ligament_3_L",
        "L_Cervical_Ligament_1_R",
        "L_Cervical_Ligament_2_R",
        "L_Cervical_Ligament_3_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_First_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_First_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_Second_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_Second_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_3_of_Second_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_Third_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_Third_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_3_of_Third_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fourth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fourth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fourth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fifth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fifth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fifth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_First_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_First_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Second_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Second_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Second_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Third_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Third_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Third_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fourth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fourth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fourth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fifth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fifth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fifth_Toe_R",
        "L_Cruciform_Ligament_1_of_First_Toe_L",
        "L_Cruciform_Ligament_2_of_First_Toe_L",
        "L_Cruciform_Ligament_3_of_First_Toe_L",
        "L_Cruciform_Ligament_4_of_First_Toe_L",
        "L_Cruciform_Ligament_5_of_First_Toe_L",
        "L_Cruciform_Ligament_6_of_First_Toe_L",
        "L_Cruciform_Ligament_1_of_Second_Toe_L",
        "L_Cruciform_Ligament_2_of_Second_Toe_L",
        "L_Cruciform_Ligament_3_of_Second_Toe_L",
        "L_Cruciform_Ligament_4_of_Second_Toe_L",
        "L_Cruciform_Ligament_5_of_Second_Toe_L",
        "L_Cruciform_Ligament_6_of_Second_Toe_L",
        "L_Cruciform_Ligament_7_of_Second_Toe_L",
        "L_Cruciform_Ligament_1_of_Third_Toe_L",
        "L_Cruciform_Ligament_2_of_Third_Toe_L",
        "L_Cruciform_Ligament_3_of_Third_Toe_L",
        "L_Cruciform_Ligament_4_of_Third_Toe_L",
        "L_Cruciform_Ligament_5_of_Third_Toe_L",
        "L_Cruciform_Ligament_6_of_Third_Toe_L",
        "L_Cruciform_Ligament_7_of_Third_Toe_L",
        "L_Cruciform_Ligament_1_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_2_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_3_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_4_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_5_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_6_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_7_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_1_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_2_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_3_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_4_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_5_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_6_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_7_of_Fifth_Toe_L",
        "L_Cuneo_Cuboideum_Plantare_Ligament_L",
        "L_Cuneo_Cuboideum_Plantare_Ligament_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Deep_Transverse_Metatarsal_Ligament_1_L",
        "L_Deep_Transverse_Metatarsal_Ligament_2_L",
        "L_Deep_Transverse_Metatarsal_Ligament_3_L",
        "L_Deep_Transverse_Metatarsal_Ligament_4_L",
        "L_Deep_Transverse_Metatarsal_Ligament_1_R",
        "L_Deep_Transverse_Metatarsal_Ligament_2_R",
        "L_Deep_Transverse_Metatarsal_Ligament_3_R",
        "L_Deep_Transverse_Metatarsal_Ligament_4_R",
        "L_Dorsal_Calcaneocuboid_Ligament_1_L",
        "L_Dorsal_Calcaneocuboid_Ligament_2_L",
        "L_Dorsal_Calcaneocuboid_Ligament_1_R",
        "L_Dorsal_Calcaneocuboid_Ligament_2_R",
        "L_Dorsal_Cuboideonavicular_Ligament_L",
        "L_Dorsal_Cuboideonavicular_Ligament_R",
        "L_Dorsal_Cuneocuboid_Ligament_1_L",
        "L_Dorsal_Cuneocuboid_Ligament_2_L",
        "L_Dorsal_Cuneocuboid_Ligament_1_R",
        "L_Dorsal_Cuneocuboid_Ligament_2_R",
        "L_Dorsal_Cuneonavicular_Ligament_1_L",
        "L_Dorsal_Cuneonavicular_Ligament_2_L",
        "L_Dorsal_Cuneonavicular_Ligament_3_L",
        "L_Dorsal_Cuneonavicular_Ligament_1_R",
        "L_Dorsal_Cuneonavicular_Ligament_2_R",
        "L_Dorsal_Cuneonavicular_Ligament_3_R",
        "L_Dorsal_Metatarsal_Ligaments_1_L",
        "L_Dorsal_Metatarsal_Ligaments_2_L",
        "L_Dorsal_Metatarsal_Ligaments_3_L",
        "L_Dorsal_Metatarsal_Ligaments_4_L",
        "L_Dorsal_Metatarsal_Ligaments_1_R",
        "L_Dorsal_Metatarsal_Ligaments_2_R",
        "L_Dorsal_Metatarsal_Ligaments_3_R",
        "L_Dorsal_Metatarsal_Ligaments_4_R",
        "L_Dorsal_Talonavicular_Ligament_L",
        "L_Dorsal_Talonavicular_Ligament_R",
        "L_Dorsal_Tarsal_Ligament_1_R",
        "L_Dorsal_Tarsal_Ligament_2_R",
        "L_Dorsal_Tarsal_Ligament_1_L",
        "L_Dorsal_Tarsal_Ligament_2_L",
        "L_Dorsal_Tarsometatarsal_Ligament_1_L",
        "L_Dorsal_Tarsometatarsal_Ligament_2_L",
        "L_Dorsal_Tarsometatarsal_Ligament_3_L",
        "L_Dorsal_Tarsometatarsal_Ligament_4_L",
        "L_Dorsal_Tarsometatarsal_Ligament_5_L",
        "L_Dorsal_Tarsometatarsal_Ligament_6_L",
        "L_Dorsal_Tarsometatarsal_Ligament_7_L",
        "L_Dorsal_Tarsometatarsal_Ligament_8_L",
        "L_Dorsal_Tarsometatarsal_Ligament_1_R",
        "L_Dorsal_Tarsometatarsal_Ligament_2_R",
        "L_Dorsal_Tarsometatarsal_Ligament_3_R",
        "L_Dorsal_Tarsometatarsal_Ligament_4_R",
        "L_Dorsal_Tarsometatarsal_Ligament_5_R",
        "L_Dorsal_Tarsometatarsal_Ligament_6_R",
        "L_Dorsal_Tarsometatarsal_Ligament_7_R",
        "L_Dorsal_Tarsometatarsal_Ligament_8_R",
        "L_Extensor_Hoods_1_L",
        "L_Extensor_Hoods_2_L",
        "L_Extensor_Hoods_3_L",
        "L_Extensor_Hoods_4_L",
        "L_Extensor_Hoods_5_L",
        "L_Extensor_Hoods_1_R",
        "L_Extensor_Hoods_2_R",
        "L_Extensor_Hoods_3_R",
        "L_Extensor_Hoods_4_R",
        "L_Extensor_Hoods_5_R",
        "L_Fibularis_Brevis_L",
        "L_Fibularis_Brevis_R",
        "L_Flexor_Digitorum_Longus_Tendon_L",
        "L_Flexor_Digitorum_Longus_Tendon_R",
        "L_Flexor_Retinaculum_L",
        "L_Flexor_Retinaculum_R",
        "L_Iliofemoral_Ligament_L",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Inferior_Extensor_Retinaculum_1_L",
        "L_Inferior_Extensor_Retinaculum_2_L",
        "L_Inferior_Extensor_Retinaculum_1_R",
        "L_Inferior_Extensor_Retinaculum_2_R",
        "L_Inferior_Fibular_Retinaculumac_L",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Intermetatarsal_ligament_L",
        "L_Intermetatarsal_ligament_R",
        "L_Interosseous_talocalcaneal_ligament_L",
        "L_Interosseous_talocalcaneal_ligament_R",
        "L_Interosseus_Membrane_L",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_L",
        "L_Ischifemoral_Ligament_R",
        "L_Joining_Capsule_1_of_First_Toe_L",
        "L_Joining_Capsule_2_of_First_Toe_L",
        "L_Joining_Capsule_1_of_Second_Toe_L",
        "L_Joining_Capsule_2_of_Second_Toe_L",
        "L_Joining_Capsule_3_of_Second_Toe_L",
        "L_Joining_Capsule_1_of_Third_Toe_L",
        "L_Joining_Capsule_2_of_Third_Toe_L",
        "L_Joining_Capsule_3_of_Third_Toe_L",
        "L_Joining_Capsule_1_of_Forth_Toe_L",
        "L_Joining_Capsule_2_of_Fourth_Toe_L",
        "L_Joining_Capsule_3_of_Fourth_Toe_L",
        "L_Joining_Capsule_1_of_Fifth_Toe_L",
        "L_Joining_Capsule_2_of_Fifth_Toe_L",
        "L_Joining_Capsule_3_of_Fifth_Toe_L",
        "L_Joining_Capsule_1_of_First_Toe_R",
        "L_Joining_Capsule_2_of_First_Toe_R",
        "L_Joining_Capsule_1_of_Second_Toe_R",
        "L_Joining_Capsule_2_of_Second_Toe_R",
        "L_Joining_Capsule_3_of_Second_Toe_R",
        "L_Joining_Capsule_1_of_Third_Toe_R",
        "L_Joining_Capsule_2_of_Third_Toe_R",
        "L_Joining_Capsule_3_of_Third_Toe_R",
        "L_Joining_Capsule_1_of_Forth_Toe_R",
        "L_Joining_Capsule_2_of_Fourth_Toe_R",
        "L_Joining_Capsule_3_of_Fourth_Toe_R",
        "L_Joining_Capsule_1_of_Fifth_Toe_R",
        "L_Joining_Capsule_2_of_Fifth_Toe_R",
        "L_Joining_Capsule_3_of_Fifth_Toe_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_L",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_L",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_L",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_L",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Lateral_Talocalcaneal_Ligament_L",
        "L_Lateral_Talocalcaneal_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_L",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Lisfranc_Ligament_1_L",
        "L_Lisfranc_Ligament_2_L",
        "L_Lisfranc_Ligament_3_L",
        "L_Lisfranc_Ligament_4_L",
        "L_Lisfranc_Ligament_5_L",
        "L_Lisfranc_Ligament_6_L",
        "L_Lisfranc_Ligament_1_R",
        "L_Lisfranc_Ligament_2_R",
        "L_Lisfranc_Ligament_3_R",
        "L_Lisfranc_Ligament_4_R",
        "L_Lisfranc_Ligament_5_R",
        "L_Lisfranc_Ligament_6_R",
        "L_Medial_Collateral_Ligament_L",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_L",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_L",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_L",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Medial_Talocalcaneal_Ligament_L",
        "L_Medial_Talocalcaneal_Ligament_R",
        "L_Oblique_Popliteal_Ligament_L",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_L",
        "L_Patellar_Ligament_R",
        "L_Pedis_Sheaths_of_First_Toe_L",
        "L_Pedis_Sheaths_of_Second_Toe_L",
        "L_Pedis_Sheaths_of_Third_Toe_L",
        "L_Pedis_Sheaths_of_Fourth_Toe_L",
        "L_Pedis_Sheaths_of_Fifth_Toe_L",
        "L_Pedis_Sheaths_of_First_Toe_R",
        "L_Pedis_Sheaths_of_Second_Toe_R",
        "L_Pedis_Sheaths_of_Third_Toe_R",
        "L_Pedis_Sheaths_of_Fourth_Toe_R",
        "L_Pedis_Sheaths_of_Fifth_Toe_R",
        "L_Plantar_Calcaneonavicular_Ligament_1_L",
        "L_Plantar_Calcaneonavicular_Ligament_2_L",
        "L_Plantar_Calcaneonavicular_Ligament_3_L",
        "L_Plantar_Calcaneonavicular_Ligament_1_R",
        "L_Plantar_Calcaneonavicular_Ligament_2_R",
        "L_Plantar_Calcaneonavicular_Ligament_3_R",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_L",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_R",
        "L_Plantar_Cuboideonavicular_Ligament_L",
        "L_Plantar_Cuboideonavicular_Ligament_R",
        "L_Plantar_Intercuneiform_Ligament_1_L",
        "L_Plantar_Intercuneiform_Ligament_1_L",
        "L_Plantar_Intercuneiform_Ligament_1_R",
        "L_Plantar_Intercuneiform_Ligament_2_R",
        "L_Plantar_Ligament_1_of_First_Toe_L",
        "L_Plantar_Ligament_2_of_First_Toe_L",
        "L_Plantar_Ligament_1_of_Second_Toe_L",
        "L_Plantar_Ligament_2_of_Second_Toe_L",
        "L_Plantar_Ligament_3_of_Second_Toe_L",
        "L_Plantar_Ligament_1_of_Third_Toe_L",
        "L_Plantar_Ligament_2_of_Third_Toe_L",
        "L_Plantar_Ligament_3_of_Third_Toe_L",
        "L_Plantar_Ligament_1_of_Fourth_Toe_L",
        "L_Plantar_Ligament_2_of_Fourth_Toe_L",
        "L_Plantar_Ligament_3_of_Fourth_Toe_L",
        "L_Plantar_Ligament_1_of_Fifth_Toe_L",
        "L_Plantar_Ligament_2_of_Fifth_Toe_L",
        "L_Plantar_Ligament_3_of_Fifth_Toe_L",
        "L_Plantar_Ligament_1_of_First_Toe_R",
        "L_Plantar_Ligament_2_of_First_Toe_R",
        "L_Plantar_Ligament_1_of_Second_Toe_R",
        "L_Plantar_Ligament_2_of_Second_Toe_R",
        "L_Plantar_Ligament_3_of_Second_Toe_R",
        "L_Plantar_Ligament_1_of_Third_Toe_R",
        "L_Plantar_Ligament_2_of_Third_Toe_R",
        "L_Plantar_Ligament_3_of_Third_Toe_R",
        "L_Plantar_Ligament_1_of_Fourth_Toe_R",
        "L_Plantar_Ligament_2_of_Fourth_Toe_R",
        "L_Plantar_Ligament_3_of_Fourth_Toe_R",
        "L_Plantar_Ligament_1_of_Fifth_Toe_R",
        "L_Plantar_Ligament_2_of_Fifth_Toe_R",
        "L_Plantar_Ligament_3_of_Fifth_Toe_R",
        "L_Plantar_Metatarsal_Ligament_1_L",
        "L_Plantar_Metatarsal_Ligament_2_L",
        "L_Plantar_Metatarsal_Ligament_3_L",
        "L_Plantar_Metatarsal_Ligament_4_L",
        "L_Plantar_Metatarsal_Ligament_1_R",
        "L_Plantar_Metatarsal_Ligament_2_R",
        "L_Plantar_Metatarsal_Ligament_3_R",
        "L_Plantar_Metatarsal_Ligament_4_R",
        "L_Plantar_Tarsometatarsal_Ligament_1_L",
        "L_Plantar_Tarsometatarsal_Ligament_2_L",
        "L_Plantar_Tarsometatarsal_Ligament_3_L",
        "L_Plantar_Tarsometatarsal_Ligament_4_L",
        "L_Plantar_Tarsometatarsal_Ligament_1_R",
        "L_Plantar_Tarsometatarsal_Ligament_2_R",
        "L_Plantar_Tarsometatarsal_Ligament_3_R",
        "L_Plantar_Tarsometatarsal_Ligament_4_R",
        "L_Posterior_Cruciate_Ligament_L",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_L",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_L",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_L",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Posterior_Talocalcaneal_Ligament_L",
        "L_Posterior_Talocalcaneal_Ligament_R",
        "L_Posterior_Talofibular_Ligament_L",
        "L_Posterior_Talofibular_Ligament_R",
        "L_Posterior_Tibiofibular_Ligament_L",
        "L_Posterior_Tibiofibular_Ligament_R",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_L",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_L",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "L_Shearh_of_Extensor_Hallucis_Longus_L",
        "L_Shearh_of_Extensor_Hallucis_Longus_R",
        "L_Sheath_of_Flexor_Digitorum_Longus_L",
        "L_Sheath_of_Flexor_Digitorum_Longus_R",
        "L_Sheath_of_Flexor_Hallucis_Longus_L",
        "L_Sheath_of_Flexor_Hallucis_Longus_R",
        "L_Sheath_of_Tibialis_Anterior_L",
        "L_Sheath_of_Tibialis_Anterior_R",
        "L_Sheath_of_Tibialis_Posterior_L",
        "L_Sheath_of_Tibialis_Posterior_R",
        "L_Superficial_Transverse_Metacarpall_L",
        "L_Superficial_Transverse_Metacarpall_R",
        "L_Superior_Extensor_Retinaculum_L",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_L",
        "L_Superior_Fibular_Retinaculum_R",
        "L_Tendon_sheath_L",
        "L_Tendon_sheath_R",
        "L_Tibiocalcaneal_part_of_medial_ligament_L",
        "L_Tibiocalcaneal_part_of_medial_ligament_R",
        "L_Tibionavicular_part_of_medial_ligament_L",
        "L_Tibionavicular_part_of_medial_ligament_R",
        "L_Transverse_Acetabular_Ligament_L",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_L",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_L",
        "L_lliolumbar_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Marginal_Vein_R",
        "Lesser_Saphenous_Vein_R",
        "M_Abductor_Digiti_Minimi_L",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_L",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_L",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_L",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_L",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_L",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_L",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_L",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_L",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_L",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_L",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_L",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_L",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_L",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_L",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_L",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_L",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_L",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_L",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_L",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_L",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Digitorum_Longus_L",
        "M_Flexor_Hallucis_Brevis_L",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_L",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_L",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_L",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_L",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_L",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_L",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_L",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_L",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_L",
        "M_Gracilis_R",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Iliococcygeus_L",
        "M_Iliococcygeus_R",
        "M_Iliotibial_Tract_L",
        "M_Iliotibial_Tract_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_L",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_L",
        "M_Lumbricals_R",
        "M_Obturator_Externus_L",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_L",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_L",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_L",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_L",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_L",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_L",
        "M_Plantaris_R",
        "M_Popliteus_L",
        "M_Popliteus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Psoas_Minor_L",
        "M_Psoas_Minor_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_L",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_L",
        "M_Quadratus_Plantae_R",
        "M_Rectus_Femoris_L",
        "M_Rectus_Femoris_R",
        "M_Sartorius_L",
        "M_Sartorius_R",
        "M_Semimembranosus_L",
        "M_Semimembranosus_R",
        "M_Semitendinosus_L",
        "M_Semitendinosus_R",
        "M_Soleus_L",
        "M_Soleus_R",
        "M_Tendinous_Arch_of_Levator_Ani_L",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_L",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_L",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_L",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_L",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_L",
        "M_Vastus_Medialis_R",
        "Medial_Marginal_Vein_R",
        "Middle_Cluneal_Nerves_R",
        "Plantar_Metatarsal_Arteries_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "M_Plantaris_R",
        "M_Plantaris_L",
        "Saphenous_Nerve_R",
        "Superficial_Epigastric_Artery_R",
        "Superficial_Epigastric_Vein_R",
        "Superficial_Iliac_Circumflex_Artery_R",
        "Superficial_Iliac_Circumflex_Vein_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Cluneal_Nerves_R",
        "Sural_Nerve_R",
        "Tibial_Nerve_R"
      ],
      "easyMeshNames": [
        "Great_Saphenous_Vein_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lesser_Saphenous_Vein_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Saphenous_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Sural_Nerve_R"
      ]
    },
    "prometheus-318-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.03859942961365295,
        "y": 0.6256830961429427,
        "z": 1.7928543820591134
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "Deep_Peroneal_Nerve_R",
        "Dorsal_Metatarsal_Veins_R",
        "Dorsal_Venous_Arch_R",
        "Dorsalis_Metatarsal_Arteries_R",
        "F_Fascia_Lata_L",
        "F_Membranous_Layer_of_Superficial_Fascia_of_Abdomen",
        "F_Penis_Superficial_Fascia",
        "F_Superficial_Perineal__Colles__Fascia",
        "F_Thoracolumbar_Fascia",
        "Femoral_Nerve_R",
        "Great_Saphenous_Vein_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Clunial_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L_Anococcygeal_Ligament",
        "L_Anococcygeal_Raphe",
        "L_Anterior_Cruciate_Ligament_L",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_L",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_L",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_L",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Anterior_Talofibular_Ligament_L",
        "L_Anterior_Talofibular_Ligament_R",
        "L_Anterior_Tibiofibular_Ligament_L",
        "L_Anterior_Tibiofibular_Ligament_R",
        "L_Anterior_Tibiotalar_Part_L",
        "L_Anterior_Tibiotalar_Part_R",
        "L_Arcuate_Popliteal_Ligament_L",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_L",
        "L_Articular_Joint_Capsule_R",
        "L_Calcaneocuboidea_Plantare_L",
        "L_Calcaneocuboidea_Plantare_R",
        "L_Calcaneofibular_Ligament_L",
        "L_Calcaneofibular_Ligament_R",
        "L_Cervical_Ligament_1_L",
        "L_Cervical_Ligament_2_L",
        "L_Cervical_Ligament_3_L",
        "L_Cervical_Ligament_1_R",
        "L_Cervical_Ligament_2_R",
        "L_Cervical_Ligament_3_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_First_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_First_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_Second_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_Second_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_3_of_Second_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_Third_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_Third_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_3_of_Third_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fourth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fourth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fourth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fifth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fifth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fifth_Toe_L",
        "L_Collateral_Interphalangial_Ligaments_1_of_First_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_First_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Second_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Second_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Second_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Third_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Third_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Third_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fourth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fourth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fourth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fifth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fifth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fifth_Toe_R",
        "L_Cruciform_Ligament_1_of_First_Toe_L",
        "L_Cruciform_Ligament_2_of_First_Toe_L",
        "L_Cruciform_Ligament_3_of_First_Toe_L",
        "L_Cruciform_Ligament_4_of_First_Toe_L",
        "L_Cruciform_Ligament_5_of_First_Toe_L",
        "L_Cruciform_Ligament_6_of_First_Toe_L",
        "L_Cruciform_Ligament_1_of_Second_Toe_L",
        "L_Cruciform_Ligament_2_of_Second_Toe_L",
        "L_Cruciform_Ligament_3_of_Second_Toe_L",
        "L_Cruciform_Ligament_4_of_Second_Toe_L",
        "L_Cruciform_Ligament_5_of_Second_Toe_L",
        "L_Cruciform_Ligament_6_of_Second_Toe_L",
        "L_Cruciform_Ligament_7_of_Second_Toe_L",
        "L_Cruciform_Ligament_1_of_Third_Toe_L",
        "L_Cruciform_Ligament_2_of_Third_Toe_L",
        "L_Cruciform_Ligament_3_of_Third_Toe_L",
        "L_Cruciform_Ligament_4_of_Third_Toe_L",
        "L_Cruciform_Ligament_5_of_Third_Toe_L",
        "L_Cruciform_Ligament_6_of_Third_Toe_L",
        "L_Cruciform_Ligament_7_of_Third_Toe_L",
        "L_Cruciform_Ligament_1_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_2_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_3_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_4_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_5_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_6_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_7_of_Fourth_Toe_L",
        "L_Cruciform_Ligament_1_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_2_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_3_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_4_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_5_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_6_of_Fifth_Toe_L",
        "L_Cruciform_Ligament_7_of_Fifth_Toe_L",
        "L_Cuneo_Cuboideum_Plantare_Ligament_L",
        "L_Cuneo_Cuboideum_Plantare_Ligament_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Deep_Transverse_Metatarsal_Ligament_1_L",
        "L_Deep_Transverse_Metatarsal_Ligament_2_L",
        "L_Deep_Transverse_Metatarsal_Ligament_3_L",
        "L_Deep_Transverse_Metatarsal_Ligament_4_L",
        "L_Deep_Transverse_Metatarsal_Ligament_1_R",
        "L_Deep_Transverse_Metatarsal_Ligament_2_R",
        "L_Deep_Transverse_Metatarsal_Ligament_3_R",
        "L_Deep_Transverse_Metatarsal_Ligament_4_R",
        "L_Dorsal_Calcaneocuboid_Ligament_1_L",
        "L_Dorsal_Calcaneocuboid_Ligament_2_L",
        "L_Dorsal_Calcaneocuboid_Ligament_1_R",
        "L_Dorsal_Calcaneocuboid_Ligament_2_R",
        "L_Dorsal_Cuboideonavicular_Ligament_L",
        "L_Dorsal_Cuboideonavicular_Ligament_R",
        "L_Dorsal_Cuneocuboid_Ligament_1_L",
        "L_Dorsal_Cuneocuboid_Ligament_2_L",
        "L_Dorsal_Cuneocuboid_Ligament_1_R",
        "L_Dorsal_Cuneocuboid_Ligament_2_R",
        "L_Dorsal_Cuneonavicular_Ligament_1_L",
        "L_Dorsal_Cuneonavicular_Ligament_2_L",
        "L_Dorsal_Cuneonavicular_Ligament_3_L",
        "L_Dorsal_Cuneonavicular_Ligament_1_R",
        "L_Dorsal_Cuneonavicular_Ligament_2_R",
        "L_Dorsal_Cuneonavicular_Ligament_3_R",
        "L_Dorsal_Metatarsal_Ligaments_1_L",
        "L_Dorsal_Metatarsal_Ligaments_2_L",
        "L_Dorsal_Metatarsal_Ligaments_3_L",
        "L_Dorsal_Metatarsal_Ligaments_4_L",
        "L_Dorsal_Metatarsal_Ligaments_1_R",
        "L_Dorsal_Metatarsal_Ligaments_2_R",
        "L_Dorsal_Metatarsal_Ligaments_3_R",
        "L_Dorsal_Metatarsal_Ligaments_4_R",
        "L_Dorsal_Talonavicular_Ligament_L",
        "L_Dorsal_Talonavicular_Ligament_R",
        "L_Dorsal_Tarsal_Ligament_1_R",
        "L_Dorsal_Tarsal_Ligament_2_R",
        "L_Dorsal_Tarsal_Ligament_1_L",
        "L_Dorsal_Tarsal_Ligament_2_L",
        "L_Dorsal_Tarsometatarsal_Ligament_1_L",
        "L_Dorsal_Tarsometatarsal_Ligament_2_L",
        "L_Dorsal_Tarsometatarsal_Ligament_3_L",
        "L_Dorsal_Tarsometatarsal_Ligament_4_L",
        "L_Dorsal_Tarsometatarsal_Ligament_5_L",
        "L_Dorsal_Tarsometatarsal_Ligament_6_L",
        "L_Dorsal_Tarsometatarsal_Ligament_7_L",
        "L_Dorsal_Tarsometatarsal_Ligament_8_L",
        "L_Dorsal_Tarsometatarsal_Ligament_1_R",
        "L_Dorsal_Tarsometatarsal_Ligament_2_R",
        "L_Dorsal_Tarsometatarsal_Ligament_3_R",
        "L_Dorsal_Tarsometatarsal_Ligament_4_R",
        "L_Dorsal_Tarsometatarsal_Ligament_5_R",
        "L_Dorsal_Tarsometatarsal_Ligament_6_R",
        "L_Dorsal_Tarsometatarsal_Ligament_7_R",
        "L_Dorsal_Tarsometatarsal_Ligament_8_R",
        "L_Extensor_Hoods_1_L",
        "L_Extensor_Hoods_2_L",
        "L_Extensor_Hoods_3_L",
        "L_Extensor_Hoods_4_L",
        "L_Extensor_Hoods_5_L",
        "L_Extensor_Hoods_1_R",
        "L_Extensor_Hoods_2_R",
        "L_Extensor_Hoods_3_R",
        "L_Extensor_Hoods_4_R",
        "L_Extensor_Hoods_5_R",
        "L_Fibularis_Brevis_L",
        "L_Fibularis_Brevis_R",
        "L_Flexor_Digitorum_Longus_Tendon_L",
        "L_Flexor_Digitorum_Longus_Tendon_R",
        "L_Flexor_Retinaculum_L",
        "L_Flexor_Retinaculum_R",
        "L_Iliofemoral_Ligament_L",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Inferior_Extensor_Retinaculum_1_L",
        "L_Inferior_Extensor_Retinaculum_2_L",
        "L_Inferior_Extensor_Retinaculum_1_R",
        "L_Inferior_Extensor_Retinaculum_2_R",
        "L_Inferior_Fibular_Retinaculumac_L",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Intermetatarsal_ligament_L",
        "L_Intermetatarsal_ligament_R",
        "L_Interosseous_talocalcaneal_ligament_L",
        "L_Interosseous_talocalcaneal_ligament_R",
        "L_Interosseus_Membrane_L",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_L",
        "L_Ischifemoral_Ligament_R",
        "L_Joining_Capsule_1_of_First_Toe_L",
        "L_Joining_Capsule_2_of_First_Toe_L",
        "L_Joining_Capsule_1_of_Second_Toe_L",
        "L_Joining_Capsule_2_of_Second_Toe_L",
        "L_Joining_Capsule_3_of_Second_Toe_L",
        "L_Joining_Capsule_1_of_Third_Toe_L",
        "L_Joining_Capsule_2_of_Third_Toe_L",
        "L_Joining_Capsule_3_of_Third_Toe_L",
        "L_Joining_Capsule_1_of_Forth_Toe_L",
        "L_Joining_Capsule_2_of_Fourth_Toe_L",
        "L_Joining_Capsule_3_of_Fourth_Toe_L",
        "L_Joining_Capsule_1_of_Fifth_Toe_L",
        "L_Joining_Capsule_2_of_Fifth_Toe_L",
        "L_Joining_Capsule_3_of_Fifth_Toe_L",
        "L_Joining_Capsule_1_of_First_Toe_R",
        "L_Joining_Capsule_2_of_First_Toe_R",
        "L_Joining_Capsule_1_of_Second_Toe_R",
        "L_Joining_Capsule_2_of_Second_Toe_R",
        "L_Joining_Capsule_3_of_Second_Toe_R",
        "L_Joining_Capsule_1_of_Third_Toe_R",
        "L_Joining_Capsule_2_of_Third_Toe_R",
        "L_Joining_Capsule_3_of_Third_Toe_R",
        "L_Joining_Capsule_1_of_Forth_Toe_R",
        "L_Joining_Capsule_2_of_Fourth_Toe_R",
        "L_Joining_Capsule_3_of_Fourth_Toe_R",
        "L_Joining_Capsule_1_of_Fifth_Toe_R",
        "L_Joining_Capsule_2_of_Fifth_Toe_R",
        "L_Joining_Capsule_3_of_Fifth_Toe_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_L",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_L",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_L",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_L",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Lateral_Talocalcaneal_Ligament_L",
        "L_Lateral_Talocalcaneal_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_L",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Lisfranc_Ligament_1_L",
        "L_Lisfranc_Ligament_2_L",
        "L_Lisfranc_Ligament_3_L",
        "L_Lisfranc_Ligament_4_L",
        "L_Lisfranc_Ligament_5_L",
        "L_Lisfranc_Ligament_6_L",
        "L_Lisfranc_Ligament_1_R",
        "L_Lisfranc_Ligament_2_R",
        "L_Lisfranc_Ligament_3_R",
        "L_Lisfranc_Ligament_4_R",
        "L_Lisfranc_Ligament_5_R",
        "L_Lisfranc_Ligament_6_R",
        "L_Medial_Collateral_Ligament_L",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_L",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_L",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_L",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Medial_Talocalcaneal_Ligament_L",
        "L_Medial_Talocalcaneal_Ligament_R",
        "L_Oblique_Popliteal_Ligament_L",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_L",
        "L_Patellar_Ligament_R",
        "L_Pedis_Sheaths_of_First_Toe_L",
        "L_Pedis_Sheaths_of_Second_Toe_L",
        "L_Pedis_Sheaths_of_Third_Toe_L",
        "L_Pedis_Sheaths_of_Fourth_Toe_L",
        "L_Pedis_Sheaths_of_Fifth_Toe_L",
        "L_Pedis_Sheaths_of_First_Toe_R",
        "L_Pedis_Sheaths_of_Second_Toe_R",
        "L_Pedis_Sheaths_of_Third_Toe_R",
        "L_Pedis_Sheaths_of_Fourth_Toe_R",
        "L_Pedis_Sheaths_of_Fifth_Toe_R",
        "L_Plantar_Calcaneonavicular_Ligament_1_L",
        "L_Plantar_Calcaneonavicular_Ligament_2_L",
        "L_Plantar_Calcaneonavicular_Ligament_3_L",
        "L_Plantar_Calcaneonavicular_Ligament_1_R",
        "L_Plantar_Calcaneonavicular_Ligament_2_R",
        "L_Plantar_Calcaneonavicular_Ligament_3_R",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_L",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_R",
        "L_Plantar_Cuboideonavicular_Ligament_L",
        "L_Plantar_Cuboideonavicular_Ligament_R",
        "L_Plantar_Intercuneiform_Ligament_1_L",
        "L_Plantar_Intercuneiform_Ligament_2_L",
        "L_Plantar_Intercuneiform_Ligament_1_R",
        "L_Plantar_Intercuneiform_Ligament_2_R",
        "L_Plantar_Ligament_1_of_First_Toe_L",
        "L_Plantar_Ligament_2_of_First_Toe_L",
        "L_Plantar_Ligament_1_of_Second_Toe_L",
        "L_Plantar_Ligament_2_of_Second_Toe_L",
        "L_Plantar_Ligament_3_of_Second_Toe_L",
        "L_Plantar_Ligament_1_of_Third_Toe_L",
        "L_Plantar_Ligament_2_of_Third_Toe_L",
        "L_Plantar_Ligament_3_of_Third_Toe_L",
        "L_Plantar_Ligament_1_of_Fourth_Toe_L",
        "L_Plantar_Ligament_2_of_Fourth_Toe_L",
        "L_Plantar_Ligament_3_of_Fourth_Toe_L",
        "L_Plantar_Ligament_1_of_Fifth_Toe_L",
        "L_Plantar_Ligament_2_of_Fifth_Toe_L",
        "L_Plantar_Ligament_3_of_Fifth_Toe_L",
        "L_Plantar_Ligament_1_of_First_Toe_R",
        "L_Plantar_Ligament_2_of_First_Toe_R",
        "L_Plantar_Ligament_1_of_Second_Toe_R",
        "L_Plantar_Ligament_2_of_Second_Toe_R",
        "L_Plantar_Ligament_3_of_Second_Toe_R",
        "L_Plantar_Ligament_1_of_Third_Toe_R",
        "L_Plantar_Ligament_2_of_Third_Toe_R",
        "L_Plantar_Ligament_3_of_Third_Toe_R",
        "L_Plantar_Ligament_1_of_Fourth_Toe_R",
        "L_Plantar_Ligament_2_of_Fourth_Toe_R",
        "L_Plantar_Ligament_3_of_Fourth_Toe_R",
        "L_Plantar_Ligament_1_of_Fifth_Toe_R",
        "L_Plantar_Ligament_2_of_Fifth_Toe_R",
        "L_Plantar_Ligament_3_of_Fifth_Toe_R",
        "L_Plantar_Metatarsal_Ligament_1_L",
        "L_Plantar_Metatarsal_Ligament_2_L",
        "L_Plantar_Metatarsal_Ligament_3_L",
        "L_Plantar_Metatarsal_Ligament_4_L",
        "L_Plantar_Metatarsal_Ligament_1_R",
        "L_Plantar_Metatarsal_Ligament_2_R",
        "L_Plantar_Metatarsal_Ligament_3_R",
        "L_Plantar_Metatarsal_Ligament_4_R",
        "L_Plantar_Tarsometatarsal_Ligament_1_L",
        "L_Plantar_Tarsometatarsal_Ligament_2_L",
        "L_Plantar_Tarsometatarsal_Ligament_3_L",
        "L_Plantar_Tarsometatarsal_Ligament_4_L",
        "L_Plantar_Tarsometatarsal_Ligament_1_R",
        "L_Plantar_Tarsometatarsal_Ligament_2_R",
        "L_Plantar_Tarsometatarsal_Ligament_3_R",
        "L_Plantar_Tarsometatarsal_Ligament_4_R",
        "L_Posterior_Cruciate_Ligament_L",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_L",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_L",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_L",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Posterior_Talocalcaneal_Ligament_L",
        "L_Posterior_Talocalcaneal_Ligament_R",
        "L_Posterior_Talofibular_Ligament_L",
        "L_Posterior_Talofibular_Ligament_R",
        "L_Posterior_Tibiofibular_Ligament_L",
        "L_Posterior_Tibiofibular_Ligament_R",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_L",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_L",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "L_Shearh_of_Extensor_Hallucis_Longus_L",
        "L_Shearh_of_Extensor_Hallucis_Longus_R",
        "L_Sheath_of_Flexor_Digitorum_Longus_L",
        "L_Sheath_of_Flexor_Digitorum_Longus_R",
        "L_Sheath_of_Flexor_Hallucis_Longus_L",
        "L_Sheath_of_Flexor_Hallucis_Longus_R",
        "L_Sheath_of_Tibialis_Anterior_L",
        "L_Sheath_of_Tibialis_Anterior_R",
        "L_Sheath_of_Tibialis_Posterior_L",
        "L_Sheath_of_Tibialis_Posterior_R",
        "L_Superficial_Transverse_Metacarpall_L",
        "L_Superficial_Transverse_Metacarpall_R",
        "L_Superior_Extensor_Retinaculum_L",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_L",
        "L_Superior_Fibular_Retinaculum_R",
        "L_Tendon_sheath_L",
        "L_Tendon_sheath_R",
        "L_Tibiocalcaneal_part_of_medial_ligament_L",
        "L_Tibiocalcaneal_part_of_medial_ligament_R",
        "L_Tibionavicular_part_of_medial_ligament_L",
        "L_Tibionavicular_part_of_medial_ligament_R",
        "L_Transverse_Acetabular_Ligament_L",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_L",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_L",
        "L_lliolumbar_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Marginal_Vein_R",
        "Lesser_Saphenous_Vein_R",
        "M_Abductor_Digiti_Minimi_L",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_L",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_L",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_L",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_L",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_L",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_L",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_L",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_L",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_L",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_L",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_L",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_L",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_L",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_L",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_L",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_L",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_L",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_L",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_L",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_L",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Digitorum_Longus_L",
        "M_Flexor_Hallucis_Brevis_L",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_L",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_L",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_L",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_L",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_L",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_L",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_L",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_L",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_L",
        "M_Gracilis_R",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Iliococcygeus_L",
        "M_Iliococcygeus_R",
        "M_Iliotibial_Tract_L",
        "M_Iliotibial_Tract_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_L",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_L",
        "M_Lumbricals_R",
        "M_Obturator_Externus_L",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_L",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_L",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_L",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_L",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_L",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_L",
        "M_Plantaris_R",
        "M_Popliteus_L",
        "M_Popliteus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Psoas_Minor_L",
        "M_Psoas_Minor_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_L",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_L",
        "M_Quadratus_Plantae_R",
        "M_Rectus_Femoris_L",
        "M_Rectus_Femoris_R",
        "M_Sartorius_L",
        "M_Sartorius_R",
        "M_Semimembranosus_L",
        "M_Semimembranosus_R",
        "M_Semitendinosus_L",
        "M_Semitendinosus_R",
        "M_Soleus_L",
        "M_Soleus_R",
        "M_Tendinous_Arch_of_Levator_Ani_L",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_L",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_L",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_L",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_L",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_L",
        "M_Vastus_Medialis_R",
        "Medial_Marginal_Vein_R",
        "Middle_Cluneal_Nerves_R",
        "Plantar_Metatarsal_Arteries_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "M_Plantaris_R",
        "M_Plantaris_L",
        "Saphenous_Nerve_R",
        "Superficial_Epigastric_Artery_R",
        "Superficial_Epigastric_Vein_R",
        "Superficial_Iliac_Circumflex_Artery_R",
        "Superficial_Iliac_Circumflex_Vein_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Cluneal_Nerves_R",
        "Sural_Nerve_R",
        "Tibial_Nerve_R"
      ],
      "easyMeshNames": ["Deep_Peroneal_Nerve_R"]
    },
    "prometheus-320-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.7752143646906743,
        "y": 1.114515779958084,
        "z": 0.5426354641907125
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Femur_Head_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Piriformis_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Piriformis_R"
      ]
    },
    "prometheus-321-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.1307878726044049,
        "y": 0.8212494107137923,
        "z": -0.9952051388622702
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "C_Lateral_meniscus_R",
        "C_Medial_meniscus_L",
        "Common_Iliac_Artery_R",
        "Deep_Circumflex_Iliac_Artery_R",
        "Deep_External_Pudendal_Artery_R",
        "Deep_Femoral_Artery_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_Auricular_Fascia_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "Internal_Iliac_Artery_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Femoral_Circumflex_Ascending_R",
        "Lateral_Femoral_Circumflex_Descending_R",
        "Lateral_Femoral_Circumflex_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Coccigeus_Muscle_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Popliteus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Femoris_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Superior_Auricular_Muscle_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Medial_circumflex_femoral_Artery_R",
        "Obturator_Nerve_R",
        "Pudendal_Artery_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Iliac_Circumflex_Artery_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Obturator_Nerve_R",
        "Sciatic_Nerve_R"
      ]
    },
    "prometheus-321-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.005356110396625972,
        "y": 1.1259724650701433,
        "z": -0.9679543371735905
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "C_Lateral_meniscus_R",
        "C_Medial_meniscus_L",
        "Common_Iliac_Artery_R",
        "Deep_Circumflex_Iliac_Artery_R",
        "Deep_External_Pudendal_Artery_R",
        "Deep_Femoral_Artery_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_Auricular_Fascia_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "Internal_Iliac_Artery_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Femoral_Circumflex_Ascending_R",
        "Lateral_Femoral_Circumflex_Descending_R",
        "Lateral_Femoral_Circumflex_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Coccigeus_Muscle_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Popliteus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Femoris_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Superior_Auricular_Muscle_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Medial_circumflex_femoral_Artery_R",
        "Obturator_Nerve_R",
        "Pudendal_Artery_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Iliac_Circumflex_Artery_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "Deep_Femoral_Artery_R",
        "Femoral_Artery_R",
        "Femoral_Vein_R",
        "Lateral_Femoral_Circumflex_Ascending_R",
        "Lateral_Femoral_Circumflex_R",
        "Medial_circumflex_femoral_Artery_R",
        "Saphenous_Nerve_R"
      ]
    },
    "prometheus-322-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.23302487896442908,
        "y": 1.1277801829881766,
        "z": 1.1289810312403648
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "C_Lateral_meniscus_R",
        "C_Medial_meniscus_L",
        "Common_Iliac_Artery_R",
        "Common_Iliac_Vein_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Femoral_Artery_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "Femoral_Artery_R",
        "Femoral_Vein_R",
        "Inferior_Gluteal_Artery_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferior_Gluteal_Vein_R_03",
        "Internal_Iliac_Artery_R",
        "Internal_Iliac_Vein_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Iliofemoral_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_R",
        "Lateral_Femoral_Circumflex_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Coccigeus_Muscle_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Popliteus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Femoris_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Medial_circumflex_femoral_Artery_R",
        "Popliteal_Artery_R",
        "Popliteal_Vein_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Artery_R",
        "Pudendal_Nerve_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superior_Gluteal_Artery_R",
        "Superior_Gluteal_Nerve_R",
        "Superior_Gluteal_Vein_R",
        "Tibial_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "Lateral_Femoral_Circumflex_R",
        "M_Gluteus_Minimus_R",
        "Popliteal_Artery_R",
        "Popliteal_Vein_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Sciatic_Nerve_R",
        "Superior_Gluteal_Artery_R",
        "Superior_Gluteal_Nerve_R",
        "Superior_Gluteal_Vein_R"
      ]
    },
    "prometheus-331-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.021955447717605006,
        "y": 0.8602307842530676,
        "z": -1.5220304989908682
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Coccyx",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L5",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sacrum",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Cuboid_Calcaneus_R",
        "C_Articular_Surface_Cuboid_of_Lateral_Cuneiform_R",
        "C_Articular_Surface_Cuboid_of_Navicular_R",
        "C_Articular_Surface_Distal_Middle_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Distal_Middle_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Distal_Middle_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Distal_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Distal_Phalanx_Great_Toe_R",
        "C_Articular_Surface_Distal_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Distal_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Distal_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Distal_Talus_R",
        "C_Articular_Surface_Distal_Tibia_R",
        "C_Articular_Surface_Distal_of_Cuboid_R",
        "C_Articular_Surface_Distal_of_Fifth_Metatarsal_R",
        "C_Articular_Surface_Distal_of_Fourth_Metatarsal_R",
        "C_Articular_Surface_Distal_of_Lateral_Cuneiform_R",
        "C_Articular_Surface_Distal_of_Middle_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Distal_of_Navicular_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Great_Toe_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Distal_of_Second_Metatarsal_R",
        "C_Articular_Surface_Distal_of_Third_Metatarsal_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Head_of_First_Metatarsal_R",
        "C_Articular_Surface_Lateral_Cuneiform_of_Cuboid_R",
        "C_Articular_Surface_Lateral_Malleolus_R",
        "C_Articular_Surface_Middle_Calcaneal_R",
        "C_Articular_Surface_Middle_Talar_R",
        "C_Articular_Surface_Navicular_of_Cuboid_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Posterior_Calcaneal_R",
        "C_Articular_Surface_Posterior_Talar_R",
        "C_Articular_Surface_Proximal_Cuboid_R",
        "C_Articular_Surface_Proximal_Talus_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Articular_Surface_Proximal_of_Fifth_Metatarsal_R",
        "C_Articular_Surface_Proximal_of_Fourth_Metatarsal_R",
        "C_Articular_Surface_Proximal_of_Lateral_Cuneiform_R",
        "C_Articular_Surface_Proximal_of_Navicular_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Great_Toe_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Proximal_of_Third_Metatarsal_R",
        "C_Articular_Surface_Proximall_of_First_Metatarsal_R",
        "C_Articular_Surface_Proximall_of_Middle_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Proximall_of_Middle_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Proximall_of_Middle_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Proximall_of_Middle_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Proximall_of_Second_Metatarsal_R",
        "C_Articular_Surface_Proximolateral_Distal_of_Medial_Cuneiform_R",
        "C_Articular_Surface_of_Lateral_Cuneiform_R",
        "C_Femur_Head_R",
        "C_Lateral_meniscus_R",
        "C_Medial_meniscus_L",
        "F_Deep_Posterior_Compartment_R",
        "F_Lateral_Compartment_R",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Anterior_Talofibular_Ligament_R",
        "L_Anterior_Tibiofibular_Ligament_R",
        "L_Anterior_Tibiotalar_Part_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Calcaneocuboidea_Plantare_R",
        "L_Calcaneofibular_Ligament_R",
        "L_Cervical_Ligament_1_R",
        "L_Cervical_Ligament_2_R",
        "L_Cervical_Ligament_3_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_First_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_First_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Second_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Second_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Second_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Third_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Third_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Third_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fourth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fourth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fourth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_1_of_Fifth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_2_of_Fifth_Toe_R",
        "L_Collateral_Interphalangial_Ligaments_3_of_Fifth_Toe_R",
        "L_Cuneo_Cuboideum_Plantare_Ligament_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Deep_Transverse_Metatarsal_Ligament_1_R",
        "L_Deep_Transverse_Metatarsal_Ligament_2_R",
        "L_Deep_Transverse_Metatarsal_Ligament_3_R",
        "L_Deep_Transverse_Metatarsal_Ligament_4_R",
        "L_Dorsal_Calcaneocuboid_Ligament_1_R",
        "L_Dorsal_Calcaneocuboid_Ligament_2_R",
        "L_Dorsal_Cuboideonavicular_Ligament_R",
        "L_Dorsal_Cuneocuboid_Ligament_1_R",
        "L_Dorsal_Cuneocuboid_Ligament_2_R",
        "L_Dorsal_Cuneonavicular_Ligament_1_R",
        "L_Dorsal_Cuneonavicular_Ligament_2_R",
        "L_Dorsal_Cuneonavicular_Ligament_3_R",
        "L_Dorsal_Metatarsal_Ligaments_1_R",
        "L_Dorsal_Metatarsal_Ligaments_2_R",
        "L_Dorsal_Metatarsal_Ligaments_3_R",
        "L_Dorsal_Metatarsal_Ligaments_4_R",
        "L_Dorsal_Talonavicular_Ligament_R",
        "L_Dorsal_Tarsal_Ligament_1_R",
        "L_Dorsal_Tarsal_Ligament_2_R",
        "L_Dorsal_Tarsometatarsal_Ligament_1_R",
        "L_Dorsal_Tarsometatarsal_Ligament_2_R",
        "L_Dorsal_Tarsometatarsal_Ligament_3_R",
        "L_Dorsal_Tarsometatarsal_Ligament_4_R",
        "L_Dorsal_Tarsometatarsal_Ligament_5_R",
        "L_Dorsal_Tarsometatarsal_Ligament_6_R",
        "L_Dorsal_Tarsometatarsal_Ligament_7_R",
        "L_Dorsal_Tarsometatarsal_Ligament_8_R",
        "L_Extensor_Hoods_1_R",
        "L_Extensor_Hoods_2_R",
        "L_Extensor_Hoods_3_R",
        "L_Extensor_Hoods_4_R",
        "L_Extensor_Hoods_5_R",
        "L_Fibularis_Brevis_R",
        "L_Flexor_Digitorum_Longus_Tendon_R",
        "L_Flexor_Retinaculum_R",
        "L_Iliofemoral_Ligament_R",
        "L_Inferior_Extensor_Retinaculum_1_R",
        "L_Inferior_Extensor_Retinaculum_2_R",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Inguinal_Ligament_R",
        "L_Intermetatarsal_ligament_R",
        "L_Interosseous_talocalcaneal_ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Joining_Capsule_1_of_First_Toe_R",
        "L_Joining_Capsule_2_of_First_Toe_R",
        "L_Joining_Capsule_1_of_Second_Toe_R",
        "L_Joining_Capsule_2_of_Second_Toe_R",
        "L_Joining_Capsule_3_of_Second_Toe_R",
        "L_Joining_Capsule_1_of_Third_Toe_R",
        "L_Joining_Capsule_2_of_Third_Toe_R",
        "L_Joining_Capsule_3_of_Third_Toe_R",
        "L_Joining_Capsule_1_of_Forth_Toe_R",
        "L_Joining_Capsule_2_of_Fourth_Toe_R",
        "L_Joining_Capsule_3_of_Fourth_Toe_R",
        "L_Joining_Capsule_1_of_Fifth_Toe_R",
        "L_Joining_Capsule_2_of_Fifth_Toe_R",
        "L_Joining_Capsule_3_of_Fifth_Toe_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Lateral_Talocalcaneal_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Lisfranc_Ligament_1_R",
        "L_Lisfranc_Ligament_2_R",
        "L_Lisfranc_Ligament_3_R",
        "L_Lisfranc_Ligament_4_R",
        "L_Lisfranc_Ligament_5_R",
        "L_Lisfranc_Ligament_6_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Medial_Talocalcaneal_Ligament_R",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Pedis_Sheaths_of_First_Toe_R",
        "L_Pedis_Sheaths_of_Second_Toe_R",
        "L_Pedis_Sheaths_of_Third_Toe_R",
        "L_Pedis_Sheaths_of_Fourth_Toe_R",
        "L_Pedis_Sheaths_of_Fifth_Toe_R",
        "L_Plantar_Calcaneonavicular_Ligament_1_R",
        "L_Plantar_Calcaneonavicular_Ligament_2_R",
        "L_Plantar_Calcaneonavicular_Ligament_3_R",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_R",
        "L_Plantar_Cuboideonavicular_Ligament_R",
        "L_Plantar_Intercuneiform_Ligament_1_R",
        "L_Plantar_Intercuneiform_Ligament_2_R",
        "L_Plantar_Ligament_1_of_First_Toe_R",
        "L_Plantar_Ligament_2_of_First_Toe_R",
        "L_Plantar_Ligament_1_of_Second_Toe_R",
        "L_Plantar_Ligament_2_of_Second_Toe_R",
        "L_Plantar_Ligament_3_of_Second_Toe_R",
        "L_Plantar_Ligament_1_of_Third_Toe_R",
        "L_Plantar_Ligament_2_of_Third_Toe_R",
        "L_Plantar_Ligament_3_of_Third_Toe_R",
        "L_Plantar_Ligament_1_of_Fourth_Toe_R",
        "L_Plantar_Ligament_2_of_Fourth_Toe_R",
        "L_Plantar_Ligament_3_of_Fourth_Toe_R",
        "L_Plantar_Ligament_1_of_Fifth_Toe_R",
        "L_Plantar_Ligament_2_of_Fifth_Toe_R",
        "L_Plantar_Ligament_3_of_Fifth_Toe_R",
        "L_Plantar_Metatarsal_Ligament_1_R",
        "L_Plantar_Metatarsal_Ligament_2_R",
        "L_Plantar_Metatarsal_Ligament_3_R",
        "L_Plantar_Metatarsal_Ligament_4_R",
        "L_Plantar_Tarsometatarsal_Ligament_1_R",
        "L_Plantar_Tarsometatarsal_Ligament_2_R",
        "L_Plantar_Tarsometatarsal_Ligament_3_R",
        "L_Plantar_Tarsometatarsal_Ligament_4_R",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Posterior_Talocalcaneal_Ligament_R",
        "L_Posterior_Talofibular_Ligament_R",
        "L_Posterior_Tibiofibular_Ligament_R",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_Shearh_of_Extensor_Hallucis_Longus_R",
        "L_Sheath_of_Flexor_Digitorum_Longus_R",
        "L_Sheath_of_Flexor_Hallucis_Longus_R",
        "L_Sheath_of_Tibialis_Anterior_R",
        "L_Sheath_of_Tibialis_Posterior_R",
        "L_Superficial_Transverse_Metacarpall_R",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_R",
        "L_Tendon_sheath_R",
        "L_Tibiocalcaneal_part_of_medial_ligament_R",
        "L_Tibionavicular_part_of_medial_ligament_R",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Coccigeus_Muscle_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_R",
        "M_Popliteus_R",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "M_Plantaris_R"
      ],
      "easyMeshNames": [
        "B_Tibia_R",
        "M_Adductor_Longus_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Iliotibial_Tract_R",
        "M_Rectus_Femoris_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Lateralis_R"
      ]
    },
    "prometheus-96-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.31683387005876434,
        "y": 1.0250594580327057,
        "z": -0.058395269853857255
      },
      "visibleMeshNames": ["B_Hipbone_R"],
      "easyMeshNames": ["B_Hipbone_R"]
    },
    "prometheus-97-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.017757425010894588,
        "y": 1.3174119048432253,
        "z": -0.40419003600223213
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Sacrum",
        "L_Pubic_Symphysis"
      ],
      "easyMeshNames": ["B_Hipbone_R", "B_Sacrum"]
    },
    "prometheus-98-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.00032618335858620616,
        "y": 0.9436839138098243,
        "z": -0.29999994630323934
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Acetabulum_Lunate_Surface_R",
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_L",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_L",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_L",
        "L_lliolumbar_Ligament_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_lliolumbar_Ligament_R"
      ]
    },
    "prometheus-197": {
      "sideMode": "Unmodified",
      "visibleMeshNames": [
        "B_Humerus_R",
        "B_Radius_R",
        "B_Ulna_R",
        "L_Annular_Ligament_of_Radius_R",
        "L_Articular_Surface_Humeroulnar_R",
        "L_Radial_Collateral_Ligament_R",
        "L_Ulnar_Collateral_Ligament_R"
      ],
      "easyMeshNames": [
        "B_Humerus_R",
        "L_Annular_Ligament_of_Radius_R",
        "L_Radial_Collateral_Ligament_R",
        "L_Ulnar_Collateral_Ligament_R"
      ]
    },
    "prometheus-241": {
      "sideMode": "Unmodified",
      "visibleMeshNames": [
        "Anterior_Interosseous_Artery_R",
        "Anterior_Interosseous_Nerve_R",
        "B_Capitate_R",
        "B_Distal_Phalanx_1_R",
        "B_Distal_Phalanx_2_R",
        "B_Distal_Phalanx_3_R",
        "B_Distal_Phalanx_4_R",
        "B_Distal_Phalanx_5_R",
        "B_Hamate_R",
        "B_Lunate_R",
        "B_Metacarpal_1_R",
        "B_Metacarpal_2_R",
        "B_Metacarpal_3_R",
        "B_Metacarpal_4_R",
        "B_Metacarpal_5_R",
        "B_Middle_Phalanx_2_R",
        "B_Middle_Phalanx_3_R",
        "B_Middle_Phalanx_4_R",
        "B_Middle_Phalanx_5_R",
        "B_Pisiform_R",
        "B_Proximal_Phalanx_1_R",
        "B_Proximal_Phalanx_2_R",
        "B_Proximal_Phalanx_3_R",
        "B_Proximal_Phalanx_4_R",
        "B_Proximal_Phalanx_5_R",
        "B_Radius_R",
        "B_Sesamoid_hand_R",
        "B_Styloid_R",
        "B_Trapezium_R",
        "B_Trapezoid_R",
        "B_Triquetrum_R",
        "B_Ulna_R",
        "Branch_to_Medial_Head_of_Triceps_and_Anconeus_R",
        "Common_Interosseous_Artery_R",
        "Common_Palmar_Digital_Nerves_R",
        "Communicating_Branch_of_Ulnar_Nerve_R",
        "Deep_Branch_of_Radial_Nerve_R",
        "Deep_Branch_of_Ulnar_Nerve_R",
        "Deep_Palmar_Arch_R",
        "Dorsal_Carpal_Arch_R",
        "Dorsal_Digital_Arteries_of_Fifth_Finger_R",
        "Dorsal_Digital_Arteries_of_First_Finger_R",
        "Dorsal_Digital_Arteries_of_Fourth_Finger_R",
        "Dorsal_Digital_Arteries_of_Second_Finger_R",
        "Dorsal_Digital_Arteries_of_Third_Finger_R",
        "Dorsal_Digital_Nerves_R",
        "First_Common_Palmar_Digital_Artery_R",
        "First_Dorsal_Metacapral_Artery_R",
        "Fourth_Palmar_Metacarpal_Artery_R",
        "L_Adductor_Aponeurosis_Ligament_R",
        "L_Annular_Ligament_of_Radius_R",
        "L_Anular_Ligament_1_of_Index_Finger_R",
        "L_Anular_Ligament_1_of_Little_Finger_R",
        "L_Anular_Ligament_1_of_Middle_Finger_R",
        "L_Anular_Ligament_1_of_Ring_Finger_R",
        "L_Anular_Ligament_1_of_Thumb_R",
        "L_Anular_Ligament_2_of_Index_Finger_R",
        "L_Anular_Ligament_2_of_Little_Finger_R",
        "L_Anular_Ligament_2_of_Middle_Finger_R",
        "L_Anular_Ligament_2_of_Ring_Finger_R",
        "L_Anular_Ligament_2_of_Thumb_R",
        "L_Anular_Ligament_3_of_Index_Finger_R",
        "L_Anular_Ligament_3_of_Little_Finger_R",
        "L_Anular_Ligament_3_of_Middle_Finger_R",
        "L_Anular_Ligament_3_of_Ring_Finger_R",
        "L_Anular_Ligament_3_of_Thumb_R",
        "L_Anular_Ligament_4_of_Index_Finger_R",
        "L_Anular_Ligament_4_of_Little_Finger_R",
        "L_Anular_Ligament_4_of_Middle_Finger_R",
        "L_Anular_Ligament_4_of_Ring_Finger_R",
        "L_Anular_Ligament_4_of_Thumb_R",
        "L_Anular_Ligament_5_of_Index_Finger_R",
        "L_Anular_Ligament_5_of_Little_Finger_R",
        "L_Anular_Ligament_5_of_Middle_Finger_R",
        "L_Anular_Ligament_5_of_Ring_Finger_R",
        "L_Articular_Surface_Humeroulnar_R",
        "L_Articular_Surface_Sesamoid_Hand_R",
        "L_Capsule_Distal_Radioulnar_Joint_R",
        "L_Capsule_of_Carpometacarpal_Joint_R",
        "L_Capsule_of_Distal_Interphalangeal_Joint_of_Index_Finger_R",
        "L_Capsule_of_Distal_Interphalangeal_Joint_of_Little_Finger_R",
        "L_Capsule_of_Distal_Interphalangeal_Joint_of_Middle_Finger_R",
        "L_Capsule_of_Distal_Interphalangeal_Joint_of_Ring_Finger_R",
        "L_Capsule_of_Metacarpophalangeal_Joint_of_Index_Finger_R",
        "L_Capsule_of_Metacarpophalangeal_Joint_of_Little_Finger_R",
        "L_Capsule_of_Metacarpophalangeal_Joint_of_Middle_Finger_R",
        "L_Capsule_of_Metacarpophalangeal_Joint_of_Ring_Finger_R",
        "L_Capsule_of_Metacarpophalangeal_Joint_of_Thumb_R",
        "L_Capsule_of_Proximal_Interphalangeal_Joint_of_Index_Finger_R",
        "L_Capsule_of_Proximal_Interphalangeal_Joint_of_Little_Finger_R",
        "L_Capsule_of_Proximal_Interphalangeal_Joint_of_Middle_Finger_R",
        "L_Capsule_of_Proximal_Interphalangeal_Joint_of_Ring_Finger_R",
        "L_Capsule_of_Proximal_Interphalangeal_Joint_of_Thumb_R",
        "L_Capsule_of_Styloid_R",
        "L_Common_Flexor_Tendon_Sheath_R",
        "L_Cruciform_Ligaments_1_of_Index_Finger_R",
        "L_Cruciform_Ligaments_1_of_Little_Finger_R",
        "L_Cruciform_Ligaments_1_of_Middle_Finger_R",
        "L_Cruciform_Ligaments_1_of_Ring_Finger_R",
        "L_Cruciform_Ligaments_2_of_Index_Finger_R",
        "L_Cruciform_Ligaments_2_of_Little_Finger_R",
        "L_Cruciform_Ligaments_2_of_Middle_Finger_R",
        "L_Cruciform_Ligaments_2_of_Ring_Finger_R",
        "L_Cruciform_Ligaments_3_of_Index_Finger_R",
        "L_Cruciform_Ligaments_3_of_Little_Finger_R",
        "L_Cruciform_Ligaments_3_of_Middle_Finger_R",
        "L_Cruciform_Ligaments_3_of_Ring_Finger_R",
        "L_Cruciform_Ligaments_4_of_Index_Finger_R",
        "L_Cruciform_Ligaments_4_of_Little_Finger_R",
        "L_Cruciform_Ligaments_4_of_Middle_Finger_R",
        "L_Cruciform_Ligaments_4_of_Ring_Finger_R",
        "L_Deep_Transverse_Metacarpal_Ligament_1_R",
        "L_Deep_Transverse_Metacarpal_Ligament_2_R",
        "L_Deep_Transverse_Metacarpal_Ligament_3_R",
        "L_Digits_Synovial_Sheaths_R",
        "L_Distal_Commissural_Ligament_R",
        "L_Distal_Interphalangeal_Joint_Collateral_Ligaments_1_R",
        "L_Distal_Interphalangeal_Joint_Collateral_Ligaments_2_R",
        "L_Distal_Interphalangeal_Joint_Collateral_Ligaments_3_R",
        "L_Distal_Interphalangeal_Joint_Collateral_Ligaments_4_R",
        "L_Dorsal_Carpometacarpral_Ligament_10_R",
        "L_Dorsal_Carpometacarpral_Ligament_11_R",
        "L_Dorsal_Carpometacarpral_Ligament_1_R",
        "L_Dorsal_Carpometacarpral_Ligament_2_R",
        "L_Dorsal_Carpometacarpral_Ligament_3_R",
        "L_Dorsal_Carpometacarpral_Ligament_4_R",
        "L_Dorsal_Carpometacarpral_Ligament_5_R",
        "L_Dorsal_Carpometacarpral_Ligament_6_R",
        "L_Dorsal_Carpometacarpral_Ligament_7_R",
        "L_Dorsal_Carpometacarpral_Ligament_8_R",
        "L_Dorsal_Carpometacarpral_Ligament_9_R",
        "L_Dorsal_Intercarpal_Ligaments_1_R",
        "L_Dorsal_Intercarpal_Ligaments_2_R",
        "L_Dorsal_Metacarpral_Ligament_1_R",
        "L_Dorsal_Metacarpral_Ligament_2_R",
        "L_Dorsal_Metacarpral_Ligament_3_R",
        "L_Dorsal_Metacarpral_Ligament_4_R",
        "L_Dorsal_Radial_Metaphyseal_Arcuate_Ligament_1_R",
        "L_Dorsal_Radial_Metaphyseal_Arcuate_Ligament_2_R",
        "L_Dorsal_Radiocarpal_Ligament_1_R",
        "L_Dorsal_Radiocarpal_Ligament_2_R",
        "L_Dorsal_Radiocarpal_Ligament_3_R",
        "L_Dorsal_Radiocarpal_Ligament_4_R",
        "L_Dorsal_Radioulnar_Ligament_R",
        "L_Dorsal_Ulnocarpal_Ligament_R",
        "L_Extensor_Carpi_Radialis_Brevis_Tendon_Sheaths_R",
        "L_Extensor_Carpi_Ulnaris_Tendon_Sheaths_R",
        "L_Extensor_Digiti_Minimi_Manus_Tendon_Sheaths_R",
        "L_Extensor_Digitorium_Tendon_Sheaths_1_R",
        "L_Extensor_Digitorium_Tendon_Sheaths_2_R",
        "L_Extensor_Digitorium_Tendon_Sheaths_3_R",
        "L_Extensor_Expansion_Hood_1_R",
        "L_Extensor_Expansion_Hood_2_R",
        "L_Extensor_Expansion_Hood_3_R",
        "L_Extensor_Expansion_Hood_4_R",
        "L_Extensor_Pollicis_Brevis_Tendon_Sheaths_1_R",
        "L_Extensor_Pollicis_Brevis_Tendon_Sheaths_2_R",
        "L_Extensor_Pollicis_Longus_Tendon_Sheaths_R",
        "L_Extensor_Retinaculum_R",
        "L_Flexor_Pollicis_Tendon_Sheath_R",
        "L_Flexor_Retinaculum_Transverse_Carpal_Ligament_R",
        "L_Interosseous_Ligament_10_R",
        "L_Interosseous_Ligament_1_R",
        "L_Interosseous_Ligament_2_R",
        "L_Interosseous_Ligament_3_R",
        "L_Interosseous_Ligament_4_R",
        "L_Interosseous_Ligament_5_R",
        "L_Interosseous_Ligament_6_R",
        "L_Interosseous_Ligament_7_R",
        "L_Interosseous_Ligament_8_R",
        "L_Interosseous_Ligament_9_R",
        "L_Interosseous_Membrane_R",
        "L_Interosseous_Tendon_Slip_to_Lateral_Band_1_of_Index_Finger_R",
        "L_Interosseous_Tendon_Slip_to_Lateral_Band_1_of_Little_Finger_R",
        "L_Interosseous_Tendon_Slip_to_Lateral_Band_1_of_Middle_Finger_R",
        "L_Interosseous_Tendon_Slip_to_Lateral_Band_1_of_Ring_Finger_R",
        "L_Interosseous_Tendon_Slip_to_Lateral_Band_2_of_Index_Finger_R",
        "L_Interosseous_Tendon_Slip_to_Lateral_Band_2_of_Little_Finger_R",
        "L_Interosseous_Tendon_Slip_to_Lateral_Band_2_of_Middle_Finger_R",
        "L_Interosseous_Tendon_Slip_to_Lateral_Band_2_of_Ring_Finger_R",
        "L_Lateral_Fiber_1_of_Index_Finger_R",
        "L_Lateral_Fiber_1_of_Little_Finger_R",
        "L_Lateral_Fiber_1_of_Middle_Finger_R",
        "L_Lateral_Fiber_1_of_Ring_Finger_R",
        "L_Lateral_Fiber_2_of_Index_Finger_R",
        "L_Lateral_Fiber_2_of_Little_Finger_R",
        "L_Lateral_Fiber_2_of_Middle_Finger_R",
        "L_Lateral_Fiber_2_of_Ring_Finger_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_1_1_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_1_2_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_2_1_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_2_2_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_3_1_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_3_2_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_4_1_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_4_2_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_5_1_R",
        "L_Metacarpophalangeal_Joints_Collateral_Ligament_5_2_R",
        "L_Oblique_Retinacular_Ligaments_1_of_Index_Finger_R",
        "L_Oblique_Retinacular_Ligaments_1_of_Little_Finger_R",
        "L_Oblique_Retinacular_Ligaments_1_of_Middle_Finger_R",
        "L_Oblique_Retinacular_Ligaments_1_of_Ring_Finger_R",
        "L_Oblique_Retinacular_Ligaments_2_of_Index_Finger_R",
        "L_Oblique_Retinacular_Ligaments_2_of_Little_Finger_R",
        "L_Oblique_Retinacular_Ligaments_2_of_Middle_Finger_R",
        "L_Oblique_Retinacular_Ligaments_2_of_Ring_Finger_R",
        "L_Palmar_Carpometacarpal_Ligament_10_R",
        "L_Palmar_Carpometacarpal_Ligament_11_R",
        "L_Palmar_Carpometacarpal_Ligament_12_R",
        "L_Palmar_Carpometacarpal_Ligament_13_R",
        "L_Palmar_Carpometacarpal_Ligament_1_R",
        "L_Palmar_Carpometacarpal_Ligament_2_R",
        "L_Palmar_Carpometacarpal_Ligament_3_R",
        "L_Palmar_Carpometacarpal_Ligament_4_R",
        "L_Palmar_Carpometacarpal_Ligament_5_R",
        "L_Palmar_Carpometacarpal_Ligament_6_R",
        "L_Palmar_Carpometacarpal_Ligament_7_R",
        "L_Palmar_Carpometacarpal_Ligament_8_R",
        "L_Palmar_Carpometacarpal_Ligament_9_R",
        "L_Palmar_Intercarpal_Ligament_10_R",
        "L_Palmar_Intercarpal_Ligament_1_R",
        "L_Palmar_Intercarpal_Ligament_2_R",
        "L_Palmar_Intercarpal_Ligament_3_R",
        "L_Palmar_Intercarpal_Ligament_4_R",
        "L_Palmar_Intercarpal_Ligament_5_R",
        "L_Palmar_Intercarpal_Ligament_6_R",
        "L_Palmar_Intercarpal_Ligament_7_R",
        "L_Palmar_Intercarpal_Ligament_8_R",
        "L_Palmar_Intercarpal_Ligament_9_R",
        "L_Palmar_Radiocarpal_Ligaments_1_R",
        "L_Palmar_Radiocarpal_Ligaments_2_R",
        "L_Palmar_Radiocarpal_Ligaments_3_R",
        "L_Palmar_Radioulnar_R",
        "L_Palmar_Ulnocarpal_Ligament_1_R",
        "L_Palmar_Ulnocarpal_Ligament_2_R",
        "L_Palmar_Ulnocarpal_Ligament_3_R",
        "L_Palmar_Ulnocarpal_Ligament_4_R",
        "L_Palmar_Ulnocarpal_Ligament_5_R",
        "L_Pisohamate_Ligament_R",
        "L_Pisometacarpal_Ligament_R",
        "L_Proximal_Commissural_Ligament_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_1_1_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_1_2_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_2_1_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_2_2_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_3_1_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_3_2_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_4_1_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_4_2_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_5_1_R",
        "L_Proximal_Interphalangeal_Joints_Collateral_Ligament_5_2_R",
        "L_Radial_Carpal_Collateral_Ligament_1_R",
        "L_Radial_Carpal_Collateral_Ligament_2_R",
        "L_Radial_Carpal_Collateral_Ligament_3_R",
        "L_Radial_Collateral_Ligament_R",
        "L_Radioscaphocapitate_Ligament_R",
        "L_Scaphocapitate_Ligament_R",
        "L_Superficial_Transverse_Metacapral_Ligament_R",
        "L_Transverse_Retinacular_Ligament_1_of_Index_Finger_R",
        "L_Transverse_Retinacular_Ligament_1_of_Little_Finger_R",
        "L_Transverse_Retinacular_Ligament_1_of_Middle_Finger_R",
        "L_Transverse_Retinacular_Ligament_1_of_Ring_Finger_R",
        "L_Transverse_Retinacular_Ligament_2_of_Index_Finger_R",
        "L_Transverse_Retinacular_Ligament_2_of_Little_Finger_R",
        "L_Transverse_Retinacular_Ligament_2_of_Middle_Finger_R",
        "L_Transverse_Retinacular_Ligament_2_of_Ring_Finger_R",
        "L_Triangular_Ligament_of_Index_Finger_R",
        "L_Triangular_Ligament_of_Little_Finger_R",
        "L_Triangular_Ligament_of_Middle_Finger_R",
        "L_Triangular_Ligament_of_Ring_Finger_R",
        "L_Triquetrocapitate_Ligament_R",
        "L_Ulnar_Carpal_Collateral_Ligament_R",
        "L_Ulnar_Collateral_Ligament_R",
        "L_Volar_Plate_1_of_Index_Finger_R",
        "L_Volar_Plate_1_of_Little_Finger_R",
        "L_Volar_Plate_1_of_Middle_Finger_R",
        "L_Volar_Plate_1_of_Ring_Finger_R",
        "L_Volar_Plate_1_of_Thumb_R",
        "L_Volar_Plate_2_of_Index_Finger_R",
        "L_Volar_Plate_2_of_Little_Finger_R",
        "L_Volar_Plate_2_of_Middle_Finger_R",
        "L_Volar_Plate_2_of_Ring_Finger_R",
        "L_Volar_Plate_2_of_Thumb_R",
        "L_Volar_Plate_3_of_Index_Finger_R",
        "L_Volar_Plate_3_of_Little_Finger_R",
        "L_Volar_Plate_3_of_Middle_Finger_R",
        "L_Volar_Plate_3_of_Ring_Finger_R",
        "Lateral_Antebrachial_Cutaneous_Nerve_R",
        "M_Abductor_Digiti_Minimi_Muscle_R",
        "M_Abductor_Pollicis_Brevis_Muscle_R",
        "M_Abductor_Pollicis_Longus_Muscle_R",
        "M_Abductor_Pollicis_Oblique_Head_Muscle_R",
        "M_Abductor_Pollicis_Transverse_Head_Muscle_R",
        "M_Adductor_Pollicis_Oblique_Head_Muscle_R",
        "M_Anconeus_Muscle_R",
        "M_Brachioradialis_Muscle_R",
        "M_Dorsal_Interosseus_Muscle_part_0_R",
        "M_Dorsal_Interosseus_Muscle_part_1_R",
        "M_Dorsal_Interosseus_Muscle_part_2_R",
        "M_Dorsal_Interosseus_Muscle_part_3_R",
        "M_Dorsal_Interosseus_Muscle_part_4_R",
        "M_Dorsal_Interosseus_Muscle_part_5_R",
        "M_Dorsal_Interosseus_Muscle_part_6_R",
        "M_Dorsal_Interosseus_Muscle_part_7_R",
        "M_Extensor_Capri_Radialis_Brevis_Muscle_R",
        "M_Extensor_Carpi_Radialis_Longus_Muscle_R",
        "M_Extensor_Carpi_Ulnaris_Muscle_R",
        "M_Extensor_Digiti_Minimi_Muscle_R",
        "M_Extensor_Digitorum_Muscle_R",
        "M_Extensor_Indicis_Muscle_R",
        "M_Extensor_Pollicis_Brevis_Muscle_R",
        "M_Extensor_Pollicis_Longus_Muscle_R",
        "M_Felexor_Pollicis_Brevis_Muscle_part_0_L",
        "M_Felexor_Pollicis_Brevis_Muscle_part_1_L",
        "M_Flexor_Carpi_Radialis_Muscle_R",
        "M_Flexor_Carpi_Ulnaris_Muscle_R",
        "M_Flexor_Digiti_Minimi_Muscle_R",
        "M_Flexor_Digitorum_Profundus_Muscle_R",
        "M_Flexor_Digitorum_Superficialis_Muscle_R",
        "M_Flexor_Pollicis_Longus_Muscle_R",
        "M_Interossei_Muscle_part_0_R",
        "M_Interossei_Muscle_part_1_R",
        "M_Interossei_Muscle_part_2_R",
        "M_Lumbricals_Muscle_part_0_R",
        "M_Lumbricals_Muscle_part_1_R",
        "M_Lumbricals_Muscle_part_2_R",
        "M_Lumbricals_Muscle_part_3_R",
        "M_Lumbricals_Muscle_part_4_R",
        "M_Lumbricals_Muscle_part_5_R",
        "M_Oblique_Cord_Muscle_R",
        "M_Opponens_Digiti_Minimi_Muscle_R",
        "M_Opponens_Pollcls_Muscle_R",
        "M_Pronator_Quadratus_Muscle_R",
        "M_Pronator_Teres_Muscle_R",
        "M_Supinator_Muscle_R",
        "Medial_Antebrachial_Cutaneous_Nerve_R",
        "Median_Nerve_R",
        "Musculocutaneous_Nerve_R",
        "Posterior_Antebrachial_Cutaneous_Nerve_R",
        "Posterior_Brachial_Cutaneous_Nerve_R",
        "Posterior_Interosseous_Artery_R",
        "Posterior_Interosseous_Nerve_R",
        "Princeps_Pollicis_Artery_R",
        "Proper_Palmar_Digital_Arteries_of_Fifth_Finger_R",
        "Proper_Palmar_Digital_Arteries_of_First_Finger_R",
        "Proper_Palmar_Digital_Arteries_of_Fourth_Finger_R",
        "Proper_Palmar_Digital_Arteries_of_Second_Finger_R",
        "Proper_Palmar_Digital_Arteries_of_Third_Finger_R",
        "Proper_Palmar_Digital_Nerves_R",
        "Radial_Artery_R",
        "Radial_Nerve_R",
        "Radialis_Indicis_Artery_R",
        "Recurrent_Interosseous_Artery_R",
        "Second_Common_Palmar_Digital_Artery_R",
        "Second_Dorsal_Metacapral_Artery_R",
        "Second_Palmar_Metacarpal_Artery_R",
        "Superficial_Branch_of_Radial_Nerve_R",
        "Superficial_Branch_of_Ulnar_Nerve_R",
        "Superficial_Palmar_Arch_R",
        "Third_Common_Palmar_Digital_Artery_R",
        "Third_Dorsal_Metacapral_Artery_R",
        "Third_Palmar_Metacarpal_Artery_R",
        "Ulnar_Artery_R",
        "Ulnar_Nerve_R"
      ],
      "easyMeshNames": [
        "First_Common_Palmar_Digital_Artery_R",
        "L_Extensor_Retinaculum_R",
        "L_Flexor_Retinaculum_Transverse_Carpal_Ligament_R",
        "Median_Nerve_R",
        "Proper_Palmar_Digital_Arteries_of_Fifth_Finger_R",
        "Proper_Palmar_Digital_Arteries_of_First_Finger_R",
        "Proper_Palmar_Digital_Arteries_of_Fourth_Finger_R",
        "Proper_Palmar_Digital_Arteries_of_Second_Finger_R",
        "Proper_Palmar_Digital_Arteries_of_Third_Finger_R",
        "Proper_Palmar_Digital_Nerves_R",
        "Second_Common_Palmar_Digital_Artery_R",
        "Superficial_Branch_of_Ulnar_Nerve_R",
        "Superficial_Palmar_Arch_R",
        "Third_Common_Palmar_Digital_Artery_R",
        "Ulnar_Nerve_R"
      ]
    },
    "fma35204": {
      "sideMode": "Unmodified",
      "visibleMeshNames": [
        "B_Calcaneus_L",
        "B_Cuboid_L",
        "B_Distal_Phalanx_Foot_1_L",
        "B_Distal_Phalanx_Foot_2_L",
        "B_Distal_Phalanx_Foot_3_L",
        "B_Distal_Phalanx_Foot_4_L",
        "B_Distal_Phalanx_Foot_5_L",
        "B_Intermediate_Cuneiform_L",
        "B_Lateral_Cuneiform_L",
        "B_Medial_Cuneiform_Bone_L",
        "B_Metatarsal_1_L",
        "B_Metatarsal_2_L",
        "B_Metatarsal_3_L",
        "B_Metatarsal_4_L",
        "B_Metatarsal_5_L",
        "B_Middle_Phalanx_Foot_2_L",
        "B_Middle_Phalanx_Foot_3_L",
        "B_Middle_Phalanx_Foot_4_L",
        "B_Middle_Phalanx_Foot_5_L",
        "B_Navicular_Bone_L",
        "B_Proximal_Phalanx_Foot_1_L",
        "B_Proximal_Phalanx_Foot_2_L",
        "B_Proximal_Phalanx_Foot_3_L",
        "B_Proximal_Phalanx_Foot_4_L",
        "B_Proximal_Phalanx_Foot_5_L",
        "B_Sesamoid_Foot_L",
        "B_Talus_L",
        "L_Dorsal_Talonavicular_Ligament_L",
        "L_Plantar_Calcaneonavicular_Ligament_1_L",
        "L_Plantar_Calcaneonavicular_Ligament_2_L",
        "L_Plantar_Calcaneonavicular_Ligament_3_L",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_L"
      ],
      "easyMeshNames": [
        "L_Dorsal_Talonavicular_Ligament_L",
        "L_Plantar_Calcaneonavicular_Ligament_1_L",
        "L_Plantar_Calcaneonavicular_Ligament_2_L",
        "L_Plantar_Calcaneonavicular_Ligament_3_L",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_L"
      ]
    }
  }
}
