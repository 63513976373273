/* eslint @typescript-eslint/no-unused-vars: off */
import {
  ActionIcon,
  Button,
  Container,
  Drawer,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { createSpotlight } from "@mantine/spotlight";
import * as Sentry from "@sentry/react";
import { IconLibrary, IconSearch } from "@tabler/icons-react";
import { Suspense } from "react";
import { useLocation, useParams } from "wouter";
import { AtlasStructurePanel } from "./AtlasStructurePanel";
import { DisplayTree } from "./DisplayTree/DisplayTree";
import { FallbackWithoutReset } from "./FallbackWithoutReset";
import { FallbackWithReset } from "./FallbackWithReset";
import { LargeLoader } from "./LargeLoader";
import { StructureSearch } from "./StructureSearch";

const [atlasSpotlightStore, atlasSpotlight] = createSpotlight();

export const AtlasPage = () => {
  const [_location, navigate] = useLocation();
  const { structureId } = useParams<{ structureId: string | undefined }>();
  const [opened, { open, close }] = useDisclosure(false);

  const structure = (
    <Stack style={{ flexGrow: 1 }}>
      <Sentry.ErrorBoundary fallback={FallbackWithoutReset}>
        <Suspense fallback={<LargeLoader />}>
          {structureId ? (
            structureId && <AtlasStructurePanel structureId={structureId} />
          ) : (
            <>
              <Title order={4} c="dimmed" ta="center">
                Nothing selected
              </Title>
              <Text c="dimmed" size="md" ta="center">
                Select or search for a structure to see information about it
              </Text>
            </>
          )}
        </Suspense>
      </Sentry.ErrorBoundary>
    </Stack>
  );

  const title = <Title order={1}>Structures</Title>;

  return (
    <>
      <Container py="md" visibleFrom="sm">
        <Group align="flex-start">
          <Stack>
            {title}
            <Group wrap="nowrap" align="flex-start">
              <Stack style={{ width: "350px", flexShrink: 0 }}>
                <Sentry.ErrorBoundary fallback={FallbackWithReset}>
                  <Suspense fallback={<LargeLoader />}>
                    <Button
                      onClick={atlasSpotlight.open}
                      leftSection={<IconSearch size={14} />}
                    >
                      Search for structure
                    </Button>
                    <DisplayTree />
                  </Suspense>
                </Sentry.ErrorBoundary>
              </Stack>
              {structure}
            </Group>
          </Stack>
        </Group>
      </Container>
      <Container py="md" hiddenFrom="sm">
        <Stack>
          <Group justify="space-between">
            {title}
            <Group gap="xs">
              <ActionIcon
                variant="filled"
                size="lg"
                radius="md"
                aria-label="Search for structure"
                onClick={atlasSpotlight.open}
              >
                <IconSearch size={14} />
              </ActionIcon>
              <ActionIcon
                variant="filled"
                size="lg"
                radius="md"
                aria-label="Search for structure"
                onClick={open}
              >
                <IconLibrary size={14} />
              </ActionIcon>
            </Group>
          </Group>
          {structure}
        </Stack>
      </Container>
      <Drawer
        offset={8}
        size="xs"
        radius="md"
        opened={opened}
        onClose={close}
        position="right"
        title="Structures Library"
      >
        <DisplayTree />
      </Drawer>
      <StructureSearch
        onSelectStructure={(structureId) =>
          navigate(`/atlas/structures/${structureId}`)
        }
        structureSpotlightStore={atlasSpotlightStore}
      />
    </>
  );
};
