import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import {
  Combobox,
  Container,
  Stack,
  TextInput,
  useCombobox,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { Link } from "wouter";

interface Question {
  id: string;
  text: string;
}

export const QuestionsPage = () => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [value, setValue] = useState("");

  const questionsQuery = useQuery(quizConnectQuery.getQuizItems, {
    searchTerm: value,
  });

  const [quizItems, setQuizItems] = useState<Question[]>([]);

  useEffect(() => {
    if (questionsQuery.data) {
      setQuizItems(questionsQuery.data.questions);
    }
  }, [questionsQuery.data]);

  const shouldFilterOptions = !quizItems.some((item) => item.text === value);
  const filteredOptions = shouldFilterOptions
    ? quizItems.filter((item) =>
        item.text.toLowerCase().includes(value.toLowerCase().trim()),
      )
    : quizItems;

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item.text} key={item.id}>
      <Link href={`/question/${item.id}`}>{item.text}</Link>
    </Combobox.Option>
  ));

  return (
    <Container py="md">
      <Stack>
        <Combobox
          onOptionSubmit={(optionValue) => {
            setValue(optionValue);
            combobox.closeDropdown();
          }}
          store={combobox}
          withinPortal={false}
        >
          <Combobox.Target>
            <TextInput
              label="🤖 Search for a question"
              placeholder="Start typing then choose"
              value={value}
              onChange={(event) => {
                setValue(event.currentTarget.value);
                combobox.openDropdown();
                combobox.updateSelectedOptionIndex();
              }}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              onBlur={() => combobox.closeDropdown()}
            />
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options mah={200} style={{ overflowY: "auto" }}>
              {options.length === 0 ? (
                <Combobox.Empty>Nothing found</Combobox.Empty>
              ) : (
                options
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </Stack>
    </Container>
  );
};
