import type { PlainMessage } from "@bufbuild/protobuf";
import { atlasPb } from "./index.js";

export function idsFromMask(
  idMask: PlainMessage<atlasPb.IdMask> | undefined,
): string[] {
  if (!idMask) {
    return [];
  }
  if (idMask.idSpace !== atlasPb.IdSpace.NONE) {
    throw new Error(
      `Unsupported ID space: ${idMask.idSpace}. Only NONE is currently supported.`,
    );
  }
  return idMask.extraIds;
}

export function maskFromIds(ids: string[]): atlasPb.IdMask {
  return new atlasPb.IdMask({
    idSpace: atlasPb.IdSpace.NONE,
    extraIds: ids,
  });
}
