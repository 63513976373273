import { quizConnectQuery } from "@augmedi/proto-gen";
import type { ConnectError } from "@connectrpc/connect";
import { useMutation } from "@connectrpc/connect-query";
import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import type { DefinedQueryObserverResult } from "@tanstack/react-query";
import type { WhoAmIResponse } from "../../../proto-gen/src/proto/quiz_pb";
import * as classes from "./AppNavLinks.module.css";

interface Props {
  whoAmIQuery: DefinedQueryObserverResult<WhoAmIResponse, ConnectError>;
}

export const TutorialVideo = ({ whoAmIQuery }: Props) => {
  const videoUrl =
    "https://augmedidevdata.blob.core.windows.net/ml-stuff/tutorial/Tutorial_v1.mp4?sv=2021-10-04&spr=https%2Chttp&st=2024-09-12T10%3A55%3A14Z&se=2030-10-13T10%3A55%3A00Z&sr=b&sp=r&sig=IoHc%2BeM5hO6wQtGHdDWolXS21YxhtSjxsSSfD8ruoCI%3D";

  const [opened, { open, close }] = useDisclosure(false);

  const userId = whoAmIQuery.data.id;

  const setTutorialSeenMutation = useMutation(
    quizConnectQuery.setUserSeenTutorial,
    {
      onSuccess: async () => {
        whoAmIQuery.refetch();
      },
    },
  );

  return (
    <>
      <Modal
        opened={!whoAmIQuery.data.seenTutorial || opened}
        onClose={() => {
          if (!whoAmIQuery.data.seenTutorial) {
            setTutorialSeenMutation.mutate({ id: userId });
          }
          close();
        }}
        title="TeachAnatomy Tutorial"
        size="auto"
        centered
      >
        <video controls autoPlay playsInline width="100%" height="100%">
          <source src={videoUrl} type="video/mp4" />
        </video>
      </Modal>
      <Button
        onClick={open}
        variant="subtle"
        color="#495057"
        className={classes.menuButton}
      >
        Tutorial
      </Button>
    </>
  );
};
