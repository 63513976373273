/* eslint @typescript-eslint/no-unused-vars: off */
import { quizConnectQuery } from "@augmedi/proto-gen";
import { createConnectQueryKey, useMutation } from "@connectrpc/connect-query";
import { Box, Button, Group, Loader, Stack } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { Suspense, useRef } from "react";
import { Link, useLocation } from "wouter";
import { FallbackWithReset } from "./FallbackWithReset";
import ModelViewerUi from "./ModelViewerUi";
import { NonFrozenModelPreview } from "./NonFrozenModelPreview.js";
import { OurCanvas } from "./OurCanvas.js";
import { type SharedModelPreviewStuffRef } from "./SharedModelPreviewStuff.js";

interface Props {
  modelId: string;
}

export const ModelPageDraftTab = ({ modelId }: Props) => {
  const [_location, navigate] = useLocation();

  const queryClient = useQueryClient();

  const freezeModelMutation = useMutation(quizConnectQuery.freezeModel, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(quizConnectQuery.getModel, {
          id: modelId,
        }),
      });
      navigate(`/models/${modelId}/frozen`);
    },
  });

  const sharedStuffRef = useRef<SharedModelPreviewStuffRef>(null);

  return (
    <Stack>
      <Sentry.ErrorBoundary fallback={FallbackWithReset}>
        <Box style={{ height: 500 }}>
          <Suspense fallback={<Loader />}>
            <ModelViewerUi
              onResetCamera={() => sharedStuffRef.current?.resetCamera()}
            >
              <OurCanvas>
                <NonFrozenModelPreview
                  ref={sharedStuffRef}
                  modelId={modelId}
                  backgroundColor={0xffffff}
                />
              </OurCanvas>
            </ModelViewerUi>
          </Suspense>
        </Box>
      </Sentry.ErrorBoundary>

      <Group>
        <Link to={`/models/${modelId}/labelEditor`} asChild>
          <Button component="a">Edit labels</Button>
        </Link>
        <Button onClick={() => freezeModelMutation.mutate({ id: modelId })}>
          Freeze model
        </Button>
      </Group>
    </Stack>
  );
};
