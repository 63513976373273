import { Divider, Flex } from "@mantine/core";
import type { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const DividerWithContent = ({ children }: Props) => {
  return (
    <Flex gap="sm" align="center">
      <Divider my="lg" style={{ flexGrow: 1 }} />
      {children}
      <Divider my="lg" style={{ flexGrow: 1 }} />
    </Flex>
  );
};
