import { type quizPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import { Alert, Button, Stack, Text, TextInput, Title } from "@mantine/core";
import { StructureSelect } from "./StructureSelect";

interface Props {
  selectedLabel?: PlainMessage<quizPb.ModelLabel>;
  modifySelectedLabel: (
    callback: (label: PlainMessage<quizPb.ModelLabel>) => void,
  ) => void;
  openDeleteLabelModal: () => void;
}

export const LabelSettings = ({
  selectedLabel,
  modifySelectedLabel,
  openDeleteLabelModal,
}: Props) => {
  return (
    selectedLabel && (
      <Stack gap="xs">
        <Title order={4}>Selected label</Title>
        <Text size="sm">Structure ID</Text>
        <StructureSelect
          structureId={selectedLabel.structureId}
          onStructureIdChange={(structureId) => {
            modifySelectedLabel((label) => {
              label.structureId = structureId ?? "";
            });
          }}
        />
        <TextInput
          label="Name"
          disabled={selectedLabel.isWholeMeshLabel}
          value={selectedLabel.name}
          onChange={(ev) =>
            modifySelectedLabel((selectedLabel) => {
              selectedLabel.name = ev.target.value;
            })
          }
        />
        {selectedLabel.mask ? (
          <Text>
            Resolution: {selectedLabel.mask.width}px x{" "}
            {selectedLabel.mask.height}px
          </Text>
        ) : (
          <Alert color="yellow">Label has no mask</Alert>
        )}

        <Button
          color="red"
          style={{ flexShrink: 0 }}
          disabled={selectedLabel.isWholeMeshLabel}
          onClick={openDeleteLabelModal}
        >
          Delete label
        </Button>
      </Stack>
    )
  );
};
