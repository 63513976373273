import { atlasConnectQuery, quizConnectQuery } from "@augmedi/proto-gen";
import { useMutation, useSuspenseQuery } from "@connectrpc/connect-query";
import { Button, Checkbox, Stack, Text } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { sortBy, uniq } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { showErrorNotification } from "../logic/notification";
import { createAnyArgumentsQueryKey } from "../logic/query-key";

interface Props {
  parentStructureId: string;
  mediaItemId: string;
  onClose: () => void;
}

interface Suggestion {
  name: string;
  structureId: string;
}

export const AnnotatorInferChildrenModalContent = ({
  parentStructureId,
  mediaItemId,
  onClose,
}: Props) => {
  const queryClient = useQueryClient();

  const structureMetadataQuery = useSuspenseQuery(
    atlasConnectQuery.getStructureMetadata,
    {
      id: parentStructureId,
    },
  );
  const existingDownstreamQuery = useSuspenseQuery(
    quizConnectQuery.listDownstreamStructures,
    {
      upstreamStructureId: parentStructureId,
    },
  );
  const inferredDownstreamQuery = useSuspenseQuery(
    quizConnectQuery.listDownstreamStructures,
    {
      upstreamStructureId: parentStructureId,
      inferFromAtlas: true,
    },
  );

  const suggestions = useMemo(() => {
    const existingDownstreamStructureIds = new Set(
      existingDownstreamQuery.data.downstreamStructures.map(
        (s) => s.downstreamStructureId,
      ),
    );
    return sortBy(
      inferredDownstreamQuery.data.downstreamStructures
        .map(
          (s): Suggestion => ({
            name: s.notes,
            structureId: s.downstreamStructureId,
          }),
        )
        .filter((s) => !existingDownstreamStructureIds.has(s.structureId)),
      (s) => s.name,
    );
  }, [existingDownstreamQuery.data, inferredDownstreamQuery.data]);

  useEffect(() => {
    if (!suggestions.length) {
      onClose();
    }
  }, [suggestions]);

  const [selectedStructureIds, setSelectedStructureIds] = useState<string[]>(
    [],
  );

  const onCheckboxChange = (suggestion: Suggestion, checked: boolean) => {
    if (checked) {
      setSelectedStructureIds((ids) => uniq([...ids, suggestion.structureId]));
    } else {
      setSelectedStructureIds((ids) =>
        ids.filter((id) => id !== suggestion.structureId),
      );
    }
  };

  const createEdgeMutation = useMutation(quizConnectQuery.addEdge);

  const addAndClose = async () => {
    try {
      await Promise.all(
        suggestions
          .filter((s) => selectedStructureIds.includes(s.structureId))
          .map((s) =>
            createEdgeMutation.mutateAsync({
              upstreamStructureId: parentStructureId,
              downstreamStructureId: s.structureId,
              mediaItemId,
              notes: s.name,
            }),
          ),
      );
    } catch (err) {
      console.error(err);
      showErrorNotification({ message: "Failed to add branch." });
    }

    await queryClient.invalidateQueries({
      queryKey: createAnyArgumentsQueryKey(
        quizConnectQuery.listDownstreamStructures,
      ),
    });

    onClose();
  };

  return (
    <Stack>
      <Text>{`According to our database, some of the following structures might be branches of "${structureMetadataQuery.data.base?.name}" and haven't been added yet. Select any that you would like to add.`}</Text>
      <Stack>
        {suggestions.map((suggestion) => (
          <Checkbox
            key={suggestion.structureId}
            checked={selectedStructureIds.includes(suggestion.structureId)}
            onChange={(ev) =>
              onCheckboxChange(suggestion, ev.currentTarget.checked)
            }
            label={suggestion.name}
          />
        ))}
      </Stack>
      <Button onClick={addAndClose}>
        {selectedStructureIds.length === 0
          ? "Don't add any branches"
          : selectedStructureIds.length === 1
            ? "Add 1 branch"
            : `Add ${selectedStructureIds.length} branches`}
      </Button>
    </Stack>
  );
};
