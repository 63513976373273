import { Alert, Code, Container, Stack, Text } from "@mantine/core";

interface Props {
  error: unknown;
}

export const FallbackWithoutReset = ({ error }: Props) => {
  return (
    <Container>
      <Alert mt="md" title="Error" color="red">
        <Stack>
          <Text>An error occurred. Try reloading the page.</Text>
          <Code>{error + ""}</Code>
        </Stack>
      </Alert>
    </Container>
  );
};
