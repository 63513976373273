import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import { Box, Loader, Stack, Text } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { Suspense, useMemo, useRef } from "react";
import { colorConstants } from "../color-constants.js";
import { shaderRgbaFromString } from "../logic/color.js";
import type { OverlayMaterialCombinedChannelKeys } from "../logic/overlay-material.js";
import { retryExceptConnectNotFound } from "../query.js";
import { FallbackWithReset } from "./FallbackWithReset.js";
import { FrozenModelPreview } from "./FrozenModelPreview.js";
import ModelViewerUi from "./ModelViewerUi.js";
import { OurCanvas } from "./OurCanvas.js";
import type { SharedModelPreviewStuffRef } from "./SharedModelPreviewStuff.js";

interface Props {
  frozenModelId: string;
}

const labelsChannel: OverlayMaterialCombinedChannelKeys = "CombinedR";

const settingsByChannel = {
  [labelsChannel]: {
    color: shaderRgbaFromString(colorConstants.ambientHighlightColor),
  },
};

export const ModelPageFrozenTab = ({ frozenModelId }: Props) => {
  const getFrozenModelQuery = useQuery(
    quizConnectQuery.getFrozenModel,
    {
      id: frozenModelId,
    },
    {
      retry: retryExceptConnectNotFound(),
    },
  );

  const frozenModelCreatedAt = getFrozenModelQuery.data?.createdAt?.toDate();

  const visibleLabelIds = useMemo(() => {
    const data = getFrozenModelQuery.data;
    if (!data) {
      return undefined;
    }
    const labels = data.manifest?.labels;
    if (!labels) {
      console.warn("No labels in frozen model manifest");
      return undefined;
    }
    return {
      [labelsChannel]: labels.map((label) => label.id),
    };
  }, [getFrozenModelQuery.data]);

  const sharedStuffRef = useRef<SharedModelPreviewStuffRef>(null);

  return (
    <Stack>
      <Sentry.ErrorBoundary fallback={FallbackWithReset}>
        <Box style={{ height: 500 }}>
          <Suspense fallback={<Loader />}>
            <ModelViewerUi
              onResetCamera={() => sharedStuffRef.current?.resetCamera()}
            >
              <OurCanvas>
                <FrozenModelPreview
                  ref={sharedStuffRef}
                  frozenModelId={frozenModelId}
                  backgroundColor={0xffffff}
                  visibleLabelIdsPerChannel={visibleLabelIds}
                  settingsByChannel={settingsByChannel}
                />
              </OurCanvas>
            </ModelViewerUi>
          </Suspense>
        </Box>
      </Sentry.ErrorBoundary>

      {frozenModelCreatedAt && (
        <Text>
          <Text span fw={700}>
            Created at:{" "}
          </Text>
          {frozenModelCreatedAt.toLocaleString()}
        </Text>
      )}
    </Stack>
  );
};
