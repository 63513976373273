import { rem } from "@mantine/core";
import { IconAdjustments } from "@tabler/icons-react";
import { Link } from "wouter";
import * as classes from "./AppNavLinks.module.css";

interface Props {
  textButton?: boolean;
}

export const UserSettingsButton = ({ textButton = false }: Props) => {
  const labelText = "Settings";

  // Do not use component if not calling from burger menu
  if (!textButton) {
    return;
  }

  return (
    <Link
      className={classes.menuButton}
      to="/user-settings"
      style={{ display: "flex", gap: rem(8) }}
    >
      <IconAdjustments size={20} />
      <span>{labelText}</span>
    </Link>
  );
};
