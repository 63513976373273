import { Alert, Button, Code, Container, Stack, Text } from "@mantine/core";

interface Props {
  error: unknown;
  resetError: () => void;
}

export const FallbackWithReset = ({ error, resetError }: Props) => {
  return (
    <Container>
      <Alert mt="md" title="Error" color="red">
        <Stack>
          <Text>An error occurred. You can try again or reload the page.</Text>
          <Code>{error + ""}</Code>
          <Button onClick={resetError}>Try again</Button>
        </Stack>
      </Alert>
    </Container>
  );
};
