import { config } from "../config";

export const SignupPage = () => {
  const authorizeParams = new URLSearchParams({
    response_type: "code",
    client_id: config.clientId,
    redirect_uri: window.location.origin + "/api/authorize",
    scope: "openid email profile",
    audience: config.audience,
    state: config.auth0State,
    screen_hint: "signup",
  });
  const authorizeURL = new URL(
    `?${authorizeParams}`,
    "https://" + config.domain + "/authorize",
  );
  window.location.href = authorizeURL.href;
  return <div></div>;
};
