import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { isTruthy } from "@augmedi/type-utils";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import {
  createConnectQueryKey,
  useMutation,
  useQuery,
} from "@connectrpc/connect-query";
import {
  Box,
  Button,
  Group,
  Input,
  Loader,
  Modal,
  Slider,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure, useInterval } from "@mantine/hooks";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { assert } from "assert-ts";
import { produce } from "immer";
import { sortBy } from "lodash-es";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "wouter";
import { config } from "../config.js";
import { AppLayout, useAppLayout } from "../logic/app-layout.js";
import { useNavigationLockAndBeforeUnload } from "../logic/navigation-lock.js";
import { showErrorNotification } from "../logic/notification.js";
import { useToggleSet } from "../logic/toggle-set.js";
import { retryExceptConnectNotFound } from "../query.js";
import { ChildLabelList } from "./ChildLabelList.js";
import { CreateNewLabelModal } from "./CreateNewLabelModal.js";
import { FallbackWithReset } from "./FallbackWithReset.js";
import { LabelSettings } from "./LabelSettings.js";
import {
  ModelPainter,
  ModelPainterTool,
  type ModelPainterRef,
} from "./ModelPainter.js";
import ModelViewerUi from "./ModelViewerUi.js";
import { OurCanvas } from "./OurCanvas.js";
import { ParentLabelList } from "./ParentLabelList.js";
import {
  SharedModelPreviewStuff,
  type SharedModelPreviewStuffRef,
} from "./SharedModelPreviewStuff.js";

interface SelectedLabel {
  label: PlainMessage<quizPb.ModelLabel>;
  dirtyInModelPainter: boolean;
  maybeDirtyVersusServer: boolean;
}

function brushRadiusFromSliderValue(v: number): number {
  return Math.pow(10, v);
}

export const LabelEditorPage = () => {
  const { modelId } = useParams<{ modelId: string }>();

  const queryClient = useQueryClient();

  const modelLabelsQuery = useQuery(quizConnectQuery.listModelLabels, {
    modelId,
  });
  const modelMeshesQuery = useQuery(quizConnectQuery.listModelMeshes, {
    modelId,
  });

  const [
    isCreateNewLabelModalOpen,
    { toggle: toggleCreateNewLabelModal, close: closeCreateNewLabelModal },
  ] = useDisclosure(false);
  const [desiredLabelId, setDesiredLabelId] = useState<string>("");

  // Do not modify this value directly. Use setDesiredLabelId instead.
  //
  // This is a copy of the label that was originally loaded from the server.
  // This copy might be out of sync with either (or both) of:
  //   - the internal texture in ModelPainter
  //   - the copy saved on the server
  // Based on the corresponding flags we can determine if it's safe to overwrite
  // this value. Overwriting the value will cause ModelPainter to lose any
  // unsaved internal state.
  const [selectedLabel, _setSelectedLabel] = useState<SelectedLabel>();

  const [selectedParentLabel, setSelectedParentLabel] =
    useState<PlainMessage<quizPb.ModelLabel>>();
  const [selectedChildLabel, setSelectedChildLabel] =
    useState<PlainMessage<quizPb.ModelLabel>>();
  const [newAddedLabelId, setNewAddedLabelId] = useState<string>();

  const [
    otherVisibleParentLabelIds,
    setOtherVisibleParentLabelIds,
    toggleParentLabelVisible,
  ] = useToggleSet<string>();
  const [
    otherVisibleChildLabelIds,
    setOtherVisibleChildLabelIds,
    toggleChildLabelVisible,
  ] = useToggleSet<string>();

  const partitionIdsByMeshId = useMemo(
    () =>
      new Map(modelMeshesQuery.data?.meshes.map((m) => [m.id, m.partitionId])),
    [modelMeshesQuery.data],
  );
  const meshIdsByLabelId = useMemo(
    () => new Map(modelLabelsQuery.data?.labels.map((l) => [l.id, l.meshId])),
    [modelLabelsQuery.data],
  );

  // This effect maintains selectedLabel.maybeDirtyVersusServer. Even though that
  // field is computed, it's stored to avoid performing the comparison often.
  useEffect(() => {
    if (!selectedLabel) {
      return;
    }
    const selectedLabelFromServer = modelLabelsQuery.data?.labels.find(
      (label) => label.id === selectedLabel.label.id,
    );
    const dirtyVersusServer = !quizPb.ModelLabel.equals(
      selectedLabel.label,
      selectedLabelFromServer,
    );
    if (dirtyVersusServer !== selectedLabel.maybeDirtyVersusServer) {
      _setSelectedLabel((oldEntry) => {
        // HACK This is sort of like double checked locking. It's not nice, but
        // I think it will never overwrite a label with a different one or mark
        // a dirty label as clean.
        if (oldEntry === selectedLabel) {
          return {
            ...oldEntry,
            maybeDirtyVersusServer: dirtyVersusServer,
          };
        }
      });
    }
  }, [selectedLabel, modelLabelsQuery.data]);

  useEffect(() => {
    _setSelectedLabel((oldEntry) => {
      const desiredLabelFromServer = modelLabelsQuery.data?.labels.find(
        (label) => label.id === desiredLabelId,
      );
      const safeToOverwriteLocal =
        !oldEntry ||
        (!oldEntry.dirtyInModelPainter && !oldEntry.maybeDirtyVersusServer);
      if (safeToOverwriteLocal && desiredLabelFromServer === undefined) {
        return undefined;
      } else if (
        safeToOverwriteLocal &&
        oldEntry?.label !== desiredLabelFromServer
      ) {
        assert(desiredLabelFromServer !== undefined);
        return {
          label: desiredLabelFromServer,
          dirtyInModelPainter: false,
          maybeDirtyVersusServer: false,
        };
      } else {
        return oldEntry;
      }
    });
  }, [desiredLabelId, modelLabelsQuery.data, selectedLabel]);

  const selectedMeshAndPartition = useMemo(():
    | { meshId: string; gltfMeshName: string; partitionId: string }
    | undefined => {
    if (!modelLabelsQuery.data || !modelMeshesQuery.data || !desiredLabelId) {
      return undefined;
    }

    const gltfMeshNamesByMeshId = new Map(
      modelMeshesQuery.data.meshes.map((m) => [m.id, m.gltfMeshName]),
    );

    const meshId = meshIdsByLabelId.get(desiredLabelId);
    if (!meshId) {
      return undefined;
    }
    const partitionId = partitionIdsByMeshId.get(meshId);
    if (!partitionId) {
      return undefined;
    }
    const gltfMeshName = gltfMeshNamesByMeshId.get(meshId);
    if (gltfMeshName === undefined) {
      return undefined;
    }
    return { meshId, gltfMeshName, partitionId };
  }, [
    modelLabelsQuery.data,
    modelMeshesQuery.data,
    desiredLabelId,
    partitionIdsByMeshId,
    meshIdsByLabelId,
  ]);

  const getModelAssetQuery = useQuery(
    quizConnectQuery.getModelAsset,
    {
      id: modelId,
      type: quizPb.ModelAssetType.GLTF,
      partitionId: selectedMeshAndPartition?.partitionId,
    },
    {
      retry: retryExceptConnectNotFound(),
      staleTime: config.ASSET_URL_LIFETIME_MILLIS / 4,
      enabled: !!selectedMeshAndPartition,
    },
  );

  const otherPartitionIdByName = useMemo(() => {
    const partitionIdByName = new Map<string, string>();
    otherVisibleParentLabelIds.forEach((labelId) => {
      const otherLabel = modelLabelsQuery.data?.labels.find(
        (label) => label.id === labelId,
      );
      if (otherLabel?.meshId) {
        const partitionId = partitionIdsByMeshId.get(otherLabel.meshId);
        if (partitionId) {
          partitionIdByName.set(otherLabel.name, partitionId);
        }
      }
    });
    return partitionIdByName;
  }, [modelLabelsQuery.data, otherVisibleParentLabelIds, partitionIdsByMeshId]);

  const modelPainterRef = useRef<ModelPainterRef>(null);

  const setSelectedLabelDirtyInModelPainter = (dirty: boolean) => {
    if (!selectedLabel) {
      return;
    }

    _setSelectedLabel((oldEntry) => {
      if (!oldEntry) {
        return;
      }
      if (!quizPb.ModelLabel.equals(oldEntry.label, selectedLabel.label)) {
        // Got a dirty state update, but since selectedLabel.label has changed
        // the dirty state we are trying to set might not be correct any more.
        // Assume it's dirty.
        dirty = true;
      }
      return {
        ...oldEntry,
        dirtyInModelPainter: dirty,
        maybeDirtyVersusServer: true,
      };
    });
  };
  const updateMaskFromModelPainter = () => {
    if (!selectedLabel) {
      return;
    }

    const modelPainter = modelPainterRef.current;
    if (!modelPainter) {
      throw new Error("Model painter not mounted");
    }
    const mask = modelPainter.getWriteableMask();
    _setSelectedLabel((oldEntry) => {
      if (oldEntry !== selectedLabel) {
        throw new Error(
          "Got dirty state update, but selectedLabel has changed since that render. This should not happen.",
        );
      }
      if (!oldEntry.label.mask) {
        throw new Error("Old selectedLabel has no mask");
      }
      return {
        ...oldEntry,
        label: {
          ...oldEntry.label,
          mask,
        },
        maybeDirtyVersusServer: true,
      };
    });
  };

  const [isDeleteLabelModalOpen, setDeleteLabelModalOpen] = useState(false);

  const createModelLabelMutation = useMutation(
    quizConnectQuery.createModelLabel,
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(quizConnectQuery.listModelLabels, {
            modelId: res.modelId,
          }),
        });
        setDesiredLabelId(res.id);
        setNewAddedLabelId(res.id);
      },
      onError: () =>
        showErrorNotification({ message: "Failed to create new label." }),
    },
  );
  const updateModelLabelMutation = useMutation(
    quizConnectQuery.updateModelLabel,
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(quizConnectQuery.listModelLabels, {
            modelId: res.modelId,
          }),
        });
      },
    },
  );
  const deleteModelLabelMutation = useMutation(
    quizConnectQuery.deleteModelLabel,
    {
      onSuccess: async (_res, req) => {
        await queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(quizConnectQuery.listModelLabels, {
            modelId,
          }),
        });
        if (!selectedParentLabel) {
          throw new Error(
            "Failed to set selected label. A parent label should be select even after child label deletion",
          );
        }
        setDesiredLabelId(selectedParentLabel.id);
        _setSelectedLabel((selectedLabel) =>
          selectedLabel?.label.id === req.id ? undefined : selectedLabel,
        );
        setSelectedChildLabel(undefined);
        setDeleteLabelModalOpen(false);
      },
    },
  );

  const modifySelectedLabel = (
    cb: (label: PlainMessage<quizPb.ModelLabel>) => void,
  ) => {
    _setSelectedLabel((oldEntry) => {
      if (!oldEntry) {
        return;
      }
      return {
        ...oldEntry,
        label: produce<PlainMessage<quizPb.ModelLabel>>(
          toPlainMessage(oldEntry.label),
          cb,
        ),
      };
    });
  };

  const readOnlyMasks = useMemo(
    () =>
      (modelLabelsQuery.data?.labels ?? [])
        .filter((label) => otherVisibleChildLabelIds.has(label.id))
        .map((label) => label.mask)
        .filter(isTruthy),
    [modelLabelsQuery.data, otherVisibleChildLabelIds],
  );

  const saveSelectedLabel = () => {
    if (!selectedLabel) {
      return;
    }
    updateModelLabelMutation.mutate({
      ...selectedLabel.label,
      mask: selectedLabel.label.isWholeMeshLabel
        ? undefined
        : selectedLabel.label.mask,
    });
  };

  const { parentLabels, childLabelsByParentId } = useMemo(() => {
    let parentLabels: PlainMessage<quizPb.ModelLabel>[] = [];
    const childLabelsByParentId: Map<
      string,
      PlainMessage<quizPb.ModelLabel>[]
    > = new Map();
    const parentLabelsByMeshId = new Map<
      string,
      PlainMessage<quizPb.ModelLabel>
    >(); // needed to find parent label by meshId of child label
    if (!modelLabelsQuery.data) {
      return { parentLabels, childLabelsByParentId, parentLabelsByMeshId };
    }

    const wholeMeshLabels = modelLabelsQuery.data.labels.filter(
      (modelLabel) => modelLabel.isWholeMeshLabel,
    );
    parentLabels = [...wholeMeshLabels];

    parentLabels.forEach((label) => {
      parentLabelsByMeshId.set(label.meshId, label);
    });

    const notWholeMeshLabels = modelLabelsQuery.data.labels.filter(
      (modelLabel) => !modelLabel.isWholeMeshLabel,
    );
    notWholeMeshLabels.forEach((modelLabel) => {
      const parentLabel = parentLabelsByMeshId.get(modelLabel.meshId);
      if (parentLabel) {
        const childLabels = childLabelsByParentId.get(parentLabel.id) || [];
        childLabels.push(modelLabel);
        childLabelsByParentId.set(parentLabel.id, childLabels);
      }
    });

    // Sort parent and child labels by name and id lastly
    parentLabels = sortBy(
      parentLabels,
      (l) => l.name,
      (l) => l.id,
    );
    childLabelsByParentId.forEach((childLabels, parentId) => {
      childLabelsByParentId.set(
        parentId,
        sortBy(
          childLabels,
          (l) => l.name,
          (l) => l.id,
        ),
      );
    });

    if (newAddedLabelId) {
      if (!selectedParentLabel) {
        throw new Error(
          `New added child label (id: ${newAddedLabelId}) has no selected parent label.`,
        );
      }
      const parentLabel = parentLabels.find(
        (l) => l.id === selectedParentLabel.id,
      );
      const newChildLabel = childLabelsByParentId
        .get(selectedParentLabel.id)
        ?.find((l) => l.id === newAddedLabelId);
      if (!newChildLabel) {
        throw new Error(
          `New added child label (id: ${newAddedLabelId}) could not be found.`,
        );
      }
      setSelectedParentLabel(parentLabel);
      setSelectedChildLabel(newChildLabel);
      setNewAddedLabelId(undefined);
    }

    return { parentLabels, childLabelsByParentId };
  }, [modelLabelsQuery.data?.labels, newAddedLabelId]);

  const autosaveIntervalHandlerRef = useRef<() => void>();
  useEffect(() => {
    autosaveIntervalHandlerRef.current = () => {
      if (
        selectedLabel?.maybeDirtyVersusServer &&
        !updateModelLabelMutation.isPending
      ) {
        saveSelectedLabel();
      }
    };
  });
  const autosaveInterval = useInterval(
    () => autosaveIntervalHandlerRef.current?.(),
    1000,
  );
  useEffect(() => {
    autosaveInterval.start();
    return () => autosaveInterval.stop();
  }, []);

  const anythingSaving =
    createModelLabelMutation.isPending || updateModelLabelMutation.isPending;
  const anythingDirty =
    anythingSaving ||
    !!selectedLabel?.dirtyInModelPainter ||
    !!selectedLabel?.maybeDirtyVersusServer;
  useNavigationLockAndBeforeUnload(anythingDirty);

  let userVisibleSaveState: "saving" | "saved" | "error";
  if (!anythingDirty) {
    userVisibleSaveState = "saved";
  } else if (anythingSaving) {
    userVisibleSaveState = "saving";
  } else if (updateModelLabelMutation.isError) {
    // Don't care about createModelLabelMutation.isError here because the user
    // will see that the label wasn't created.
    userVisibleSaveState = "error";
  } else {
    // We're not saving yet, but we will be soon because of the autosave timer.
    userVisibleSaveState = "saving";
  }
  const userVisibleSaveStateLabels: {
    [K in typeof userVisibleSaveState]: string;
  } = {
    saved: "All changes saved",
    saving: "Saving...",
    error: "Failed to save changes",
  };

  useAppLayout(AppLayout.FullscreenWithHeader);

  const [_tool, _setTool] = useState(ModelPainterTool.Camera);
  const tool = selectedChildLabel ? _tool : ModelPainterTool.Camera;
  const [brushRadiusSliderValue, setBrushRadiusSliderValue] = useState(-2);
  const brushRadius = brushRadiusFromSliderValue(brushRadiusSliderValue);

  const toggleChildLabelVisibleSafe = (labelId: string) => {
    if (selectedChildLabel?.id !== labelId) {
      toggleChildLabelVisible(labelId);
    }
  };

  const onParentLabelSelect = (newLabel: PlainMessage<quizPb.ModelLabel>) => {
    setDesiredLabelId(newLabel.id);
    setSelectedParentLabel(newLabel);
    setOtherVisibleParentLabelIds(new Set());
    setSelectedChildLabel(undefined);
    setOtherVisibleChildLabelIds(new Set());
    _setTool(ModelPainterTool.Camera);
  };

  const onChildLabelSelect = (newLabel: PlainMessage<quizPb.ModelLabel>) => {
    setDesiredLabelId(newLabel.id);
    setSelectedChildLabel(newLabel);
    setOtherVisibleChildLabelIds(new Set());
  };

  const onAddChildLabel = () => {
    if (!selectedParentLabel) {
      throw new Error("Failed to add new label. No parent label selected.");
    }
    toggleCreateNewLabelModal();
  };

  const sharedStuffRef = useRef<SharedModelPreviewStuffRef>(null);

  function onKeyDown(event: KeyboardEvent) {
    const isSpotlightOpened = !!document.querySelector(
      ".mantine-Spotlight-inner",
    );
    const anyModalOpened = isCreateNewLabelModalOpen || isDeleteLabelModalOpen;
    const anyInputFocused = document.activeElement?.tagName === "INPUT";
    if (
      isSpotlightOpened ||
      anyModalOpened ||
      anyInputFocused ||
      anythingDirty
    ) {
      return;
    }

    if (event.key === "1") {
      _setTool(ModelPainterTool.Camera);
    } else if (event.key === "2") {
      _setTool(ModelPainterTool.Brush);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [onKeyDown]);

  return (
    <>
      <Group
        style={{ height: "100%", overflow: "hidden" }}
        align="stretch"
        wrap="nowrap"
        gap={0}
      >
        <Box
          style={{
            borderRight: "1px solid #dee2e6",
          }}
        >
          <Stack
            p="sm"
            style={{
              height: "100%",
              width: "max-content",
              display: "flex",
            }}
          >
            <Text fs="italic" c="dimmed">
              {userVisibleSaveStateLabels[userVisibleSaveState]}
            </Text>
            <Button.Group>
              <Button
                style={{ flexGrow: 1 }}
                variant={
                  tool === ModelPainterTool.Camera ? "filled" : "outline"
                }
                onClick={() => _setTool(ModelPainterTool.Camera)}
                disabled={anythingDirty}
              >
                Camera [1]
              </Button>
              <Tooltip
                label={
                  selectedChildLabel
                    ? "Brush: [LeftClick] | Eraser: [Shift]+[LeftClick]"
                    : "Select a sub structure label to paint."
                }
                withArrow
              >
                <Button
                  style={{ flexGrow: 1 }}
                  variant={
                    tool === ModelPainterTool.Brush ? "filled" : "outline"
                  }
                  onClick={() => _setTool(ModelPainterTool.Brush)}
                  disabled={anythingDirty || !selectedChildLabel}
                >
                  Brush/Eraser [2]
                </Button>
              </Tooltip>
            </Button.Group>
            {tool === ModelPainterTool.Brush && (
              <Input.Wrapper label="Brush/Eraser size">
                <Slider
                  value={brushRadiusSliderValue}
                  onChange={(v) => setBrushRadiusSliderValue(v)}
                  min={-3}
                  max={-1}
                  step={0.1}
                  scale={brushRadiusFromSliderValue}
                  label={(value) => value.toFixed(3)}
                  mt="0.3rem"
                />
              </Input.Wrapper>
            )}
            <>
              <Group
                gap="lg"
                align="flex-start"
                style={{
                  overflow: "hidden",
                }}
              >
                <ParentLabelList
                  parentLabels={parentLabels}
                  selectedParentLabelId={selectedParentLabel?.id}
                  onParentLabelSelect={onParentLabelSelect}
                  otherVisibleLabelIds={otherVisibleParentLabelIds}
                  toggleLabelVisible={toggleParentLabelVisible}
                  useEyes
                />
                {parentLabels.length !== 0 && (
                  <ChildLabelList
                    childLabelList={
                      childLabelsByParentId.get(
                        selectedParentLabel?.id ?? "",
                      ) ?? []
                    }
                    selectedChildLabelId={selectedChildLabel?.id}
                    onChildLabelSelect={onChildLabelSelect}
                    otherVisibleLabelIds={otherVisibleChildLabelIds}
                    toggleLabelVisible={toggleChildLabelVisibleSafe}
                    onAddChildLabel={selectedParentLabel && onAddChildLabel}
                    useEyes
                  />
                )}
              </Group>

              <LabelSettings
                selectedLabel={selectedLabel?.label}
                modifySelectedLabel={modifySelectedLabel}
                openDeleteLabelModal={() => setDeleteLabelModalOpen(true)}
              />
            </>
          </Stack>
        </Box>
        <Box
          style={{
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          {getModelAssetQuery.data?.downloadUrl && selectedMeshAndPartition && (
            <Sentry.ErrorBoundary fallback={FallbackWithReset}>
              <Suspense fallback={<Loader />}>
                <ModelViewerUi
                  onResetCamera={() => sharedStuffRef.current?.resetCamera()}
                  disabled={tool !== ModelPainterTool.Camera}
                >
                  <OurCanvas>
                    <SharedModelPreviewStuff
                      ref={sharedStuffRef}
                      backgroundColor={0xffffff}
                      disableCameraControls={tool !== ModelPainterTool.Camera}
                    />
                    <ModelPainter
                      ref={modelPainterRef}
                      modelId={modelId}
                      gltfUrl={getModelAssetQuery.data.downloadUrl}
                      gltfMeshName={selectedMeshAndPartition.gltfMeshName}
                      otherPartitionIdByName={otherPartitionIdByName}
                      readOnlyMasks={readOnlyMasks}
                      initialWriteableMask={selectedLabel?.label.mask}
                      onWriteableMaskDirtyChanged={(dirty) =>
                        setSelectedLabelDirtyInModelPainter(dirty)
                      }
                      writeableMaskOpacity={0.7}
                      onIdle={() => updateMaskFromModelPainter()}
                      tool={tool}
                      brushRadius={brushRadius}
                    />
                  </OurCanvas>
                </ModelViewerUi>
              </Suspense>
            </Sentry.ErrorBoundary>
          )}
        </Box>
      </Group>
      {selectedLabel && (
        <Modal
          opened={isDeleteLabelModalOpen}
          onClose={() => setDeleteLabelModalOpen(false)}
          title="Delete label?"
        >
          <Stack>
            <Text>
              {`Are you sure you want to delete the label "${selectedLabel.label.name}"?`}
            </Text>
            <Button
              color="red"
              onClick={() =>
                deleteModelLabelMutation.mutate({
                  id: selectedLabel.label.id,
                })
              }
            >
              Delete {selectedLabel.label.name}
            </Button>
          </Stack>
        </Modal>
      )}
      {selectedParentLabel && (
        <CreateNewLabelModal
          modelId={modelId}
          parentLabel={selectedParentLabel}
          isModalOpen={isCreateNewLabelModalOpen}
          createModelLabelMutation={createModelLabelMutation}
          closeModal={closeCreateNewLabelModal}
        />
      )}
    </>
  );
};
