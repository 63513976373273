import type { Message } from "@bufbuild/protobuf";
import {
  createConnectQueryKey,
  type MethodUnaryDescriptor,
} from "@connectrpc/connect-query";

export function createAnyArgumentsQueryKey<
  I extends Message<I>,
  O extends Message<O>,
>(
  methodDescriptor: Pick<MethodUnaryDescriptor<I, O>, "I" | "name" | "service">,
): [string, string] {
  const exactKey = createConnectQueryKey(methodDescriptor);
  return [exactKey[0], exactKey[1]];
}
