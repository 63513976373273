import { quizPb } from "@augmedi/proto-gen";
import type { Duration, Timestamp } from "@bufbuild/protobuf";

/**
 * Utility function to format milliseconds into a human-readable string.
 * @param millis - The time in milliseconds.
 * @returns A string formatted as `Xm Ys` or `Ys` depending on the input.
 */
export const formatMillis = (millis: number): string => {
  const seconds = Math.floor(millis / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes > 0) {
    return `${minutes}m ${remainingSeconds}s`;
  } else {
    return `${seconds}s`;
  }
};

export const bodyPartToString: { [key in quizPb.BodyPart]: string } = {
  [quizPb.BodyPart.BODYPART_UNKNOWN]: "All",
  [quizPb.BodyPart.BODYPART_SHOULDER]: "Shoulder",
  [quizPb.BodyPart.BODYPART_ELBOW]: "Elbow",
  [quizPb.BodyPart.BODYPART_HAND]: "Hand",
  [quizPb.BodyPart.BODYPART_HIP]: "Hip",
  [quizPb.BodyPart.BODYPART_KNEE]: "Knee",
  [quizPb.BodyPart.BODYPART_FEET]: "Feet",
  [quizPb.BodyPart.BODYPART_SPINE]: "Spine",
  [quizPb.BodyPart.BODYPART_HEAD]: "Head",
  [quizPb.BodyPart.BODYPART_THORAX]: "Thorax",
  [quizPb.BodyPart.BODYPART_ABDOMEN]: "Abdomen",
};

export const bodyPartToInt: { [key: string]: quizPb.BodyPart } = {
  All: quizPb.BodyPart.BODYPART_UNKNOWN,
  Shoulder: quizPb.BodyPart.BODYPART_SHOULDER,
  Elbow: quizPb.BodyPart.BODYPART_ELBOW,
  Hand: quizPb.BodyPart.BODYPART_HAND,
  Hip: quizPb.BodyPart.BODYPART_HIP,
  Knee: quizPb.BodyPart.BODYPART_KNEE,
  Feet: quizPb.BodyPart.BODYPART_FEET,
  Spine: quizPb.BodyPart.BODYPART_SPINE,
  Head: quizPb.BodyPart.BODYPART_HEAD,
  Thorax: quizPb.BodyPart.BODYPART_THORAX,
  Abdomen: quizPb.BodyPart.BODYPART_ABDOMEN,
};

export const formatDuration = (duration: Duration | undefined) => {
  if (!duration) {
    return "0s";
  }
  const totalSeconds = Number(duration.seconds);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours}h ${minutes}m ${seconds}s`;
};

export const formatTimestamp = (timestamp: Timestamp | undefined) => {
  if (!timestamp) {
    return "Unknown";
  }
  const date = new Date(Number(timestamp.seconds) * 1000);
  return date.toLocaleString();
};
