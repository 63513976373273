/* eslint @typescript-eslint/no-explicit-any: off */
import { atlasConnectQuery, atlasPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import { useQuery } from "@connectrpc/connect-query";
import { Loader } from "@mantine/core";
import { clamp, useDebouncedValue } from "@mantine/hooks";
import {
  Spotlight,
  type SpotlightRootProps,
  type SpotlightStore,
} from "@mantine/spotlight";
import { IconSearch } from "@tabler/icons-react";
import { keepPreviousData } from "@tanstack/react-query";
import { useEffect, useState } from "react";

interface Props {
  onSelectStructure: (
    structureId: string,
    entry: PlainMessage<atlasPb.NameSearchResponse_Entry>,
  ) => void;
  structureSpotlightStore: any;
  spotlightRootProps?: Omit<
    SpotlightRootProps,
    "store" | "query" | "onQueryChange" | "scrollable"
  >;
}

// HACK copied from mantine source because it's not exported
function selectAction(index: number, store: SpotlightStore): number {
  const state = store.getState();
  const actionsList = document.getElementById(state.listId);
  const selected =
    actionsList?.querySelector<HTMLButtonElement>("[data-selected]");
  const actions =
    actionsList?.querySelectorAll<HTMLButtonElement>("[data-action]") ?? [];
  const nextIndex =
    index === -1 ? actions.length - 1 : index === actions.length ? 0 : index;

  const selectedIndex = clamp(nextIndex, 0, actions.length - 1);
  selected?.removeAttribute("data-selected");
  actions[selectedIndex]?.scrollIntoView({ block: "nearest" });
  actions[selectedIndex]?.setAttribute("data-selected", "true");
  store.updateState((state) => ({ ...state, selected: index }));

  return selectedIndex;
}

export const StructureSearch = ({
  onSelectStructure,
  structureSpotlightStore,
  spotlightRootProps,
}: Props) => {
  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearchInput] = useDebouncedValue(searchInput, 500);

  const nameSearchQuery = useQuery(
    atlasConnectQuery.nameSearch,
    {
      query: debouncedSearchInput,
    },
    {
      placeholderData: keepPreviousData,
    },
  );
  const displaySearchLoading =
    !!searchInput &&
    (nameSearchQuery.isFetching || searchInput !== debouncedSearchInput);

  const items = (nameSearchQuery.data?.entries ?? []).map((entry) => (
    <Spotlight.Action
      key={entry.structureId}
      label={entry.displayName}
      description={entry.matchingName}
      onClick={() => onSelectStructure(entry.structureId, entry)}
    />
  ));

  useEffect(() => {
    selectAction(0, structureSpotlightStore);
  }, [nameSearchQuery.data]);

  return (
    <Spotlight.Root
      {...spotlightRootProps}
      store={structureSpotlightStore}
      query={searchInput}
      onQueryChange={(v) => {
        setSearchInput(v);
      }}
      scrollable
    >
      <Spotlight.Search
        placeholder="Search for anatomical structures..."
        leftSection={
          displaySearchLoading ? (
            <Loader size={24} />
          ) : (
            <IconSearch stroke={1.5} />
          )
        }
      />
      <Spotlight.ActionsList>
        {items.length > 0 ? (
          items
        ) : (
          <Spotlight.Empty>
            {displaySearchLoading ? "Loading..." : "No results"}
          </Spotlight.Empty>
        )}
      </Spotlight.ActionsList>
    </Spotlight.Root>
  );
};
