import { quizConnectQuery } from "@augmedi/proto-gen";
import { useMutation, useSuspenseQuery } from "@connectrpc/connect-query";
import { Container, Stack, Switch, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";

export const UserSettingsPage: React.FC = () => {
  const [muteAudio, setMuteAudio] = useState<boolean>(false);
  const { data } = useSuspenseQuery(quizConnectQuery.getUserSettings);
  const mutation = useMutation(quizConnectQuery.updateUserSettings);

  useEffect(() => {
    const fetchedMute = data?.settings?.muteAudio;
    if (fetchedMute !== undefined) {
      setMuteAudio(fetchedMute);
    } else {
      setMuteAudio(false);
      throw new Error(
        "User settings could not be fetched while checking mute status",
      );
    }
  }, [data]);

  const handleClick = () => {
    setMuteAudio((prevMuteAudio) => {
      const newMuteAudio = !prevMuteAudio;
      mutation.mutate(
        {
          settings: {
            muteAudio: newMuteAudio,
          },
        },
        {
          onError: (error) => {
            throw new Error(
              "Failed to update user settings when muting audio: " + error,
            );
          },
        },
      );
      return newMuteAudio;
    });
  };

  return (
    <Container py="md">
      <Title>User Settings</Title>
      <Stack>
        <div style={{ marginBottom: "1rem" }}></div>
        <Switch
          size="md"
          checked={muteAudio}
          onChange={handleClick}
          label="Mute Audio"
        />
      </Stack>
    </Container>
  );
};
