import url from "url";
import { z } from "zod";

const treeAnatomyBaseBlobUrl =
  "https://augmedidevdata.blob.core.windows.net/tree-anatomy-labelling/";
const treeAnatomySasToken =
  "?sv=2021-10-04&st=2024-10-02T12%3A45%3A30Z&se=2024-11-03T13%3A45%3A00Z&sr=c&sp=r&sig=Cue9Omaxor18xMoXo2T95xHUV4Z%2BMu8MGFwiLvnKWAA%3D";

export function getTreeAnatomyUrl(path: string) {
  return url.resolve(treeAnatomyBaseBlobUrl, path) + treeAnatomySasToken;
}

export const allTreeAnatomyProjects = [
  "arm-arteries-left",
  "arm-nerves-left",
  "arm-veins-left",
  "leg-arteries-left",
  "leg-nerves-left",
  "leg-veins-left",
];

const componentLabelZod = z.object({
  directStructureId: z.string().optional(),
  indirectStructureId: z.string().optional(),
});

const labelSetZod = z.object({
  projectName: z.string(),
  labels: z.array(componentLabelZod),
});

export type ComponentLabel = z.infer<typeof componentLabelZod>;
export type LabelSet = z.infer<typeof labelSetZod>;

export function parseTreeAnatomyJson(json: unknown): LabelSet {
  return labelSetZod.parse(json);
}
