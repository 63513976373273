import { exactlyOne } from "@augmedi/type-utils";
import {
  Button,
  Container,
  Grid,
  Menu,
  Paper,
  rem,
  Stack,
  Text,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { IconSourceCode } from "@tabler/icons-react";
import { Link, useLocation } from "wouter";
import { showErrorNotification } from "../../logic/notification";
import {
  allTreeAnatomyProjects,
  parseTreeAnatomyJson,
} from "../../logic/tree-anatomy-labelling";
import { IconChevronForDropdown } from "../IconChevronForDropdown";

const dropzoneIconStyle = {
  width: rem(52),
  height: rem(52),
};

export const TreeAnatomyLandingPage = () => {
  const navigate = useLocation()[1];

  const onDropzoneAccept = async (files: File[]) => {
    try {
      const file = exactlyOne(files);
      const blobUrl = URL.createObjectURL(file);

      const parsed = parseTreeAnatomyJson(JSON.parse(await file.text()));

      navigate(
        `/label-tree-anatomy/${parsed.projectName}?initialBlobUrl=${blobUrl}`,
      );
    } catch (err) {
      console.error(err);
      showErrorNotification({
        message: "Failed to open project. See console for more info.",
      });
    }
  };

  return (
    <Container py="md">
      <Grid>
        <Grid.Col span={6}>
          <Paper px="md" py="xl" shadow="sm">
            <Stack gap="xl">
              <Text size="xl" ta="center" inline>
                Start without labels
              </Text>
              <Menu>
                <Menu.Target>
                  <Button rightSection={<IconChevronForDropdown />}>
                    New blank project
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  {allTreeAnatomyProjects.map((projectName) => (
                    <Menu.Item
                      key={projectName}
                      component={Link}
                      to={`/label-tree-anatomy/${projectName}`}
                    >
                      {projectName}
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            </Stack>
          </Paper>
        </Grid.Col>
        <Grid.Col span={6}>
          <Paper px="md" py="xl" shadow="sm">
            <Dropzone
              onDrop={onDropzoneAccept}
              onReject={() =>
                showErrorNotification({ message: "Unsupported file type" })
              }
              accept={{ "application/json": [".json"] }}
            >
              <Stack
                justify="center"
                align="center"
                gap="xl"
                style={{ pointerEvents: "none" }}
              >
                <Stack align="center" gap="xs">
                  <Text size="xl" inline>
                    Open an existing project
                  </Text>
                  <Text c="dimmed" ta="center">
                    Drop a JSON file from a saved project here
                    <br />
                    or click to browse files
                  </Text>
                </Stack>
                <IconSourceCode
                  style={{
                    ...dropzoneIconStyle,
                    color: "var(--mantine-color-dimmed)",
                  }}
                  stroke={1.5}
                />
              </Stack>
            </Dropzone>
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
